import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useState } from "react";
// import * as React from "react";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import {
  CometChatConversationsWithMessages,
  CometChatTheme,
  CometChatUIKit,
  CometChatUIKitConstants,
  TextBubbleStyle,
} from "@cometchat/chat-uikit-react";

import {
  ConversationsConfiguration,
  ConversationsStyle,
  MessageHeaderConfiguration,
  MessageListConfiguration,
  MessagesConfiguration,
  ThreadedMessagesConfiguration,
  WithMessagesStyle,
} from "@cometchat/uikit-shared";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import moment from "moment";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  APPROVALS_ACCEPTED,
  APPROVALS_DECLINED,
  APPROVALS_WAITING,
  Context,
} from "../../App";
import MessageSound from "../../assets/audio/notification-sound.mp3";
import { SHOP_CREATED } from "../../config/dataService/constants";
import { customerDetail } from "../../redux/Customer/action";
import {
  dasboardDetails,
  requestAppointmentDetails,
  requestEstimateDetails,
} from "../../redux/Dashboard/action";
import { appointmentDetails } from "../../redux/appointment/action";
import { getItem } from "../../utility/localStorage";
import AppointmentModel from "../Appointment/AppointmentModel";
import AppointmentRescheduleRequestModal from "../Appointment/AppointmentRescheduleRequestModal";
import NewChatList from "../Messenger/NewChatList";
import AppointmentRequestModal from "../modal/AppointmentrequestModal";
import EstimateRequestModal from "../modal/EstimateRequestModal";

import { shopDetails } from "../../redux/Account/ShopDetails/action";
import "./Dashboard.css";
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      // animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2, pt: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Dashboard() {
  const { cometChatUser } = useContext(Context);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [dashbordData, setdashbordData] = React.useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const [id, setId] = React.useState("");
  const [appointmentTodayId, setAppointmentTodayId] = React.useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [rescheduledRequestOpen, setRescheduledRequestOpen] =
    React.useState(false);
  const [appointment, setAppointment] = React.useState("");
  const [appointmentId, setAppointmentId] = React.useState("");
  const [rescheduledId, setRescheduledId] = React.useState("");
  const [estimate, setEstimate] = React.useState("");
  const [openAppointMentModel, setOpenAppointMentModel] = React.useState(false);
  const [data, setData] = React.useState("");
  const [status, setStatus] = useState("");
  const [show, setShow] = React.useState(false);
  const [openNewChatModel, setOpenNewChatModel] = React.useState(false);
  const handleStatus = () => setShow(true);

  const [clickDisabled, setClickDisabled] = useState(false);
  const [templates, setTemplates] = useState([]);
  const shopDetail = getItem("apex-saas_details");
  const handleStatusClose = () => {
    setShow(false);
  };

  useEffect(() => {
    details();
  }, [appointmentTodayId]);

  const details = async () => {
    if (appointmentTodayId) {
      setShowLoader(true);
      const detailView = await dispatch(appointmentDetails(appointmentTodayId));
      if (detailView) {
        setStatus(detailView?.status);
        setData(detailView);
      }
      setShowLoader(false);
    }
  };

  const handleCloseAppointmentModel = () => {
    setOpenAppointMentModel(false);
  };
  const openAppointmentModel = (detailsData) => {
    setAppointmentTodayId(detailsData?._id);
    setOpenAppointMentModel(true);
  };
  const handleClose = () => {
    dashboardDetails();
    setOpen(false);
  };
  const handleOpen = () => {
    dashboardDetails();
    setOpen(true);
  };
  const handleClose1 = () => {
    dashboardDetails();
    setOpen1(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleCancelAppointment = () => {
    setOpen1(false);
  };
  const handleOpen1 = () => setOpen1(true);
  const DetailsData = useSelector((state) => state?.dashboard?.dashboardData);
  const getShopDetails = async () => {
    setShowLoader(true);
    const data = await dispatch(shopDetails());
    if (data) {
      setShowLoader(false);
    }
  };
  const BasicDetails = useSelector((state) => state?.account?.shopDetailsData);

  const dashboardDetails = async () => {
    setShowLoader(true);
    let data = await dispatch(dasboardDetails());
    if (data) {
      setdashbordData(data);
    } else {
      setdashbordData("");
    }
    setShowLoader(false);
  };

  const AppointmentDetails = async () => {
    if (appointmentId) {
      setShowLoader(true);
      const data = await dispatch(requestAppointmentDetails(appointmentId));
      if (data) {
        setAppointment(data);
      }
      setShowLoader(false);
    }
  };

  const estimateDetails = async () => {
    if (id) {
      setShowLoader(true);
      const data = await dispatch(requestEstimateDetails(id));
      if (data) {
        setEstimate(data);
      }
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (id) {
      estimateDetails();
    }
    if (appointmentId) {
      AppointmentDetails();
    }
  }, [id, appointmentId]);

  useEffect(() => {
    dashboardDetails();
    getShopDetails();

    const intervalId = setInterval(() => {
      dashboardDetails();
      getShopDetails();
    }, 120000);
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    if (cometChatUser) {
      let definedTemplates =
        CometChatUIKit.getDataSource().getAllMessageTemplates(
          new CometChatTheme({})
        );
      const template = definedTemplates.map((template) => {
        if (
          template.type === "text" &&
          template.category === CometChatUIKitConstants.MessageCategory.message
        ) {
          template.contentView = (message) => getContentView(message);
          return template;
        }
        return template;
      });
      setTemplates(template);
    }
  }, [cometChatUser]);
  const getContentView = (message) => {
    if (message instanceof CometChat.TextMessage) {
      const textStyle = new TextBubbleStyle({
        borderRadius: "6px",
        textFont: "400 16px Roboto",
        width: "inherit",
        background:
          message.receiver.name !== shopDetail.shopName
            ? "#3d9fff"
            : "#f6f6f6eb",
        textColor:
          message.receiver.name !== shopDetail.shopName
            ? "rgb(255, 255, 255)"
            : "#000000de",
      });

      return (
        <cometchat-text-bubble
          text={message.getText()}
          textStyle={JSON.stringify(textStyle)}
        />
      );
    }
  };
  const cwmStyle = new WithMessagesStyle({
    // width: "500px",
    height: "600px",
  });
  const getCustomerType = async (id) => {
    setClickDisabled(true);
    const data = await dispatch(customerDetail(id));
    if (data) {
      if (data?.createdFrom == SHOP_CREATED) {
        navigate(`/customers/${id}/${SHOP_CREATED}`);
      } else {
        navigate(`/customers/${id}/apex_auto_app`);
      }
    }
    setClickDisabled(false);
  };
  const msConfig = new MessagesConfiguration({
    messageHeaderConfiguration: new MessageHeaderConfiguration({
      showBackButton: true,

      listItemView: (user, group) => {
        return (
          <div
            onClick={() => {
              if (!clickDisabled) getCustomerType(user?.uid);
            }}
            style={{
              display: "flex",
              fontSize: "13px",
              fontWeight: 400,
              cursor: "pointer",
              height: "45px",
              alignItems: "center",
            }}
          >
            {user.status == "online" ? (
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar
                  src={user?.avatar ?? ""}
                  alt={user.name}
                  sx={{ width: 34, height: 34 }}
                >
                  {/* {user.name.split(" ")[0].charAt(0)}
              {user.name.split(" ")[1].charAt(0)} */}
                </Avatar>
              </StyledBadge>
            ) : (
              <Avatar
                src={user?.avatar ?? ""}
                alt={user.name}
                sx={{ width: 34, height: 34, background: "#5D5D5D" }}
              >
                <span style={{ fontSize: "14px" }}>
                  {user.name.split(" ")[0].charAt(0)}
                  {user.name.split(" ")[1].charAt(0)}
                </span>
              </Avatar>
            )}
            <div style={{ marginLeft: "10px", color: "#141414" }}>
              <span style={{ fontWeight: 500, fontSize: "15px" }}>
                {user.name}
              </span>
              <br />
              <span style={{ color: "#AFA6A7" }}>{user.status}</span>
            </div>
            {/* My profile view {user.name} */}
          </div>
        );
      },
    }),

    messageListConfiguration: new MessageListConfiguration({
      messageListStyle: {
        background: "white",
        TimestampTextFont: "400 10px Roboto",
      },
      templates: templates,
    }),
    threadedMessageConfiguration: new ThreadedMessagesConfiguration({
      messageListConfiguration: new MessageListConfiguration({
        templates: templates,
        messageListStyle: {
          TimestampTextFont: "400 10px Roboto",
        },
      }),
    }),
  });
  const cwfConfig = new ConversationsConfiguration({
    badgeStyle: { background: "red" },
    // avatarStyle: {
    //   url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTra2eAajJXFOjiO3gI2mnVSzl6cRo6vwvIMEHKSlLBgA&s",
    //   fallback: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTra2eAajJXFOjiO3gI2mnVSzl6cRo6vwvIMEHKSlLBgA&s", // Provide a fallback image URL
    // },

    // selectionMode: SelectionMode.multiple,
    conversationsStyle: new ConversationsStyle({
      // onlineStatusColor: "#BFFF00",
      lastMessageTextColor: "grey",
    }),
    customSoundForMessages: MessageSound,
    menu: (
      <button
        onClick={(e) => setOpenNewChatModel(true)}
        className="newChatButton"
      >
        <img src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1.6rem' height='1.6rem' viewBox='0 0 256 256'%3E%3Cpath fill='%2364B1FF' d='m229.66 58.34l-32-32a8 8 0 0 0-11.32 0l-96 96A8 8 0 0 0 88 128v32a8 8 0 0 0 8 8h32a8 8 0 0 0 5.66-2.34l96-96a8 8 0 0 0 0-11.32M124.69 152H104v-20.69l64-64L188.69 88ZM200 76.69L179.31 56L192 43.31L212.69 64ZM224 120v88a16 16 0 0 1-16 16H48a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h88a8 8 0 0 1 0 16H48v160h160v-88a8 8 0 0 1 16 0'/%3E%3C/svg%3E" />
      </button>
    ),
  });
  const handleCloseChatModal = () => {
    setOpenNewChatModel(false);
  };
  return (
    <>
      <Box
        sx={{
          /*backgroundColor: "#F1F1F1",*/
          // top: 0,
          // position: "absolute",
          // marginLeft: "-32px",
          // paddingLeft: "17px",
          // paddingTop: "86px",
          padding: " 50px 40px 24px 40px",
        }}
      >
        {/* Dashboard Title */}
        <Box>
          <Grid container>
            <Typography variant="h4" style={{ fontWeight: 400 }}>
              Welcome Back, {BasicDetails?.shopName}
              {/* {getItem("apex-saas_details")
                ? getItem("apex-saas_details")?.shopName
                : "Auto Repair Shop Name"} */}
            </Typography>
          </Grid>

          {/* Appointment Tabs*/}
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12} sm={12} md={6} xl={6}>
              <Card
                sx={{
                  width: "100%",
                  boxShadow: 0,
                  borderRadius: "10px",
                  padding: "3px 2px",
                }}
              >
                <Box>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    // style={{ color: "#B388FF" }}
                    className="tab_color"
                  >
                    <Tab
                      label={
                        <span>
                          APPOINTMENT REQUESTS{" "}
                          <Badge
                            badgeContent={
                              dashbordData?.[0]?.pendingAppointmentRequest
                            }
                            color="error"
                            style={{
                              paddingLeft:
                                dashbordData?.[0]?.pendingAppointmentRequest &&
                                "15px",
                            }}
                          />{" "}
                        </span>
                      }
                      {...a11yProps(0)}
                      style={{ lineHeight: "24px" }}

                      // style={{ color: "#B388FF"}}
                    />
                    <Tab
                      label={
                        <span>
                          ESTIMATE REQUESTS{" "}
                          <Badge
                            badgeContent={
                              dashbordData?.[0]?.pendingEstimateRequest
                            }
                            color="error"
                            style={{
                              paddingLeft:
                                dashbordData?.[0]?.pendingEstimateRequest &&
                                "15px",
                            }}
                          />{" "}
                        </span>
                      }
                      {...a11yProps(1)}
                      // style={{ color: "#B388FF"}}
                      className="tab_color"
                      style={{ lineHeight: "24px" }}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <Grid container rowGap={1}>
                    {dashbordData &&
                      dashbordData?.map((item) =>
                        item?.appointment_requests?.map((item) => {
                          return (
                            <Grid
                              container
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              xl={12}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              sx={{
                                boxShadow: 3,
                                padding: "12px",
                                borderLeft: 4,
                                borderColor: "#B388FF",
                                borderRadius: "4px",
                              }}
                            >
                              <Grid item xs={6} sm={6} md={7} xl={6}>
                                <Typography
                                  variant="h6"
                                  style={{ fontWeight: "500" }}
                                >
                                  {item?.customer?.fullName &&
                                    item?.customer?.fullName
                                      ?.charAt(0)
                                      .toUpperCase() +
                                      item?.customer?.fullName?.slice(1)}
                                </Typography>
                                <Typography
                                  variant="title"
                                  style={{ fontWeight: 500 }}
                                >
                                  {item?.dropOffDateTime &&
                                    moment(item?.dropOffDateTime).format(
                                      "MMMM D, YYYY"
                                    )}
                                </Typography>
                              </Grid>
                              {/* <Grid item container> */}
                              <Grid item justifyItems={"end"}>
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    if (item?.isRescheduled == false) {
                                      setAppointmentId(item?._id);
                                      handleOpen1();
                                    } else {
                                      setAppointmentId(item?._id);
                                      setRescheduledRequestOpen(true);
                                    }
                                  }}
                                >
                                  VIEW
                                </Button>
                              </Grid>
                              {/* </Grid> */}
                            </Grid>
                          );
                        })
                      )}

                    <Grid
                      item
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      xl={12}
                      justifyContent={"end"}
                    >
                      <Grid item>
                        <Button
                          variant="text"
                          size="large"
                          onClick={() => navigate(`/request/appointments`)}
                          sx={{
                            lineHeight: "26px",
                            marginTop: "16px",
                            // marginBottom: "16px",
                          }}
                        >
                          VIEW ALL <ChevronRightIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Grid container rowGap={1}>
                    {dashbordData &&
                      dashbordData?.map((item) =>
                        item?.estimate_requests?.map((item) => {
                          return (
                            <Grid
                              container
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              xl={12}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              sx={{
                                boxShadow: 3,
                                padding: "12px",
                                borderLeft: 4,
                                borderColor: "#B388FF",
                                borderRadius: "4px",
                              }}
                            >
                              <Grid item xs={6} sm={6} md={7} xl={6}>
                                <Typography
                                  variant="h6"
                                  style={{ fontWeight: "500" }}
                                >
                                  {item?.customer?.fullName &&
                                    item?.customer?.fullName
                                      ?.charAt(0)
                                      .toUpperCase() +
                                      item?.customer?.fullName?.slice(1)}
                                </Typography>
                                <Typography
                                  variant="title"
                                  style={{ fontWeight: 500 }}
                                >
                                  {item?.createdAt &&
                                    moment(item?.createdAt).format(
                                      "MMMM D, YYYY"
                                    )}
                                </Typography>
                              </Grid>

                              <Grid item justifyItems={"end"}>
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    setId(item?._id);
                                    handleOpen();
                                  }}
                                >
                                  VIEW
                                </Button>
                              </Grid>
                            </Grid>
                          );
                        })
                      )}

                    <Grid
                      item
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      xl={12}
                      justifyContent={"end"}
                    >
                      <Grid item>
                        <Button
                          onClick={() => navigate(`/request/estimates`)}
                          variant="text"
                          size="large"
                          sx={{
                            lineHeight: "26px",
                            marginTop: "16px",
                            marginBottom: "16px",
                          }}
                        >
                          VIEW ALL <ChevronRightIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </CustomTabPanel>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} xl={6}>
              <Card
                sx={{
                  width: "97%",
                  padding: "16px",
                  boxShadow: 0,
                  borderRadius: "10px",
                }}
              >
                {/* Messages */}
                {cometChatUser && (
                  <CometChatConversationsWithMessages
                    conversationsConfiguration={cwfConfig}
                    conversationsWithMessagesStyle={cwmStyle}
                    isMobileView={true}
                    messagesConfiguration={msConfig}
                  />
                )}

                {/* <Messenger isMobileView={true} /> */}

                {/* <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  justifyContent={"end"}
                >
                  <Grid item>
                    <Button
                      variant="text"
                      color="primary"
                      size="large"
                      style={{ marginLeft: "30px", lineHeight: "24px" }}
                      onClick={() => navigate("/messenger")}
                    >
                      VIEW ALL <ChevronRightIcon />
                    </Button>
                  </Grid>
                </Grid> */}
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={3}
            rowSpacing={2.5}
            style={{ marginTop: "20px" }}
          >
            <Grid
              item
              // sx={{ boxShadow: 0, borderRadius: "10px" }}
              // item
              xs={12}
              sm={12}
              md={6}
              xl={6}
              // justifyContent={"space-between"}
              // spacing={2}
              style={{
                // marginTop: "20px",
                paddingBottom: "20px",
                marginLeft: "0px",
                marginRight: "0px",
                // padding: "10px",
              }}
            >
              <Card
                sx={{
                  padding: "24px",
                  width: "100%",
                  height: "fit-content",
                  boxShadow: "none",
                }}
              >
                {/* Appointments Waiting for Drop Off Today */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  marginBottom={"20px"}
                >
                  <Typography variant="h5" style={{ fontWeight: 400 }}>
                    Appointments Waiting for Drop Off Today
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <TableContainer
                    component={Paper}
                    style={{ boxShadow: "none" }}
                  >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead sx={{ bgcolor: "background.dark_grey" }}>
                        <TableRow>
                          <TableCell>Customer Name</TableCell>
                          <TableCell align="center">Drop Off Date</TableCell>
                          <TableCell align="center">Drop Off Time</TableCell>
                          <TableCell align="center">Vehicle</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dashbordData &&
                          dashbordData?.map((item) =>
                            item?.appointments_drop_of_today?.map((row) => (
                              <TableRow
                                key={row.name}
                                onClick={() => openAppointmentModel(row)}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  cursor: "pointer",
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row?.customer?.fullName &&
                                    row?.customer?.fullName
                                      ?.charAt(0)
                                      .toUpperCase() +
                                      row?.customer?.fullName?.slice(1)}
                                </TableCell>
                                <TableCell align="center">
                                  {row?.dropOffDateTime &&
                                    moment(row?.dropOffDateTime).format(
                                      "MMM Do , YYYY"
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                  {row?.dropOffDateTime &&
                                    moment(row?.dropOffDateTime).format(
                                      "hh:mm A"
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                  {row?.vehicle?.year && row?.vehicle?.year}{" "}
                                  {row?.vehicle?.vehicleMake?.name &&
                                    row?.vehicle?.vehicleMake?.name}{" "}
                                  {row?.vehicle?.vehicleModel?.name &&
                                    row?.vehicle?.vehicleModel?.name}{" "}
                                </TableCell>
                              </TableRow>
                            ))
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    justifyContent={"end"}
                    // marginBottom={"16px"}
                    marginTop={"16px"}
                  >
                    <Grid item>
                      <Button
                        sx={{ marginLeft: "16px", lineHeight: "24px" }}
                        variant="text"
                        color="primary"
                        size="large"
                        onClick={() => navigate(`/appointment/today`)}
                      >
                        VIEW ALL <ChevronRightIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            {/* Approvals */}
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={6}
              xl={6}
              justifyContent={"space-between"}
              style={{
                // marginTop: "20px",
                paddingBottom: "20px",
                // marginLeft: "0px",
                // marginRight: "0px",
                padding: "20px",
                borderRadius: "8px",
              }}
            >
              <Card
                sx={{
                  padding: "24px",
                  width: "100%",
                  height: "fit-content",
                  boxShadow: 0,
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  spacing={2}
                  style={{ marginBottom: "20px" }}
                >
                  <Typography variant="h5" style={{ fontWeight: 400 }}>
                    Approvals
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <TableContainer
                    component={Paper}
                    style={{ boxShadow: "none" }}
                  >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead sx={{ bgcolor: "background.dark_grey" }}>
                        <TableRow>
                          <TableCell>Appointment ID</TableCell>
                          <TableCell align="center">Date of Request</TableCell>
                          <TableCell align="center">Vehicle</TableCell>
                          <TableCell align="center">Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dashbordData &&
                          dashbordData?.map((item) =>
                            item?.approval_ist?.map((row) => (
                              <TableRow
                                key={row.name}
                                onClick={() =>
                                  navigate(`/approval/${row?._id}`)
                                }
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  cursor: "pointer",
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row?.appointmentNumber}
                                </TableCell>
                                <TableCell align="center">
                                  {row?.approvalRequestedAt &&
                                    moment(row?.approvalRequestedAt).format(
                                      "MMM Do , YYYY"
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                  {row?.vehicle?.year && row?.vehicle?.year}{" "}
                                  {row?.vehicle?.vehicleMake?.name &&
                                    row?.vehicle?.vehicleMake?.name}{" "}
                                  {row?.vehicle?.vehicleModel?.name &&
                                    row?.vehicle?.vehicleModel?.name}{" "}
                                </TableCell>
                                <TableCell align="center">
                                  <Chip
                                    // color="warning"
                                    style={{
                                      backgroundColor:
                                        row?.status == "waiting"
                                          ? APPROVALS_WAITING
                                          : row?.status == "responded"
                                          ? APPROVALS_ACCEPTED
                                          : row?.status == "declined"
                                          ? APPROVALS_DECLINED
                                          : "black",
                                      color:
                                        row?.status == "waiting"
                                          ? "black"
                                          : "white",
                                    }}
                                    label={
                                      row?.status &&
                                      row?.status?.charAt(0)?.toUpperCase() +
                                        row?.status?.slice(1)
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            ))
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    justifyContent={"end"}
                    // marginBottom={"16px"}
                    marginTop={"16px"}
                  >
                    <Grid item>
                      <Button
                        sx={{ marginLeft: "16px", lineHeight: "24px" }}
                        variant="text"
                        color="primary"
                        size="large"
                        onClick={() => navigate(`/approvals/waiting`)}
                      >
                        VIEW ALL <ChevronRightIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={6}
              xl={6}
              // justifyContent={"space-between"}
              style={{
                // marginTop: "20px",
                // backgroundColor: "#fff",
                paddingBottom: "20px",
                // marginLeft: "20px",
                // marginRight: "20px",
                // padding: "20px",
                borderRadius: "8px",
              }}
            >
              <Card
                sx={{
                  padding: "24px",
                  width: "100%",
                  height: "fit-content",
                  boxShadow: "none",
                }}
              >
                {/* Estimates */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  spacing={2}
                  style={{ marginBottom: "20px" }}
                >
                  <Typography variant="h5" style={{ fontWeight: 400 }}>
                    Estimates
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <TableContainer
                    component={Paper}
                    style={{ border: "none", boxShadow: "none" }}
                  >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead sx={{ bgcolor: "background.dark_grey" }}>
                        <TableRow>
                          <TableCell>Estimate No.</TableCell>
                          <TableCell align="center">Customer Name</TableCell>
                          <TableCell align="center">Date</TableCell>
                          <TableCell align="center">Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dashbordData &&
                          dashbordData?.map((item) =>
                            item?.estimates?.map((row) => (
                              <TableRow
                                key={row.name}
                                onClick={() =>
                                  navigate(
                                    `/estimates/${row?.status}/${row?._id}`
                                  )
                                }
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  cursor: "pointer",
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row?.estimateNumber}
                                </TableCell>
                                <TableCell align="center">
                                  {" "}
                                  {row?.customer?.fullName &&
                                    row?.customer?.fullName
                                      ?.charAt(0)
                                      .toUpperCase() +
                                      row?.customer?.fullName.slice(1)}
                                </TableCell>
                                <TableCell align="center">
                                  {row?.estimateDate &&
                                    moment(row?.estimateDate).format(
                                      "MMM Do , YYYY"
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                  <Chip
                                    label={
                                      row?.status &&
                                      row?.status?.charAt(0).toUpperCase() +
                                        row?.status?.slice(1)
                                    }
                                    style={{
                                      backgroundColor:
                                        row?.status == "sent"
                                          ? "#B0BDFC" /* purple */
                                          : row?.status == "draft"
                                          ? "#FDD79C" /* yellow */
                                          : "#FEBBC3" /* pink */,
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    justifyContent={"end"}
                    // marginBottom={"16px"}
                    marginTop={"16px"}
                  >
                    <Grid item>
                      <Button
                        sx={{ marginLeft: "16px", lineHeight: "24px" }}
                        onClick={() => navigate(`/estimates/all`)}
                        variant="text"
                        color="primary"
                        size="large"
                      >
                        VIEW ALL <ChevronRightIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={6}
              xl={6}
              justifyContent={"space-between"}
              style={{
                // marginTop: "20px",
                // backgroundColor: "#fff",
                paddingBottom: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                // padding: "20px",
                borderRadius: "8px",
              }}
            >
              <Card
                sx={{
                  padding: "24px",
                  width: "100%",
                  height: "fit-content",
                  boxShadow: "none",
                }}
              >
                {/* Invoices */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  spacing={2}
                  style={{ marginBottom: "20px" }}
                >
                  <Typography variant="h5" style={{ fontWeight: 400 }}>
                    Invoices
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <TableContainer
                    component={Paper}
                    style={{ boxShadow: "none" }}
                  >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead sx={{ bgcolor: "background.dark_grey" }}>
                        <TableRow>
                          <TableCell>Invoice Number</TableCell>
                          <TableCell align="center">Customer Name</TableCell>
                          <TableCell align="center">Date</TableCell>
                          <TableCell align="center">Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dashbordData &&
                          dashbordData?.map((item) =>
                            item?.invoices?.map((row) => (
                              <TableRow
                                key={row.name}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  navigate(
                                    `/invoice/${row?.status}/${row?._id}`
                                  )
                                }
                              >
                                <TableCell component="th" scope="row">
                                  {row.invoiceNumber}
                                </TableCell>
                                <TableCell align="center">
                                  {row?.customer?.fullName &&
                                    row?.customer?.fullName
                                      ?.charAt(0)
                                      .toUpperCase() +
                                      row?.customer?.fullName?.slice(1)}
                                </TableCell>

                                <TableCell align="center">
                                  {row?.invoiceDate &&
                                    moment(row?.invoiceDate).format(
                                      "MMM Do , YYYY"
                                    )}
                                </TableCell>

                                <TableCell align="center">
                                  <Chip
                                    label={
                                      row?.status &&
                                      row?.status?.charAt(0).toUpperCase() +
                                        row?.status?.slice(1)
                                    }
                                    color={
                                      row?.status == "sent"
                                        ? "primary"
                                        : "secondary"
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            ))
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    justifyContent={"end"}
                    // marginBottom={"16px"}
                    marginTop={"16px"}
                  >
                    <Grid item>
                      <Button
                        sx={{ marginLeft: "16px", lineHeight: "24px" }}
                        variant="text"
                        color="primary"
                        size="large"
                        onClick={() => navigate(`/invoice/all`)}
                      >
                        VIEW ALL <ChevronRightIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <AppointmentRequestModal
        open={open1}
        handleClose={handleClose1}
        appointmentData={appointment}
        id={appointmentId}
        handleCancelAppointment={handleCancelAppointment}
        apiCall={dashboardDetails}
      />
      <AppointmentRescheduleRequestModal
        open={rescheduledRequestOpen}
        setOpen={setRescheduledRequestOpen}
        appointmentData={appointment}
        id={appointmentId}
        apiCall={dashboardDetails}
      />
      <EstimateRequestModal
        open={open}
        handleClose={handleClose}
        estimateData={estimate}
        id={id}
        handleCancel={handleCancel}
      />
      <AppointmentModel
        open={openAppointMentModel}
        handleClose={handleCloseAppointmentModel}
        data={data}
        status={status}
        show={show}
        handleStatusClose={handleStatusClose}
        handleStatus={handleStatus}
      />
      <NewChatList
        openNewChatModel={openNewChatModel}
        handleClose={handleCloseChatModal}
      />
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  );
}
