import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { formatPostalCode } from "../../../components/FormatNumber/FormatNumber";
import { getCityList, getProvinceList } from "../../../redux/Customer/action";
import { CONTACT_PREFRENCES, PHONE_TYPES } from "../../../utility/Constant";
import { handlePreventKeyDown } from "../../../utility/helpers/commonFunction";
import { inputFormatPhoneNumber } from "../../Common/FormatPhoneNumber";
import { SHOP_CREATED } from "../../../config/dataService/constants";
function CustomerProfileQuickEdit({
  data,
  setQuickEdit,
  onSubmitCustomer,
  addressValues,
  setAddressValues,
  type,
}) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();
  const [cityList, setCityList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [cityListLoader, setCityListLoader] = useState(false);
  const [provinceListLoader, setProvinceListLoader] = useState(false);

  const onSubmit = (data) => {
    let payload = data;

    console.log(payload);

    // Process form payload
    onSubmitCustomer(payload);
  };
  useEffect(() => {
    fetchCityList();
    fetchProvinceList();
  }, []);

  const fetchCityList = async (province, country) => {
    setCityListLoader(true);
    let resp = await dispatch(
      getCityList({ country: "64e738882fec8f2c9b79c139", province: province })
    );
    if (resp) {
      setCityList(resp);
    }
    setCityListLoader(false);
  };

  const fetchProvinceList = async (country) => {
    setProvinceListLoader(true);
    let resp = await dispatch(
      getProvinceList({ country: "64e738882fec8f2c9b79c139" })
    );
    if (resp) {
      setProvinceList(resp);
    }
    setProvinceListLoader(false);
  };

  useEffect(() => {
    setValue("firstName", data?.firstName);
    setValue("lastName", data?.lastName);
    setValue("phoneNumber", inputFormatPhoneNumber(data?.phoneNumber));
    setValue(
      "secondaryPhoneNumber",
      inputFormatPhoneNumber(data?.secondaryPhoneNumber)
    );
    setValue("email", data?.email);
    setValue("secondaryEmail", data?.secondaryEmail);
    setValue("company", data?.company);
    setValue("streetAddress", data?.streetAddress);
    setValue("city", data?.city?._id);
    setValue("province", data?.province?._id);
    setValue("postalCode", data?.postalCode);

    setAddressValues({
      ...addressValues,
      city: data?.city?.city,
      province: data?.province?.province,
      cityId: data?.city?._id,
      provinceId: data?.province?._id,
      streetAddress: data?.streetAddress,
      postal: formatPostalCode(data?.postalCode),
    });
  }, [data]);
  return (
    <>
      <Grid item width={"100%"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={6}>
              <TextField
                id="standard-basic"
                label={
                  <div>
                    First Name <span style={{ color: "#D32F2F" }}>*</span>
                  </div>
                }
                variant="standard"
                {...register("firstName", {
                  required: "First name is required",
                  minLength: {
                    value: 3,
                    message: "First name must be at least 3 characters long",
                  },
                })}
                error={!!errors.firstName}
                helperText={errors.firstName ? errors.firstName.message : ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="standard-basic"
                label="Last Name"
                variant="standard"
                {...register("lastName", {
                  //   required: "Last name is required",
                  //   minLength: {
                  //     value: 3,
                  //     message: "Last name must be at least 3 characters long",
                  //   },
                })}
                error={!!errors.lastName}
                helperText={errors.lastName ? errors.lastName.message : ""}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                disabled={type == SHOP_CREATED ? false : true}
                id="standard-basic"
                label={
                  <div>
                    {PHONE_TYPES.find(
                      (d) => d.key == data?.primaryPhoneNumberType
                    ).value + " (Primary)"}{" "}
                    <span style={{ color: "#D32F2F" }}>*</span>
                  </div>
                }
                variant="standard"
                {...register("phoneNumber", {
                  required: "Phone number is required",
                  pattern: {
                    value: /^\d{3}-\d{3}-\d{4}$/,
                    message: "Phone number should be 10 digits long",
                  },
                })}
                error={!!errors.phoneNumber}
                helperText={
                  errors.phoneNumber ? errors.phoneNumber.message : ""
                }
                onChange={(e) => {
                  setValue(
                    "phoneNumber",
                    inputFormatPhoneNumber(e.target.value)
                  );
                  if (e.target.value.length == 12) {
                    clearErrors("phoneNumber");
                  } else {
                    setError("phoneNumber", {
                      message: "Phone number should be 10 digits long",
                    });
                  }
                }}
                onKeyDown={handlePreventKeyDown}
                fullWidth
              />
            </Grid>
            {data?.secondaryPhoneNumber && (
              <Grid item xs={12}>
                <TextField
                  disabled={type == SHOP_CREATED ? false : true}
                  id="standard-basic"
                  label={
                    PHONE_TYPES.find(
                      (d) => d.key == data?.secondaryPhoneNumberType
                    ).value
                  }
                  variant="standard"
                  {...register("secondaryPhoneNumber", {
                    //   required: "secondaryPhoneNumber is required",
                  })}
                  error={!!errors.secondaryPhoneNumber}
                  helperText={
                    errors.secondaryPhoneNumber
                      ? errors.secondaryPhoneNumber.message
                      : ""
                  }
                  onChange={(e) => {
                    setValue(
                      "secondaryPhoneNumber",
                      inputFormatPhoneNumber(e.target.value)
                    );
                    if (
                      e.target.value.length == 12 ||
                      e.target.value.length == 0
                    ) {
                      clearErrors("secondaryPhoneNumber");
                    } else {
                      setError("secondaryPhoneNumber", {
                        message: "Phone number should be 10 digits long",
                      });
                    }
                  }}
                  onKeyDown={handlePreventKeyDown}
                  fullWidth
                />
              </Grid>
            )}

            <Grid item xs={12}> 
              <TextField
                disabled={type == SHOP_CREATED ? false : true}
                id="standard-basic"
                label="Email Address"
                variant="standard"
                {...register("email", {
                  // required: "Email address is required",
                })}
                type="email"
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
                fullWidth
              />
            </Grid>
            {data?.secondaryEmail && (
              <Grid item xs={12}>
                <TextField
                  disabled={type == SHOP_CREATED ? false : true}
                  id="standard-basic"
                  label="Secondary Email Address"
                  variant="standard"
                  {...register("secondaryEmail", {
                    //   required: "Email address is required",
                  })}
                  type="email"
                  error={!!errors.secondaryEmail}
                  helperText={
                    errors.secondaryEmail ? errors.secondaryEmail.message : ""
                  }
                  fullWidth
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                id="standard-basic"
                label="Company name"
                variant="standard"
                {...register("company", {
                  //   required: "Company name is required",
                })}
                error={!!errors.company}
                helperText={errors.company ? errors.company.message : ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-basic"
                label={
                  <div>
                    Street Address
                    {(addressValues.streetAddress ||
                      addressValues.province ||
                      addressValues.city ||
                      addressValues.postal) && (
                      <span style={{ color: "#D32F2F" }}> *</span>
                    )}
                  </div>
                }
                variant="standard"
                {...register("streetAddress", {
                  required:
                    addressValues.streetAddress ||
                    addressValues.province ||
                    addressValues.city ||
                    addressValues.postal
                      ? "Street Address is required"
                      : false,
                })}
                error={!!errors.streetAddress}
                helperText={
                  errors.streetAddress ? errors.streetAddress.message : ""
                }
                onChange={(e) => {
                  setAddressValues({
                    ...addressValues,
                    streetAddress: e.target.value,
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                select
                id="standard-basic"
                label={
                  <div>
                    City
                    {(addressValues.streetAddress ||
                      addressValues.province ||
                      addressValues.city ||
                      addressValues.postal) && (
                      <span style={{ color: "#D32F2F" }}> *</span>
                    )}
                  </div>
                }
                variant="standard"
                defaultValue={addressValues?.cityId}
                {...register("city", {
                  required:
                    addressValues.streetAddress ||
                    addressValues.province ||
                    addressValues.city ||
                    addressValues.postal
                      ? "City is required"
                      : false,
                })}
                error={!!errors.city}
                helperText={errors.city ? errors.city.message : ""}
                fullWidth
                onChange={(e) => (
                  setAddressValues({
                    ...addressValues,
                    city: cityList?.find((d) => d._id == e.target.value),
                    cityId: e.target.value,
                    province: cityList?.find((d) => d._id == e.target.value)
                      .province?.province,
                    provinceId: cityList?.find((d) => d._id == e.target.value)
                      .province?._id,
                    countryId: cityList?.find((d) => d._id == e.target.value)
                      ?.country?._id,
                  }),
                  console.log(
                    "cityList?.find((d) => d._id == e.target.value)",
                    cityList?.find((d) => d._id == e.target.value)
                  ),
                  setValue(
                    "province",
                    cityList?.find((d) => d._id == e.target.value).province?._id
                  )
                )}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 200, // Limit the height of the dropdown
                      },
                    },
                  },
                }}
              >
                {cityList?.map((city) => (
                  <MenuItem key={city?._id} value={city?._id}>
                    {city?.city}
                  </MenuItem>
                ))}
                {cityListLoader && (
                  <MenuItem key={null} value={null} disabled>
                    <CircularProgress size={30} />
                  </MenuItem>
                )}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                select
                id="standard-basic"
                label={
                  <div>
                    Province
                    {(addressValues.streetAddress ||
                      addressValues.province ||
                      addressValues.city ||
                      addressValues.postal) && (
                      <span style={{ color: "#D32F2F" }}> *</span>
                    )}
                  </div>
                }
                variant="standard"
                value={addressValues?.provinceId ?? null}
                {...register("province", {
                  required:
                    addressValues.streetAddress ||
                    addressValues.province ||
                    addressValues.city ||
                    addressValues.postal
                      ? "Province is required"
                      : false,
                })}
                error={!!errors.province}
                helperText={errors.province ? errors.province.message : ""}
                fullWidth
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 200, // Limit the height of the dropdown
                      },
                    },
                  },
                }}
                InputLabelProps={{
                  shrink: addressValues?.provinceId?.length > 0,
                }}
                onChange={(e) => (
                  setAddressValues({
                    ...addressValues,
                    province: provinceList?.find(
                      (d) => d._id == e.target.value
                    ),
                    provinceId: e.target.value,
                    city: "",
                    cityId: "",
                    countryId: provinceList?.find(
                      (d) => d._id == e.target.value
                    )?.country?._id,
                  }),
                  setCityList([]),
                  fetchCityList(e.target.value)
                  //   setValue("city", "")
                )}
              >
                {provinceList?.map((province) => (
                  <MenuItem key={province?._id} value={province?._id}>
                    {province?.province}
                  </MenuItem>
                ))}
                {provinceListLoader && (
                  <MenuItem key={null} value={null} disabled>
                    <CircularProgress />
                  </MenuItem>
                )}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="standard-basic"
                label={
                  <div>
                    Postal Code
                    {(addressValues.streetAddress ||
                      addressValues.province ||
                      addressValues.city ||
                      addressValues.postal) && (
                      <span style={{ color: "#D32F2F" }}> *</span>
                    )}
                  </div>
                }
                variant="standard"
                {...register("postalCode", {
                  required:
                    addressValues.streetAddress ||
                    addressValues.province ||
                    addressValues.city ||
                    addressValues.postal
                      ? "Postal code is required"
                      : false,
                })}
                error={!!errors.postalCode}
                helperText={errors.postalCode ? errors.postalCode.message : ""}
                fullWidth
                inputProps={{
                  style: { textTransform: "uppercase" },
                  maxlength: 7,
                  minLength: 7,
                }}
                onChange={(e) => {
                  if (formatPostalCode(e.target.value).length != 7) {
                    setError("postalCode", {
                      message: "Enter Valid Postal Code",
                    });
                  } else {
                    clearErrors("postalCode");
                  }
                  setValue("postalCode", formatPostalCode(e.target.value));
                  setAddressValues({
                    ...addressValues,
                    postal: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                id="standard-basic"
                label="Contact Preferences:"
                variant="standard"
                defaultValue={data?.contactPrefrence}
                {...register("contactPrefrence", {
                  // required: "Contact Preferences is required",
                })}
                error={!!errors.contactPrefrence}
                helperText={
                  errors.contactPrefrence ? errors.contactPrefrence.message : ""
                }
                fullWidth
              >
                {CONTACT_PREFRENCES.map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid
            item
            container
            justifyContent={"end"}
            // paddingLeft={"8px"}
            paddingTop={"24px"}
          >
            <Stack spacing={1} direction={"row"}>
              <Button
                variant="outlined"
                onClick={() => {
                  setQuickEdit(false);
                }}
              >
                DISCARD
              </Button>

              <Button
                variant="contained"
                id="demo-positioned-button"
                type="submit"
              >
                SAVE
              </Button>
            </Stack>
          </Grid>
        </form>
      </Grid>
      <Divider style={{ width: "100%", marginTop: "23px" }} />
      <Grid
        item
        container
        justifyContent={"start"}
        paddingLeft={"8px"}
        paddingTop={"24px"}
        style={{ opacity: 0.5, pointerEvents: "none" }}
      >
        <Stack spacing={1} direction={"row"}>
          <Button variant="contained">SEND MESSAGE</Button>
          <Button variant="outlined" endIcon={<ExpandMoreIcon />}>
            ACTIONS
          </Button>
        </Stack>
      </Grid>
    </>
  );
}

export default CustomerProfileQuickEdit;
