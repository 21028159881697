import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
// import "./Appointment.css";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { useTheme } from "@emotion/react";
import { InfoOutlined } from "@mui/icons-material";
import {
  Alert,
  Badge,
  Breadcrumbs,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FormatNumberForKm } from "../../../components/FormatNumber/FormatNumber";
import {
  appointmentDetails,
  updateAppointmentStatus,
} from "../../../redux/appointment/action";
import { USER_CREATED_FROM } from "../../../utility/Constant";
import { formatPhoneNumber } from "../../Common/FormatPhoneNumber";
import SuccessMessageModal from "../../Common/SuccessMessage";
import GuestCustomerInfoModal from "../../Customers/GuestCustomers/GuestCustomerInfoModal";
import SaveCustomerConfirmModal from "../../Customers/GuestCustomers/SaveCustomerConfirmModal";
import AddOdoModal from "../../modal/AddOdoModal";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}
const styleTag = {
  borderRadius: "48px",
  color: "black",
  padding: "10px 20px",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  margin: "10px",
  width: "200px",
};
const serviceStyle = {
  fontSize: "13px",
  fontWeight: 500,
  // borderStyle: "solid",
  // borderWidth: "1px",
  // borderColor: "black",
  padding: "5px 10px",
  borderRadius: "20px",
  marginLeft: "10px",
  // color: "white",
};
const PastAppointmentView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [showLoader, setShowLoader] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [getAppointmentData, setGetAppointmentData] = React.useState("");
  const [updateStatus, setUpdateStatus] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");

  const [openGuestModal, setOpenGuestModal] = React.useState(false);
  const [saveConfirmModal, setSaveConfirmModal] = React.useState(false);

  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    appointmentView();
  }, []);

  const appointmentView = async () => {
    setShowLoader(true);
    const data = await dispatch(appointmentDetails(id));
    if (data) {
      setGetAppointmentData(data);
    }
    setShowLoader(false);
  };

  const handlePreviewPdf = () => {
    const pdfUrl = getAppointmentData?.estimate?.pdfUrl;
    window.open(pdfUrl, "_blank");
  };

  const handlePreviewInvoicePdf = () => {
    const pdfUrl = getAppointmentData?.invoice?.pdfUrl;
    window.open(pdfUrl, "_blank");
    // Close the menu
    // handleClose1();
  };
  const open1 = Boolean(anchorEl);
  const handleClose = () => {
    // setOpen(false);
    setShow(false);
    // kanbanBoardData();
  };
  const handleStatusClose = () => {
    setShow(false);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const handleStatus = () => {
    setShow(true);
    handleClose1();
  };

  const UpdateStatus = async (id, status) => {
    handleClose1();
    setShowLoader(true);
    let payload = {
      status: status,
    };
    const statusUpdate = await dispatch(updateAppointmentStatus(id, payload));

    if (statusUpdate) {
      setOpenSuccessModal(true);
      setSuccessMessage("Status Updated Successfully");
      // setTimeout(() => {
      handleClose();

      appointmentView();
      //   setShowLoader(false);
      // }, 1000);
    }

    setShowLoader(false);
    // appointmentView();
  };

  if (showLoader) {
    return (
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <Box padding={"24px"}>
      <Grid container sx={{ padding: "16px 0px 0 0" }} gap={"8px"}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                to="/appointment/today"
                style={{
                  color: "black",
                  opacity: "0.6",
                  textDecoration: "none",
                }}
              >
                Appointments
              </Link>
              <Link
                to="/appointment/past-appointments"
                style={{
                  color: "black",
                  opacity: "0.6",
                  textDecoration: "none",
                }}
              >
                Past Appointment
              </Link>
              <Link
                to={`/appointment/past-appointment/${id}`}
                style={{
                  color: "black",
                  opacity: "0.6",
                  textDecoration: "none",
                }}
              >
                {getAppointmentData && getAppointmentData?.appointmentNumber}
              </Link>
            </Breadcrumbs>
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h4" fontWeight={500} lineHeight={"42px"}>
            {getAppointmentData && getAppointmentData?.estimateNumber}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={12}>
          <Typography fontWeight={500} fontSize={"34px"} lineHeight={"42px"}>
            Past Appointment
          </Typography>
        </Grid>

        {getAppointmentData?.status == "mark_as_no_show" && (
          <Grid container item mb={2} mt={2.5}>
            <Alert
              sx={{
                width: "100%",
                fontSize: "16px",
                fontWeight: 500,
                color: "black",
                background: "#FFB3B3",
              }}
              // severity="error"
              icon={<InfoOutlined sx={{ color: "#FF2727" }} />}
            >
              No Show
            </Alert>
          </Grid>
        )}

        <Grid container justifyContent={"end"} gap={2}>
          {/* <Grid item> */}
          {/* <Stack spacing={2} direction={"row"}> */}
          {getAppointmentData?.status != "mark_as_no_show" &&
          getAppointmentData?.status != "customer_paid" ? (
            <Grid item>
              <Button
                variant="contained"
                endIcon={<KeyboardArrowDownIcon />}
                style={{
                  backgroundColor:
                    getAppointmentData?.status == "waiting_for_drop_off"
                      ? "#75DBFF"
                      : getAppointmentData?.status == "vehicle_dropped_off"
                      ? "#FFC30F"
                      : getAppointmentData?.status == "in_progress"
                      ? "#FF9E31"
                      : getAppointmentData?.status == "ready_for_pick_up"
                      ? "#5CE469"
                      : getAppointmentData?.status == "customer_paid"
                      ? "#00CD44"
                      : "#F44336",
                  //   : "#5CE469",
                  borderRadius: "48px",
                  color: "black",
                  padding: "6px 16px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                // aria-controls={open1 ? "demo-positioned-menu" : undefined}
                // aria-haspopup="true"
                // aria-expanded={open1 ? "true" : undefined}
                onClick={handleClick1}
              >
                {getAppointmentData?.status == "waiting_for_drop_off" &&
                  "Waiting for drop off"}
                {getAppointmentData?.status == "vehicle_dropped_off" &&
                  "VEHICLE DROPPED OFF"}{" "}
                {getAppointmentData?.status == "in_progress" && "IN PROGRESS"}{" "}
                {getAppointmentData?.status == "customer_paid" &&
                  "CUSTOMER PAID"}{" "}
                {getAppointmentData?.status == "mark_as_no_show" &&
                  "MARK AS NO SHOW"}
                {getAppointmentData?.status == "ready_for_pick_up" &&
                  "READY FOR PICKUP"}
              </Button>

              <Menu
                style={{ width: "400px" }}
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open1}
                onClose={handleClose1}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem>Update Status</MenuItem>
                <MenuItem
                  onClick={() => {
                    setUpdateStatus("waiting_for_drop_off");
                    handleStatus();
                  }}
                  disabled={true}
                  style={{ ...styleTag, backgroundColor: "#75DBFF" }}
                >
                  Waiting for Drop Off
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setUpdateStatus("vehicle_dropped_off");
                    if (getAppointmentData?.status == "waiting_for_drop_off") {
                      handleStatus();
                    } else {
                      UpdateStatus(
                        getAppointmentData?._id,
                        "vehicle_dropped_off"
                      );
                    }
                  }}
                  disabled={
                    getAppointmentData?.status == "waiting_for_drop_off" ||
                    getAppointmentData?.status == "in_progress"
                      ? false
                      : true
                  }
                  style={{
                    ...styleTag,
                    background: "#FFC30F",
                  }}
                >
                  Vehicle Dropped Off
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setUpdateStatus("in_progress");
                    UpdateStatus(getAppointmentData?._id, "in_progress");
                  }}
                  disabled={
                    getAppointmentData?.status == "waiting_for_drop_off" ||
                    getAppointmentData?.status == "vehicle_dropped_off" ||
                    getAppointmentData?.status == "ready_for_pick_up"
                      ? false
                      : true
                  }
                  style={{
                    ...styleTag,
                    background: "#FF9E31",
                  }}
                >
                  In Progress
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setUpdateStatus("ready_for_pick_up");
                    if (
                      getAppointmentData?.status == "waiting_for_drop_off" ||
                      getAppointmentData?.status == "in_progress"
                    ) {
                      handleStatus();
                    } else {
                      UpdateStatus(
                        getAppointmentData?._id,
                        "ready_for_pick_up"
                      );
                    }
                  }}
                  disabled={
                    getAppointmentData?.status == "waiting_for_drop_off" ||
                    getAppointmentData?.status == "vehicle_dropped_off" ||
                    getAppointmentData?.status == "in_progress"
                      ? false
                      : true
                  }
                  style={{
                    ...styleTag,
                    background: "#5CE469",
                  }}
                >
                  Ready for Pick Up
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setUpdateStatus("customer_paid");
                    if (getAppointmentData?.status == "waiting_for_drop_off") {
                      handleStatus();
                    } else {
                      UpdateStatus(getAppointmentData?._id, "customer_paid");
                    }
                  }}
                  disabled={
                    getAppointmentData?.status == "waiting_for_drop_off" ||
                    getAppointmentData?.status == "vehicle_dropped_off" ||
                    getAppointmentData?.status == "in_progress" ||
                    getAppointmentData?.status == "ready_for_pick_up"
                      ? false
                      : true
                  }
                  style={{
                    ...styleTag,
                    background: "#00CD44",
                  }}
                >
                  Customer Paid
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setUpdateStatus("mark_as_no_show");
                    UpdateStatus(getAppointmentData?._id, "mark_as_no_show");
                  }}
                  disabled={
                    getAppointmentData?.status == "waiting_for_drop_off"
                      ? false
                      : true
                  }
                  style={{
                    ...styleTag,
                    background: "#F44336",
                  }}
                >
                  Mark as No Show
                </MenuItem>
              </Menu>
            </Grid>
          ) : (
            ""
          )}

          {(getAppointmentData?.status == "waiting_for_drop_off" ||
            getAppointmentData?.status == "in_progress") && (
            <AddOdoModal
              open={show}
              handleClose={handleClose}
              updateStatus={updateStatus}
              id={getAppointmentData?._id}
              handleClose1={handleClose}
              currentStatus={getAppointmentData?.status}
              handleClose2={handleClose}
              appointmentView={appointmentView}
            />
          )}
          {getAppointmentData?.hasInvoice ? (
            <Button
              style={{ backgroundColor: "#002394" }}
              variant="contained"
              // onClick={handlePreviewInvoicePdf}
              onClick={() => {
                if (getAppointmentData?.invoice?.status == "sent") {
                  navigate(`/invoice/sent/${getAppointmentData?.invoice?._id}`);
                } else {
                  navigate(
                    `/invoice/draft/${getAppointmentData?.invoice?._id}`
                  );
                }
              }}
            >
              {" "}
              <VisibilityIcon style={{ marginRight: "10px" }} />
              VIEW INVOICE
            </Button>
          ) : (
            <>
              {getAppointmentData?.status == "customer_paid" && (
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#002394" }}
                  onClick={() => {
                    if (
                      getAppointmentData?.customer?.createdFrom ===
                      USER_CREATED_FROM.SHOP_GUEST
                    ) {
                      setSaveConfirmModal(true);
                    } else {
                      navigate(`/invoice/new/${getAppointmentData?._id}`);
                    }
                  }}
                >
                  {" "}
                  <EditIcon style={{ marginRight: "10px" }} />
                  CREATE INVOICE
                </Button>
              )}
            </>
          )}
          {getAppointmentData?.hasEstimate && (
            <Button
              variant="contained"
              style={{ backgroundColor: "#002394" }}
              onClick={handlePreviewPdf}
            >
              <VisibilityIcon style={{ marginRight: "10px" }} /> PREVIEW
              ESTIMATE
            </Button>
          )}
          <Button
            style={{ backgroundColor: "#002394" }}
            variant="contained"
            onClick={() => {
              if (
                getAppointmentData?.customer?.createdFrom ===
                USER_CREATED_FROM.SHOP_GUEST
              ) {
                setOpenGuestModal(true);
              } else {
                navigate(
                  `/customers/${getAppointmentData?.customer?._id}/${getAppointmentData?.customer?.createdFrom}`
                );
              }
            }}
          >
            {" "}
            <VisibilityIcon style={{ marginRight: "10px" }} />
            VIEW CUSTOMER
          </Button>
          {getAppointmentData?.workOrderAuthorization && (
            <Badge
              invisible={
                getAppointmentData?.workOrderAuthorization?.showUnreadBadge ==
                true
                  ? false
                  : true
              }
              badgeContent={""}
              color="error"
            >
              <Button
                style={{ backgroundColor: "#002394" }}
                variant="contained"
                onClick={() => {
                  navigate(`/appointment/workOrderAuthorization/${id}`);
                }}
              >
                <VisibilityIcon style={{ marginRight: "10px" }} />
                VIEW AUTHORIZATION
              </Button>
            </Badge>
          )}
          {/* </Stack> */}
          {/* </Grid> */}
        </Grid>
      </Grid>
      <div className="appointment-view-container">
        <Grid container spacing={3}>
          <Grid item>
            <Typography variant="h4" fontWeight={400}>
              {getAppointmentData?.appointmentNumber}:{" "}
              {getAppointmentData && getAppointmentData?.customer?.firstName}{" "}
              {getAppointmentData && getAppointmentData?.customer?.lastName}{" "}
            </Typography>
          </Grid>

          <Grid
            item
            container
            md={12}
            spacing={2}
            columns={{ xs: 12, sm: 12, md: 6, lg: "auto" }}
            justifyContent={"space-between"}
          >
            <Grid item lg={"auto"} md={6} sm={12} xs={12}>
              <Stack spacing={0.5}>
                <Typography
                  fontWeight={500}
                  fontSize={"20px"}
                  lineHeight={"32px"}
                  letterSpacing={"0.15px"}
                >
                  Dates
                </Typography>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    fontWeight={500}
                    lineHeight={"24px"}
                    letterSpacing={"0.15px"}
                  >
                    Dropped Off:{" "}
                  </Typography>
                  <Typography
                    fontWeight={400}
                    lineHeight={"24px"}
                    letterSpacing={"0.15px"}
                  >
                    {moment(getAppointmentData?.dropOffDateTime).format(
                      "MMM D, YYYY"
                    )}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    fontWeight={500}
                    lineHeight={"24px"}
                    letterSpacing={"0.15px"}
                  >
                    Picked Up:{" "}
                  </Typography>
                  <Typography
                    fontWeight={400}
                    lineHeight={"24px"}
                    letterSpacing={"0.15px"}
                  >
                    {moment(getAppointmentData?.pickupDateTime).format(
                      "MMM D, YYYY"
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item lg={"auto"} md={6} sm={12} xs={12}>
              <Stack spacing={0.5}>
                <Typography
                  fontWeight={500}
                  fontSize={"20px"}
                  lineHeight={"32px"}
                  letterSpacing={"0.15px"}
                >
                  Customer Information
                </Typography>
                <Typography
                  fontWeight={400}
                  lineHeight={"24px"}
                  letterSpacing={"0.15px"}
                >
                  {getAppointmentData &&
                    getAppointmentData?.customer?.firstName}{" "}
                  {getAppointmentData && getAppointmentData?.customer?.lastName}{" "}
                </Typography>
                <Typography
                  fontWeight={400}
                  lineHeight={"24px"}
                  letterSpacing={"0.15px"}
                >
                  {/* {getAppointmentData?.customer?.phoneNumber ? getAppointmentData?.customer?.phoneNumber : " "} */}
                  {formatPhoneNumber(getAppointmentData?.customer?.phoneNumber)}
                </Typography>
                <Typography
                  fontWeight={400}
                  lineHeight={"24px"}
                  letterSpacing={"0.15px"}
                >
                  {getAppointmentData?.customer?.email
                    ? getAppointmentData?.customer?.email
                    : " "}
                </Typography>
              </Stack>
            </Grid>
            <Grid item lg={"auto"} md={6} sm={12} xs={12}>
              <Stack spacing={0.5}>
                <Typography
                  fontWeight={500}
                  fontSize={"20px"}
                  lineHeight={"32px"}
                  letterSpacing={"0.15px"}
                >
                  Vehicle Information
                </Typography>
                {/* {console.log("test foo", getAppointmentData?.vehicle)} */}
                <Typography
                  fontWeight={400}
                  lineHeight={"24px"}
                  letterSpacing={"0.15px"}
                >
                  {getAppointmentData && getAppointmentData?.vehicle?.year}{" "}
                  {getAppointmentData?.vehicle &&
                    getAppointmentData?.vehicle?.vehicleMake?.name}{" "}
                  {getAppointmentData?.vehicle &&
                    getAppointmentData?.vehicle?.vehicleModel?.name}{" "}
                  {getAppointmentData?.vehicle &&
                  getAppointmentData?.vehicle?.vehicleModelTrim?.name
                    ? getAppointmentData?.vehicle?.vehicleModelTrim?.name
                    : ""}
                </Typography>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    fontWeight={500}
                    lineHeight={"24px"}
                    letterSpacing={"0.15px"}
                  >
                    VIN:{" "}
                  </Typography>
                  <Typography
                    fontWeight={400}
                    lineHeight={"24px"}
                    letterSpacing={"0.15px"}
                  >
                    {getAppointmentData?.vehicle?.vin
                      ? getAppointmentData?.vehicle?.vin
                      : " -"}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    fontWeight={500}
                    lineHeight={"24px"}
                    letterSpacing={"0.15px"}
                  >
                    Licence Plate Number:{" "}
                  </Typography>
                  <Typography
                    fontWeight={400}
                    lineHeight={"24px"}
                    letterSpacing={"0.15px"}
                  >
                    {getAppointmentData?.vehicle?.licenseNumber
                      ? getAppointmentData?.vehicle?.licenseNumber
                      : " -"}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    fontWeight={500}
                    lineHeight={"24px"}
                    letterSpacing={"0.15px"}
                  >
                    Odometer Dropped Off:{" "}
                  </Typography>
                  <Typography
                    fontWeight={400}
                    lineHeight={"24px"}
                    letterSpacing={"0.15px"}
                  >
                    {getAppointmentData?.dropOffODO
                      ? FormatNumberForKm(getAppointmentData?.dropOffODO) + "km"
                      : " -"}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    fontWeight={500}
                    lineHeight={"24px"}
                    letterSpacing={"0.15px"}
                  >
                    Odometer Returned:{" "}
                  </Typography>
                  <Typography
                    fontWeight={400}
                    lineHeight={"24px"}
                    letterSpacing={"0.15px"}
                  >
                    {getAppointmentData?.completionODO
                      ? FormatNumberForKm(getAppointmentData?.completionODO) +
                        "km"
                      : " -"}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item md={12}>
            <Typography
              fontWeight={500}
              fontSize={"20px"}
              lineHeight={"32px"}
              letterSpacing={"0.15px"}
            >
              Note for{" "}
              {getAppointmentData && getAppointmentData?.customer?.firstName}{" "}
              {getAppointmentData && getAppointmentData?.customer?.lastName}{" "}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography
              fontWeight={400}
              lineHeight={"24px"}
              letterSpacing={"0.15px"}
              color={"rgba(132, 132, 132, 1)"}
            >
              {getAppointmentData?.noteForCustomer ?? " "}
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={12}>
            <Typography
              fontWeight={500}
              fontSize={"20px"}
              lineHeight={"32px"}
              letterSpacing={"0.15px"}
            >
              Total Services:{" "}
              {getAppointmentData && getAppointmentData?.services.length}
            </Typography>
          </Grid>
        </Grid>
        {getAppointmentData &&
          getAppointmentData?.services.map((item, index) => {
            return (
              <>
                <Grid container>
                  <Grid
                    container
                    item
                    style={{
                      backgroundColor: "#002394",
                      color: "#FFFFFF",
                      borderRadius: "4px 4px 0 0",
                    }}
                    sm={12}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        fontWeight={"500"}
                        padding={"16px"}
                        fontSize={"20px"}
                        letterSpacing={"0.15px"}
                        lineHeight={"32px"}
                      >
                        {item?.serviceName}
                      </Typography>
                      {item.approvalStatus && (
                        <Chip
                          style={{
                            background:
                              item?.approvalStatus == "waiting"
                                ? "#FFB400"
                                : item?.approvalStatus == "approved"
                                ? "#30D158"
                                : item?.approvalStatus == "declined"
                                ? "#FF2727"
                                : item?.approvalStatus == "deferred"
                                ? "#000000"
                                : "",
                            marginRight: "10px",
                            textTransform: "capitalize",
                          }}
                          className="chip-text-style"
                          label={item?.approvalStatus}
                        />
                      )}
                    </Grid>
                  </Grid>
                  {item?.pricingType == "labor_&_parts" ? (
                    <Grid container>
                      <Grid item xl={12} md={12} sm={12} xs={12} boxShadow={0}>
                        <TableContainer>
                          <Table aria-label="caption table" padding="16px">
                            <TableHead
                              style={{
                                backgroundColor: "#f4f6f8",
                                fontWeight: "bold",
                              }}
                            >
                              <TableRow style={{ fontWeight: "bold" }}>
                                <TableCell width={"9.6%"}>No.</TableCell>
                                <TableCell width={"600px"}>
                                  {" "}
                                  Description
                                </TableCell>
                                <TableCell width={"9.6%"}> Quantity</TableCell>
                                <TableCell width={"9.6%"}> Cost</TableCell>
                                <TableCell width={"9.6%"}> Amount</TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {item?.labor && (
                                <TableRow key={item.no}>
                                  <TableCell component="th" scope="row">
                                    1
                                  </TableCell>
                                  <TableCell align="left">Labor</TableCell>
                                  <TableCell align="left">
                                    {item?.labor?.qty}
                                  </TableCell>
                                  <TableCell align="left">
                                    ${item?.labor?.ratePerHour}
                                  </TableCell>
                                  <TableCell align="left">
                                    ${item?.labor?.totalAmount}
                                  </TableCell>
                                </TableRow>
                              )}

                              {item?.parts.map((row, index) => (
                                <TableRow key={row.no}>
                                  <TableCell component="th" scope="row">
                                    {index + 2}
                                  </TableCell>
                                  <TableCell align="left">
                                    <div>{row.partName}</div>
                                    <div>
                                      Part Provider :
                                      {row?.partsProvider == "oem" && "OEM"}{" "}
                                      {row?.partsProvider == "after_market" &&
                                        "After Market"}{" "}
                                      {row?.partsProvider == "used" && "Used"}
                                    </div>
                                    <div>Part Number : {row.partNumber}</div>
                                    <div>{row.description}</div>
                                  </TableCell>
                                  <TableCell align="left">{row.qty}</TableCell>
                                  <TableCell align="left">
                                    ${row.cost}
                                  </TableCell>
                                  <TableCell align="left">
                                    ${row.totalAmount}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  ) : item?.pricingType == "fixed" ? (
                    <>
                      <Grid container xs={12} item>
                        <Grid
                          item
                          xl={12}
                          md={12}
                          sm={12}
                          xs={12}
                          boxShadow={0}
                        >
                          <TableContainer sx={{ maxHeight: 600 }}>
                            <Table
                              stickyHeader
                              aria-label="caption table"
                              // md={{ minWidth: 850 }}
                              // padding="16px"
                            >
                              <TableHead
                                style={{
                                  backgroundColor: "#f4f6f8",
                                  fontWeight: "bold",
                                }}
                              >
                                <TableRow style={{ fontWeight: "bold" }}>
                                  <TableCell width={"9.6%"}>No.</TableCell>
                                  <TableCell> Service Name</TableCell>
                                  <TableCell width={"9.6%"}>Quantity</TableCell>
                                  <TableCell width={"9.6%"}>Cost</TableCell>
                                  <TableCell width={"9.6%"}>Amount</TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                <TableRow key={item.no}>
                                  <TableCell component="th" scope="row">
                                    1
                                  </TableCell>
                                  <TableCell align="left">
                                    {item?.serviceName}
                                  </TableCell>
                                  <TableCell align="left">1</TableCell>
                                  <TableCell align="left">
                                    ${item?.fixedPrice}
                                  </TableCell>
                                  <TableCell align="left">
                                    ${item?.fixedPrice}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    </>
                  ) : item?.pricingType == "range" ? (
                    <>
                      <Grid container item>
                        <Grid
                          item
                          xl={12}
                          md={12}
                          sm={12}
                          xs={12}
                          boxShadow={0}
                        >
                          <TableContainer>
                            <Table aria-label="caption table" padding="16px">
                              <TableHead
                                style={{
                                  backgroundColor: "#f4f6f8",
                                  fontWeight: "bold",
                                }}
                              >
                                <TableRow style={{ fontWeight: "bold" }}>
                                  <TableCell width={"9.6%"}>No.</TableCell>
                                  <TableCell width={"600px"}>
                                    {" "}
                                    Service Name
                                  </TableCell>
                                  <TableCell width={"9.6%"}>
                                    {" "}
                                    Quantity
                                  </TableCell>
                                  <TableCell width={"9.6%"}> Cost</TableCell>
                                  <TableCell width={"9.6%"}> Amount</TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                <TableRow key={item.no}>
                                  <TableCell component="th" scope="row">
                                    1
                                  </TableCell>
                                  <TableCell align="left">
                                    {item?.serviceName}
                                  </TableCell>

                                  <TableCell>1</TableCell>
                                  <TableCell align="left">
                                    {"$" +
                                      item?.priceMin +
                                      " - " +
                                      "$" +
                                      item?.priceMax}
                                  </TableCell>
                                  <TableCell align="left">
                                    {"$" +
                                      item?.priceMin +
                                      " - " +
                                      "$" +
                                      item?.priceMax}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid container item>
                        <Grid
                          item
                          xl={12}
                          md={12}
                          sm={12}
                          xs={12}
                          boxShadow={0}
                        >
                          <TableContainer>
                            <Table aria-label="caption table" padding="16px">
                              <TableHead
                                style={{
                                  backgroundColor: "#f4f6f8",
                                  fontWeight: "bold",
                                }}
                              >
                                <TableRow style={{ fontWeight: "bold" }}>
                                  <TableCell width={"9.6%"}>No.</TableCell>
                                  <TableCell> Service Name</TableCell>
                                  <TableCell width={"9.6%"}>
                                    {" "}
                                    Quantity
                                  </TableCell>
                                  <TableCell width={"9.6%"}> Cost</TableCell>
                                  <TableCell width={"9.6%"}> Amount</TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                <TableRow key={item.no}>
                                  <TableCell component="th" scope="row">
                                    1
                                  </TableCell>
                                  <TableCell align="left">
                                    {item?.serviceName}
                                  </TableCell>
                                  <TableCell align="left">1</TableCell>
                                  <TableCell align="left">-</TableCell>
                                  <TableCell align="left">-</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            );
          })}
      </div>

      <GuestCustomerInfoModal
        userData={getAppointmentData}
        openGuestModal={openGuestModal}
        setOpenGuestModal={setOpenGuestModal}
        showTag
        fetchAppointmentDetails={appointmentView}
      />
      <SaveCustomerConfirmModal
        userData={getAppointmentData}
        open={saveConfirmModal}
        setSaveConfirmModal={setSaveConfirmModal}
        userName={
          getAppointmentData?.customer?.firstName +
          " " +
          getAppointmentData?.customer?.lastName
        }
        handleClose={() => {
          setSaveConfirmModal(false);
          navigate(`/invoice/new/${getAppointmentData?._id}`);
          appointmentView();
        }}
      />
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
    </Box>
  );
};

export default PastAppointmentView;
