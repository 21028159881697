import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "./Appointment.css";

import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Badge,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Menu,
  MenuItem,
  Modal,
  Stack,
  Toolbar,
  capitalize,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FormatNumberForKm } from "../../components/FormatNumber/FormatNumber";
import { updateAppointmentStatus } from "../../redux/appointment/action";
import { eitherDisplayOrRemove } from "../../utility/helpers/displayHelper.js";
import AddAssigneeModal from "./TodayAppointment/AddAssigneeModal.jsx";
import GuestCustomerInfoModal from "../Customers/GuestCustomers/GuestCustomerInfoModal.jsx";
import SaveCustomerConfirmModal from "../Customers/GuestCustomers/SaveCustomerConfirmModal.jsx";
import { USER_CREATED_FROM } from "../../utility/Constant.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
};
const styleTag = {
  borderRadius: "48px",
  color: "black",
  padding: "10px 20px",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  margin: "10px",
  width: "200px",
};
const serviceStyle = {
  fontSize: "13px",
  fontWeight: 500,
  // borderStyle: "solid",
  // borderWidth: "1px",
  // borderColor: "black",
  padding: "5px 10px",
  borderRadius: "20px",
  // color: "white",
};
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const AppointmentModel = ({
  open,
  handleClose,
  data,
  fetchAppointmentDetails,
  status,
  show,
  handleStatusClose,
  handleStatus,
  recallDetailsAPI,
  setAppointmentDropOffODo,
  setAppointmentCompletionOffODo,
  columns,
  setUpdateStatus,
  setSelectId,
  fetchCalendarDataonAppointmentStatusChange,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  // const [updateStatus, setUpdateStatus] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showLoader, setShowLoader] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [showAssignee, setShowAssignee] = useState(false);
  const [isAssignChanged, setIsAssigneeChanged] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const [openGuestModal, setOpenGuestModal] = useState(false);
  const [saveConfirmModal, setSaveConfirmModal] = useState(false);

  const [actionState, setActionState] = useState({
    isActionSaved: false, // Indicates if an action is saved
    messageToSend: null, // Holds a string message to send
    requestApproval: null, // Holds a function to handle rescheduling
    authRequest: null, // Holds a string for authentication requests
  });
  console.log("🚀 ~ actionState:", actionState);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const open1 = Boolean(anchorEl);
  const handlePreviewPdf = () => {
    const pdfUrl = data?.estimate?.pdfUrl;
    window.open(pdfUrl, "_blank");
  };
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
    if (setSelectId) setSelectId(data?._id);
  };
  const filteredData = data?.services?.filter((item) => item.newAdded);

  filteredData?.map((item) => {
    if (item?.pricingType == "labor_&_parts") {
      item.sumTotalAmount = item?.parts?.reduce((accumulator, object) => {
        return accumulator + parseFloat(object?.totalAmount);
      }, 0);
    }
  });

  const path = window.location.pathname.split("/");
  const UpdateStatus = async (id, status) => {
    setShowLoader(true);
    let payload = {
      status: status,
    };
    const statusUpdate = await dispatch(updateAppointmentStatus(id, payload));
    if (statusUpdate) {
      handleClose();
      // navigate("/appointment/today");
    }
    setShowLoader(false);
    if (path.includes("calendar")) {
      fetchCalendarDataonAppointmentStatusChange();
    }
    if (path.includes("workOrderAuthorization")) {
      recallDetailsAPI();
    }
  };
  const handleCloseAssigneeModal = () => {
    setShowAssignee(false);
  };
  useEffect(() => {
    if (isAssignChanged) {
      if (recallDetailsAPI) {
        recallDetailsAPI();
        if (path.includes("calendar")) {
          fetchCalendarDataonAppointmentStatusChange();
        }
      }
      setIsAssigneeChanged(false);
    }
  }, [isAssignChanged]);
  useEffect(() => {
    if (!open) setValue(0);
  }, [open]);
  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{ ...style, width: "896px", border: "none" }}
          className="appointmentModalBodyBoxContainer"
        >
          <AppBar
            variant="outlined"
            className="modalHeader"
            sx={{
              height: "64px",
              paddingRight: "0px !important",
            }}
          >
            <Toolbar variant="regular">
              <Typography
                variant="h6"
                gutterBottom={false}
                color={"black"}
                width={"98%"}
                fontSize={"20px"}
              >
                Appointment Details
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={handleClose}
                  className="appointmentModal"
                  style={{ cursor: "pointer", height: "32px", color: "black" }}
                >
                  <CloseIcon
                    style={{ fontSize: 32, fill: "rgb(0 0 0 / 56%)" }}
                  />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Grid
            container
            marginTop={"64px"}
            fontSize={"15px"}
            minHeight={"608px"}
            fontWeight={500}
            className="modalBody_container"
          >
            <Grid
              item
              md={4.13}
              sm={12}
              xs={12}
              padding={"24px"}
              style={{ background: "#F5F5F7" }}
              borderRight={"1px solid rgba(0, 0, 0, 0.23)"}
              className="appointmentModal_leftContainer"
              gap={2}
            >
              <Stack gap={"16px"}>
                <div>
                  <Button
                    variant="contained"
                    fullWidth
                    endIcon={<KeyboardArrowDownIcon />}
                    style={{
                      backgroundColor:
                        status == "waiting_for_drop_off"
                          ? "#75DBFF"
                          : status == "vehicle_dropped_off"
                          ? "#FFC30F"
                          : status == "in_progress"
                          ? "#FF9E31"
                          : status == "ready_for_pick_up"
                          ? "#5CE469"
                          : status == "customer_paid"
                          ? "#00CD44"
                          : "#F44336",
                      //   : "#5CE469",
                      borderRadius: "48px",
                      color: "black",
                      padding: "6px 16px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    aria-controls={open1 ? "demo-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open1 ? "true" : undefined}
                    onClick={handleClick1}
                  >
                    {status == "waiting_for_drop_off" && "Waiting for drop off"}
                    {status == "vehicle_dropped_off" &&
                      "VEHICLE DROPPED OFF"}{" "}
                    {status == "in_progress" && "IN PROGRESS"}{" "}
                    {status == "customer_paid" && "CUSTOMER PAID"}{" "}
                    {status == "mark_as_no_show" && "MARK AS NO SHOW"}
                    {status == "ready_for_pick_up" && "READY FOR PICKUP"}
                  </Button>

                  <Menu
                    style={{ width: "400px" }}
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open1}
                    onClose={handleClose1}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem>Update Status</MenuItem>
                    <MenuItem
                      onClick={() => {
                        setUpdateStatus("waiting_for_drop_off");
                        handleStatus();
                        handleClose1();
                      }}
                      disabled={true}
                      style={{ ...styleTag, backgroundColor: "#75DBFF" }}
                    >
                      Waiting for Drop Off
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        handleClose1();

                        setUpdateStatus("vehicle_dropped_off");
                        if (status == "waiting_for_drop_off") {
                          handleStatus();
                        } else {
                          UpdateStatus(data?._id, "vehicle_dropped_off");
                        }
                      }}
                      disabled={
                        status == "waiting_for_drop_off" ||
                        status == "in_progress"
                          ? false
                          : true
                      }
                      style={{
                        ...styleTag,
                        background: "#FFC30F",
                      }}
                    >
                      Vehicle Dropped Off
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose1();

                        setUpdateStatus("in_progress");
                        if (status == "waiting_for_drop_off") {
                          handleStatus();
                        } else {
                          UpdateStatus(data?._id, "in_progress");
                        }
                      }}
                      disabled={
                        status == "waiting_for_drop_off" ||
                        status == "vehicle_dropped_off" ||
                        status == "ready_for_pick_up"
                          ? false
                          : true
                      }
                      style={{
                        ...styleTag,
                        background: "#FF9E31",
                      }}
                    >
                      In Progress
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose1();

                        setUpdateStatus("ready_for_pick_up");
                        setAppointmentDropOffODo(null);
                        setAppointmentCompletionOffODo(null);

                        if (window.location.pathname.includes("calendar")) {
                          if (data?.dropOffODO)
                            setAppointmentDropOffODo(data?.dropOffODO);

                          if (data?.completionODO)
                            setAppointmentCompletionOffODo(data?.completionODO);
                        } else {
                          let found = columns?.[status]?.items?.filter(
                            (d) => d._id == data?._id
                          );

                          if (Array.isArray(found) && found?.[0]?.dropOffODO) {
                            setAppointmentDropOffODo(found?.[0]?.dropOffODO);
                          }
                          if (
                            Array.isArray(found) &&
                            found?.[0]?.completionODO
                          ) {
                            setAppointmentCompletionOffODo(
                              found?.[0]?.completionODO
                            );
                          }
                        }
                        if (
                          status == "waiting_for_drop_off" ||
                          status == "vehicle_dropped_off" ||
                          status == "in_progress"
                        ) {
                          handleStatus();
                        } else {
                          UpdateStatus(data?._id, "ready_for_pick_up");
                        }
                      }}
                      disabled={
                        status == "waiting_for_drop_off" ||
                        status == "vehicle_dropped_off" ||
                        status == "in_progress"
                          ? false
                          : true
                      }
                      style={{
                        ...styleTag,
                        background: "#5CE469",
                      }}
                    >
                      Ready for Pick Up
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose1();

                        setUpdateStatus("customer_paid");
                        setAppointmentDropOffODo(null);
                        setAppointmentCompletionOffODo(null);
                        if (window.location.pathname.includes("calendar")) {
                          if (data?.dropOffODO)
                            setAppointmentDropOffODo(data?.dropOffODO);

                          if (data?.completionODO)
                            setAppointmentCompletionOffODo(data?.completionODO);
                        } else {
                          let found = columns?.[status]?.items?.filter(
                            (d) => d._id == data?._id
                          );

                          if (Array.isArray(found) && found?.[0]?.dropOffODO) {
                            setAppointmentDropOffODo(found?.[0]?.dropOffODO);
                          }
                          if (
                            Array.isArray(found) &&
                            found?.[0]?.completionODO
                          ) {
                            setAppointmentCompletionOffODo(
                              found?.[0]?.completionODO
                            );
                          }
                        }
                        if (
                          status == "waiting_for_drop_off" ||
                          status == "vehicle_dropped_off" ||
                          status == "in_progress"
                        ) {
                          handleStatus();
                        } else {
                          UpdateStatus(data?._id, "customer_paid");
                        }
                      }}
                      disabled={
                        status == "waiting_for_drop_off" ||
                        status == "vehicle_dropped_off" ||
                        status == "in_progress" ||
                        status == "ready_for_pick_up"
                          ? false
                          : true
                      }
                      style={{
                        ...styleTag,
                        background: "#00CD44",
                      }}
                    >
                      Customer Paid
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose1();
                        setUpdateStatus("mark_as_no_show");
                        UpdateStatus(data?._id, "mark_as_no_show");
                      }}
                      disabled={status == "waiting_for_drop_off" ? false : true}
                      style={{
                        ...styleTag,
                        background: "#F44336",
                      }}
                    >
                      Mark as No Show
                    </MenuItem>
                  </Menu>
                </div>
                {/* <AddOdoModal open={show} handleClose={handleStatusClose} status={updateStatus} id={data?._id} handleClose1={handleClose1} /> */}

                <Button
                  variant="contained"
                  size="large"
                  // fullWidth
                  sx={{
                    height: "42px",
                    lineHeight: "26px",
                    letterSpacing: "0.46px",
                    fontSize: "15px",
                    width: "100%",
                  }}
                  // onClick={() => {
                  //   navigate(`/appointment/newApproval/${data?._id}`);
                  // }}

                  onClick={() => {
                    if (
                      data?.customer?.createdFrom ===
                      USER_CREATED_FROM.SHOP_GUEST
                    ) {
                      setSaveConfirmModal(true);
                      setActionState({
                        isActionSaved: true,
                        messageToSend: null,
                        requestApproval: `/appointment/newApproval/${data?._id}`,
                        authRequest: null,
                      });
                    } else {
                      navigate(`/appointment/newApproval/${data?._id}`);
                    }
                  }}
                >
                  REQUEST APPROVAL
                </Button>
                {data?.hasEstimate && (
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{
                      height: "42px",
                      lineHeight: "26px",
                      letterSpacing: "0.46px",
                      fontSize: "15px",
                    }}
                    onClick={handlePreviewPdf}
                  >
                    VIEW ESTIMATE
                  </Button>
                )}
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  // onClick={() => navigate(`/messenger/${data?.customer?._id}`)}

                  onClick={() => {
                    if (
                      data?.customer?.createdFrom ===
                      USER_CREATED_FROM.SHOP_GUEST
                    ) {
                      setSaveConfirmModal(true);
                      setActionState({
                        isActionSaved: true,
                        messageToSend: `/messenger/${data?.customer?._id}`,
                        requestApproval: null,
                        authRequest: null,
                      });
                    } else {
                      navigate(`/messenger/${data?.customer?._id}`);
                    }
                  }}
                >
                  SEND MESSAGE
                </Button>
              </Stack>
              {!location.pathname
                .split("/")
                .includes("workOrderAuthorization") && (
                <Badge
                  invisible={
                    data?.workOrderAuthorization?.showUnreadBadge == true
                      ? false
                      : true
                  }
                  badgeContent={""}
                  color="error"
                >
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{
                      height: "42px",
                      lineHeight: "26px",
                      letterSpacing: "0.46px",
                      fontSize: "15px",
                    }}
                    // onClick={() =>
                    // navigate(
                    //   `/appointment/workOrderAuthorization/${data?._id}`
                    // )
                    // }
                    onClick={() => {
                      if (
                        data?.customer?.createdFrom ===
                        USER_CREATED_FROM.SHOP_GUEST
                      ) {
                        setSaveConfirmModal(true);
                        setActionState({
                          isActionSaved: true,
                          messageToSend: null,
                          requestApproval: null,
                          authRequest: `/appointment/workOrderAuthorization/${data?._id}`,
                        });
                      } else {
                        navigate(
                          `/appointment/workOrderAuthorization/${data?._id}`
                        );
                      }
                    }}
                  >
                    {data?.workOrderAuthorization?._id
                      ? "VIEW AUTHORIZATION"
                      : "REQUEST AUTHORIZATION"}
                  </Button>
                </Badge>
              )}
            </Grid>
            {/* <Divider orientation="vertical" flexItem /> */}
            <Grid
              item
              md={7.8}
              sm={12}
              xs={12}
              padding={"24px"}
              className="appointmentModal_rightContainer"
            >
              <Box sx={{ width: "100%", border: "1px" }}>
                <Box marginBottom={"24px"}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      style={{ fontSize: "14px" }}
                      label="OVERVIEW"
                      {...a11yProps(0)}
                    />

                    <Tab
                      style={{ fontSize: "14px" }}
                      label="VEHICLE"
                      {...a11yProps(1)}
                    />
                    <Tab
                      style={{ fontSize: "14px" }}
                      label="SERVICES"
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <Grid container item spacing={2}>
                    <Grid container item>
                      <Grid
                        item
                        md={4}
                        sm={4}
                        xs={4}
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Customer
                      </Grid>

                      {data?.customer?.createdFrom ===
                      USER_CREATED_FROM.SHOP_GUEST ? (
                        <span
                          onClick={() => setOpenGuestModal(true)}
                          style={{
                            color: "#2196F3",
                            textDecoration: "none",
                            cursor: "pointer",
                          }}
                        >
                          {data?.customer?.firstName} {data?.customer?.lastName}
                        </span>
                      ) : (
                        <Link
                          to={`/customers/${data?.customer?._id}/${data?.customer?.createdFrom}`}
                          style={{ color: "#2196F3", textDecoration: "none" }}
                        >
                          {data?.customer?.firstName} {data?.customer?.lastName}
                        </Link>
                      )}
                    </Grid>
                    <Grid container item>
                      <Grid
                        item
                        xs={4}
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Vehicle
                      </Grid>
                      <Grid item md={8} fontSize={"16px"} color={"#000000"}>
                        {eitherDisplayOrRemove(data?.vehicle?.year) +
                          " " +
                          eitherDisplayOrRemove(
                            data?.vehicle?.vehicleMake?.name
                          ) +
                          " " +
                          eitherDisplayOrRemove(
                            data?.vehicle?.vehicleModel?.name
                          )}{" "}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid
                        item
                        xs={4}
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Drop Off
                      </Grid>
                      <Grid item md={8} fontSize={"16px"} color={"#000000"}>
                        {data?.dropOffDateTime
                          ? moment(data?.dropOffDateTime).format(
                              "MMM D, YYYY hh:mm A"
                            )
                          : " - "}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid
                        item
                        xs={4}
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Estimated Pick Up
                      </Grid>
                      <Grid item md={8} color={"#2196F3"} fontSize={"16px"}>
                        {data?.pickupDateTime
                          ? moment(data?.pickupDateTime).format(
                              "MMM D, YYYY hh:mm A"
                            )
                          : moment(data?.shopClosingTime).format(
                              "MMM D, YYYY hh:mm A"
                            )}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid
                        item
                        xs={4}
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Customer Wants To
                      </Grid>
                      <Grid item md={8} fontSize={"16px"} color={"#000000"}>
                        {data?.waitingOnSite &&
                        data?.waitingOnSite == "dropping_off_car"
                          ? "Dropping Off Car"
                          : " Wait on Site "}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid
                        item
                        xs={4}
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Contact Preferences
                      </Grid>
                      <Grid item md={8} fontSize={"16px"} color={"#000000"}>
                        {/* {data?.contactPreferences ? data?.contactPreferences : " - "} */}
                        {data?.contactPreferences &&
                        data?.contactPreferences == "phone"
                          ? "Phone Call"
                          : data?.contactPreferences == "email"
                          ? "Email"
                          : "In-App Message"}
                      </Grid>
                    </Grid>
                    {/* {console.log("data", data)} */}
                    <Grid container item>
                      <Grid
                        item
                        xs={4}
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Note from Customer
                      </Grid>
                      {data?.noteForMechanic ? (
                        <Grid
                          item
                          md={8}
                          border={"1px solid gray"}
                          padding={"10px 10px"}
                          borderRadius={"8px"}
                          fontSize={"16px"}
                          color={"#000000"}
                        >
                          {data?.noteForMechanic}
                        </Grid>
                      ) : (
                        " - "
                      )}
                    </Grid>
                    <Grid container item>
                      <Grid
                        item
                        xs={4}
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Internal Shop Note
                      </Grid>
                      {data?.noteForCustomer ? (
                        <Grid
                          item
                          md={8}
                          border={"1px solid gray"}
                          padding={"10px 10px"}
                          borderRadius={"8px"}
                          fontSize={"16px"}
                          color={"#000000"}
                        >
                          {data?.noteForCustomer}
                        </Grid>
                      ) : (
                        " - "
                      )}
                    </Grid>
                    <Grid container item>
                      <Grid
                        item
                        xs={4}
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Assigned To
                      </Grid>
                      {data?.assignee && Array.isArray(data?.assignee) ? (
                        <Grid
                          item
                          md={8}
                          color={"#2196F3"}
                          fontSize={"16px"}
                          sx={{ cursor: "pointer" }}
                          onClick={() => setShowAssignee(true)}
                          // border={"1px solid gray"}
                          // padding={"10px 10px"}
                          // borderRadius={"8px"}
                          // fontSize={"16px"}
                          // color={'#000000de'}
                        >
                          {data?.assignee[0]?.name}
                        </Grid>
                      ) : (
                        " - "
                      )}
                      {/* Assigned */}
                    </Grid>
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Grid container item spacing={2}>
                    <Grid container item>
                      <Grid
                        item
                        xs={4}
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Year
                      </Grid>
                      <Grid item md={7} fontSize={"16px"} color={"#000000"}>
                        {data?.vehicle?.year ? data?.vehicle?.year : " - "}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid
                        item
                        xs={4}
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Make
                      </Grid>
                      <Grid item md={7} fontSize={"16px"} color={"#000000"}>
                        {data?.vehicle?.vehicleMake?.name
                          ? data?.vehicle?.vehicleMake?.name
                          : " - "}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid
                        item
                        xs={4}
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Model
                      </Grid>
                      <Grid item md={7} fontSize={"16px"} color={"#000000"}>
                        {data?.vehicle?.vehicleModel?.name
                          ? data?.vehicle?.vehicleModel?.name
                          : " - "}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid
                        item
                        xs={4}
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Trim
                      </Grid>
                      <Grid item md={7} fontSize={"16px"} color={"#000000"}>
                        {data?.vehicle?.vehicleModelTrim?.name
                          ? data?.vehicle?.vehicleModelTrim?.name
                          : " - "}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid
                        item
                        md={4}
                        sm={4}
                        xs={4}
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Licence Plate Number:
                      </Grid>
                      <Grid item md={7} fontSize={"16px"} color={"#000000"}>
                        {data?.vehicle?.licenseNumber
                          ? data?.vehicle?.licenseNumber
                          : " - "}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid
                        item
                        xs={4}
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        VIN
                      </Grid>
                      <Grid item md={7} fontSize={"16px"} color={"#000000"}>
                        {data?.vehicle?.vin ? data?.vehicle?.vin : " - "}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid
                        item
                        xs={4}
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Odometer
                      </Grid>
                      <Grid item md={7} fontSize={"16px"} color={"#000000"}>
                        {data?.vehicle?.odo && data?.vehicle?.odo !== "" ? (
                          <>
                            {FormatNumberForKm(data?.vehicle?.odo)} {"km"}
                            <br></br>
                            {"Last Updated:"}
                            {moment(data?.vehicle?.odoUpdatedAt).format(
                              "MMM D,YYYY"
                            )}
                          </>
                        ) : (
                          "-"
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      margin: "24px 0",
                      textAlign: "end",
                      textDecoration: "underline",
                      textUnderlineOffset: "2px",
                      textTransform: "capitalize",
                      color: "#006CDD",
                    }}
                  >
                    <p
                      onClick={() => setShowMore(!showMore)}
                      style={{
                        cursor: "pointer",
                        margin: "0",
                        letterSpacing: "0.15px",
                        lineHeight: "175%",
                        fontWeight: 500,
                      }}
                    >
                      {showMore ? " Show Less" : "Show More"}
                    </p>
                  </div>
                  {showMore && (
                    <div>
                      <div className="grid-detail-container">
                        <div className="vehicle-left-side-grid">
                          <Typography
                            fontSize={"14px"}
                            color={"#00000099"}
                            lineHeight={"143%"}
                            letterSpacing={"0.17px"}
                            alignItems={"flex-start"}
                          >
                            Trim Description
                          </Typography>
                        </div>
                        <div className="right-side-grid-detail">
                          <Typography fontSize={"16px"} lineHeight={"24px"}>
                            {data?.vehicle?.vehicleModelTrim
                              ? data?.vehicle?.vehicleModelTrim?.description
                              : " - "}
                          </Typography>
                        </div>
                      </div>
                      <div className="grid-detail-container">
                        <div className="vehicle-left-side-grid">
                          <Typography
                            fontSize={"14px"}
                            color={"#00000099"}
                            lineHeight={"143%"}
                            letterSpacing={"0.17px"}
                          >
                            Engine Type
                          </Typography>
                        </div>
                        <div className="right-side-grid-detail">
                          <Typography fontSize={"16px"} lineHeight={"24px"}>
                            {data?.vehicle?.vehicleModelTrim
                              ? data?.vehicle?.vehicleModelTrim?.engineType
                              : " - "}
                          </Typography>
                        </div>
                      </div>

                      <div className="grid-detail-container">
                        <div className="vehicle-left-side-grid">
                          <Typography
                            fontSize={"14px"}
                            color={"#00000099"}
                            lineHeight={"143%"}
                            letterSpacing={"0.17px"}
                          >
                            Engine Fuel Type
                          </Typography>
                        </div>
                        <div className="right-side-grid-detail">
                          <Typography fontSize={"16px"} lineHeight={"24px"}>
                            {data?.vehicle?.vehicleModelTrim
                              ? data?.vehicle?.vehicleModelTrim?.engineFuelType
                              : " - "}
                          </Typography>
                        </div>
                      </div>

                      <div className="grid-detail-container">
                        <div className="vehicle-left-side-grid">
                          <Typography
                            fontSize={"14px"}
                            color={"#00000099"}
                            lineHeight={"143%"}
                            letterSpacing={"0.17px"}
                          >
                            Engine Drive Type
                          </Typography>
                        </div>
                        <div className="right-side-grid-detail">
                          <Typography fontSize={"16px"} lineHeight={"24px"}>
                            {data?.vehicle?.vehicleModelTrim
                              ? data?.vehicle?.vehicleModelTrim?.engineDriveType
                              : " - "}
                          </Typography>
                        </div>
                      </div>

                      <div className="grid-detail-container">
                        <div className="vehicle-left-side-grid">
                          <Typography
                            fontSize={"14px"}
                            color={"#00000099"}
                            lineHeight={"143%"}
                            letterSpacing={"0.17px"}
                          >
                            Engine Transmission
                          </Typography>
                        </div>
                        <div className="right-side-grid-detail">
                          <Typography fontSize={"16px"} lineHeight={"24px"}>
                            {data?.vehicle?.vehicleModelTrim
                              ? data?.vehicle?.vehicleModelTrim
                                  ?.engineTransmission
                              : " - "}
                          </Typography>
                        </div>
                      </div>

                      <div className="grid-detail-container">
                        <div className="vehicle-left-side-grid">
                          <Typography
                            fontSize={"14px"}
                            color={"#00000099"}
                            lineHeight={"143%"}
                            letterSpacing={"0.17px"}
                          >
                            Body Type
                          </Typography>
                        </div>
                        <div className="right-side-grid-detail">
                          <Typography fontSize={"16px"} lineHeight={"24px"}>
                            {data?.vehicle?.vehicleModelTrim
                              ? data?.vehicle?.vehicleModelTrim?.bodyType
                              : " - "}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )}
                </CustomTabPanel>
                <CustomTabPanel
                  value={value}
                  index={2}
                  style={{
                    // maxHeight: "485px",
                    overflow: "auto",
                    paddingBottom: "16px",
                  }}
                >
                  <Grid container item rowGap={3}>
                    <Grid
                      container
                      // justifyContent={"space-between"}
                      item
                      rowGap={3}
                      // boxShadow={2}
                      // padding={"16px"}
                      style={{ backgroundColor: "white" }}
                    >
                      <Grid item xs={6} sm={4} md={4}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#00000099",
                          }}
                          variant="title"
                        >
                          {" "}
                          Total Services
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{ fontSize: "16px", fontWeight: 400 }}
                          variant="title"
                        >
                          {data?.services?.length}
                        </Typography>
                      </Grid>
                    </Grid>

                    {filteredData?.length > 0 && (
                      <>
                        <Grid
                          container
                          // justifyContent={"space-between"}
                          item
                          rowGap={3}
                          // boxShadow={2}
                          // padding={"16px"}
                          style={{ backgroundColor: "white" }}
                        >
                          <Grid item xs={6} sm={4} md={4}>
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: 400,
                                color: "#00000099",
                              }}
                              variant="title"
                            >
                              {" "}
                              Services Added
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              style={{ fontSize: "16px", fontWeight: 400 }}
                              variant="title"
                            >
                              {filteredData?.length}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          md={12}
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                        >
                          <Grid item md={6}>
                            <Typography
                              style={{ fontSize: "16px", fontWeight: 500 }}
                              variant="title"
                            >
                              {" "}
                              Services Added
                            </Typography>
                          </Grid>

                          <Grid item md={6}>
                            <Typography
                              style={{
                                fontSize: "13px",
                                fontWeight: 500,
                                // marginLeft: "70px",
                                float: "inline-end",
                                lineHeight: "23px",
                              }}
                              variant="title"
                            >
                              <Link
                                to={`/approval/${data?._id}`}
                                style={{
                                  color: "#006CDD",
                                  textDecoration: "none",
                                }}
                              >
                                VIEW APPROVAL DETAILS
                              </Link>
                            </Typography>
                          </Grid>
                        </Grid>

                        {filteredData?.map((row, index) => {
                          return (
                            <Grid
                              container
                              justifyContent={"space-between"}
                              item
                              rowGap={3}
                              // boxShadow={2}
                              border={"1px solid rgba(0, 0, 0, 0.23)"}
                              padding={"16px"}
                              key={index}
                              borderRadius={1}
                              color={"black"}
                            >
                              <Grid container justifyContent={"space-between"}>
                                <Grid item md={6}>
                                  <Chip
                                    label={
                                      capitalize(row?.approvalStatus) ==
                                      "Approved"
                                        ? "Accepted"
                                        : capitalize(row?.approvalStatus)
                                    }
                                    style={{
                                      backgroundColor:
                                        row?.approvalStatus == "waiting"
                                          ? "#FFD60A"
                                          : row?.approvalStatus == "approved"
                                          ? "#30D158"
                                          : row?.approvalStatus == "declined"
                                          ? "#FF2727"
                                          : "#000000",
                                      color:
                                        row?.approvalStatus == "waiting"
                                          ? "#000000"
                                          : "white",
                                      fontWeight: "500",
                                      textTransform: "capitalize",
                                    }}
                                  />
                                </Grid>

                                {row?.pricingType == "fixed" ? (
                                  <Grid item>
                                    <Typography
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 400,
                                      }}
                                      variant="body1"
                                    >
                                      {row?.fixedPrice
                                        ? "$" +
                                          FormatNumberForKm(row?.fixedPrice)
                                        : ""}
                                    </Typography>
                                  </Grid>
                                ) : (
                                  <Grid item>
                                    <Typography
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 400,
                                      }}
                                      variant="body1"
                                    >
                                      $
                                      {parseInt(row?.labor?.totalAmount) +
                                        parseInt(row?.sumTotalAmount)}
                                    </Typography>
                                  </Grid>
                                )}

                                <Grid item md={12}>
                                  <Typography
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 500,
                                      marginTop: "5px",
                                    }}
                                    variant="body1"
                                  >
                                    {" "}
                                    {row?.serviceName}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </>
                    )}

                    {data?.service?.length > 0 && (
                      <Grid item md={6}>
                        <Typography
                          style={{ fontSize: "16px", fontWeight: 500 }}
                          variant="title"
                        >
                          {" "}
                          Scheduled Services
                        </Typography>
                      </Grid>
                    )}
                    {data?.services?.map((item, index) => {
                      if (!item?.newAdded)
                        return (
                          <Grid
                            container
                            item
                            justifyContent={"space-between"}
                            rowGap={0}
                            border={"1px solid rgba(0, 0, 0, 0.23)"}
                            // boxShadow={2}
                            padding={"16px"}
                            key={index}
                            borderRadius={1}
                            color={"black"}
                          >
                            <Grid container justifyContent={"space-between"}>
                              <Grid item md={6}>
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                  }}
                                  variant="body1"
                                >
                                  {" "}
                                  {item?.serviceName}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 400,
                                  }}
                                  variant="body1"
                                >
                                  {item?.pricingType === "no_price"
                                    ? "No Price"
                                    : item?.pricingType === "fixed"
                                    ? "$" + FormatNumberForKm(item?.fixedPrice)
                                    : item?.pricingType === "range"
                                    ? "$" +
                                      FormatNumberForKm(item?.priceMin) +
                                      " - " +
                                      "$" +
                                      FormatNumberForKm(item?.priceMax)
                                    : "-"}
                                </Typography>
                              </Grid>
                              {Object?.keys(item?.questionAnswer).length >
                                0 && (
                                <>
                                  <Divider style={{ width: "100%" }} />
                                  <Grid item md={12}>
                                    <Typography
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        marginTop: "5px",
                                      }}
                                      variant="body1"
                                    >
                                      {" "}
                                      {item?.questionAnswer?.question}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={12}>
                                    <Typography
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        marginTop: "5px",
                                        marginBottom: "5px",
                                      }}
                                      variant="body1"
                                    >
                                      {" "}
                                      {item?.questionAnswer?.answer}
                                    </Typography>
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        );
                    })}
                  </Grid>
                </CustomTabPanel>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* {(status == "waiting_for_drop_off" || status == "in_progress") && (
        <AddOdoModal
          open={show}
          handleClose={handleStatusClose}
          updateStatus={updateStatus}
          id={data?._id}
          handleClose1={handleClose1}
          currentStatus={status}
          handleClose2={handleClose}
          appointmentView={() => {}}
        />
      )} */}
      <AddAssigneeModal
        open={showAssignee}
        handleClose={handleCloseAssigneeModal}
        data={data}
        setIsAssigneeChanged={setIsAssigneeChanged}
      />
      <GuestCustomerInfoModal
        userData={data}
        openGuestModal={openGuestModal}
        setOpenGuestModal={setOpenGuestModal}
        showBtn
        showTag
        fetchAppointmentDetails={fetchAppointmentDetails}
      />
      <SaveCustomerConfirmModal
        open={saveConfirmModal}
        setActionState={setActionState}
        setSaveConfirmModal={setSaveConfirmModal}
        userData={data}
        userName={data?.customer?.firstName + " " + data?.customer?.lastName}
        handleClose={() => {
          setSaveConfirmModal(false);
          if (actionState.isActionSaved) {
            if (actionState.authRequest) {
              navigate(actionState.authRequest);
            }
            if (actionState.messageToSend) {
              navigate(actionState.messageToSend);
            }
            if (actionState.requestApproval) {
              navigate(actionState.requestApproval);
            }
          }
          fetchAppointmentDetails();
        }}
      />
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AppointmentModel;
