import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select as MuiSelect,
  Radio,
  RadioGroup,
  Skeleton,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { REQUIRED_RED } from "../../../App";
import PostalCode from "../../../components/Address/PostalCode";
import { formatPostalCode } from "../../../components/FormatNumber/FormatNumber";
import { discountProgramApi } from "../../../redux/Account/Pricing/action";
import {
  customerCheckPhoneNumberExist,
  customerDetail,
  customerVehicles,
  editCustomerVehicle,
  getCityList,
  getProvinceList,
  updatePhoneNumberExistCustomer,
  vehicleMakeMaster,
  vehicleModelMaster,
  vehicleTrimMaster,
  vehicleYearMaster,
} from "../../../redux/Customer/action";
import { CONTACT_PREFRENCES, PHONE_TYPES } from "../../../utility/Constant";
import { handlePreventKeyDown } from "../../../utility/helpers/commonFunction";
import {
  customStyles,
  errorCustomStyles,
  phoneTypeCustomStyles,
} from "../../../utility/SelectDropdown";
import { inputFormatPhoneNumber } from "../../Common/FormatPhoneNumber";
import "../Customers.css";

import { useTheme } from "@emotion/react";
import { toast } from "sonner";
import { configDetails } from "../../../redux/Account/action";
import AddressSelect from "../Profile/AddressSelect";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "2px solid ",
  boxShadow: 24,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function ConvertCustomerModal({
  showBtn,
  userId,
  openConvertModal,
  handleCloseCustomer,
  addressValues,
  setAddressValue,
  primaryEmailIndex,
  setPrimaryEmailIndex,
  setOpenDelete,
  type,
}) {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue: setValueinForm,
    getValues,
    setFocus,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      primaryPhoneNumberType: {
        key: "mobile",
        value: "Mobile",
      },
      secondaryPhoneNumberType: {
        key: "mobile",
        value: "Mobile",
      },
    },
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [editModalTabvalue, setEditModalTabValue] = useState(0);
  const [phoneTypes, setPhoneTypes] = useState(PHONE_TYPES);
  const [contactPrefrences, setContactPrefrences] =
    useState(CONTACT_PREFRENCES);
  const [showSecondaryNumber, setShowSecondaryNumber] = useState(false);
  const [selectedPhoneTypes, setSelectedPhoneTypes] = useState({
    primary: "mobile",
    secondary: "mobile",
  });
  const [getAddress, setAddress] = useState();
  const [country, setCountry] = useState();
  const [showSecondaryEmail, setShowSecondaryEmail] = useState(false);

  const handleChangeEditCustomerTabValue = (event, newValue) => {
    setEditModalTabValue(newValue);
  };
  const [cityList, setCityList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [discountList, setDiscountList] = useState([]);
  const [cityListLoader, setCityListLoader] = useState(false);
  const [provinceListLoader, setProvinceListLoader] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [discountProgramLoader, setDiscountProgramLoader] = useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");

  const [showLoader, setShowLoader] = useState(false);
  const [yearPage, setYearPage] = useState(1);
  const [makeValuePage, setMakeValuePage] = useState(1);
  const [modelValuePage, setModelValuePage] = useState(1);
  const [trimValuePage, setTrimValuePage] = useState(1);
  const [yearValue, setYearValue] = useState("");
  const [makeValue, setMakeValue] = useState("");
  const [modelValue, setModelValue] = useState("");
  const [trimValue, setTrimValue] = useState("");
  const [trimEngineValue, setTrimEngineValue] = useState("");
  const [make, setMake] = useState([]);
  const [model, setModel] = useState([]);
  const [trim, setTrim] = useState([]);
  const [makeLoader, setMakeLoader] = useState(false);
  const [modelLoader, setModelLoader] = useState(false);
  const [trimLoader, setTrimLoader] = useState(false);
  const [vehicles, setVehicles] = useState([]);

  const [provinceValue, setProvinceValue] = useState("");
  const [selectedProvinceObject, setSelectedProvinceObject] = useState("");
  const [recordFoundByPhone, setRecordFoundByPhone] = useState({
    id: "",
    found: false,
  });

  useEffect(() => {
    fetchCityList();
    fetchProvinceList();
    fetchDiscountProgram();
    fetchVehicleYear();
    fetchConfig();
    setValueinForm(
      "primaryPhoneNumberType",
      selectedPhoneTypes
        ? phoneTypes.find((option) => option.key == selectedPhoneTypes?.primary)
        : ""
    );
  }, []);
  useEffect(() => {
    if (userId?.customer?._id && showBtn) {
      fetchCustomerDetail(userId?.customer?._id);
      fetchCustomerVehicle(userId?.customer?._id);
    }
  }, [userId]);
  const fetchCustomerVehicle = async (id) => {
    setShowLoader(true);

    let resp = await dispatch(customerVehicles(id));
    if (resp.length > 0) {
      setVehicles(resp);
    }
    setShowLoader(false);
  };

  const fetchCustomerDetail = async (id) => {
    await dispatch(customerDetail(id));
  };
  const fetchConfig = async () => {
    await dispatch(configDetails());
  };
  const config = useSelector((state) => state.account.configDetailData);
  const fetchDiscountProgram = async () => {
    setDiscountProgramLoader(true);
    let resp = await dispatch(discountProgramApi({ pagination: false }));
    if (resp.length > 0) {
      setDiscountList(resp);
    }
    setDiscountProgramLoader(false);
  };

  const fetchCityList = async (province, country) => {
    setCityListLoader(true);
    let resp = await dispatch(
      getCityList({ country: "64e738882fec8f2c9b79c139", province: province })
    );
    if (resp) {
      setCityList(resp);
    }
    setCityListLoader(false);
  };

  const fetchProvinceList = async (country) => {
    setProvinceListLoader(true);
    let resp = await dispatch(
      getProvinceList({ country: "64e738882fec8f2c9b79c139" })
    );
    if (resp) {
      setProvinceList(resp);
    }
    setProvinceListLoader(false);
  };

  const data = useSelector((state) => state.customer.customerDetail);

  // useEffect(()=>{},[vehc])
  useEffect(() => {
    reset();
    setShowSecondaryNumber(false);
    setShowSecondaryEmail(false);

    if (data.primaryPhoneNumberType) {
      setValueinForm(
        "primaryPhoneNumberType",
        data.primaryPhoneNumberType
          ? phoneTypes.find(
              (option) => option.key == data.primaryPhoneNumberType
            )
          : ""
      );
    } else {
      setValueinForm(
        "primaryPhoneNumberType",
        selectedPhoneTypes
          ? phoneTypes.find(
              (option) => option.key == selectedPhoneTypes?.primary
            )
          : ""
      );
    }
    if (data?.membershipProgram) {
      setValueinForm(
        "membershipProgram",
        data.membershipProgram?._id ?? "none"
      );
    }
    setValueinForm("firstName", data?.firstName);
    setValueinForm("lastName", data?.lastName);
    setValueinForm("phoneNumber", inputFormatPhoneNumber(data?.phoneNumber));
    setValueinForm("email", data?.email);
    setValueinForm("company", data?.company);
    setValueinForm("contactPrefrence", data?.contactPrefrence);

    setValueinForm("province", data?.province?.province);
    setValueinForm("city", data?.city?.city);
    setValueinForm("streetAddress", data?.streetAddress);
    setValueinForm("country", data?.country?.country);
    setValueinForm("postalCode", formatPostalCode(data?.postalCode));
    setAddress(data?.streetAddress);
    setAddressValue({
      ...addressValues,
      city: data?.city?.city,
      province: data?.province?.province,
      cityId: data?.city?._id,
      provinceId: data?.province?._id,
      streetAddress: data?.streetAddress,
      postal: formatPostalCode(data?.postalCode),
    });
    setCountry(data?.country?.country);

    if (data?.secondaryPhoneNumber) {
      setShowSecondaryNumber(true);
      setValueinForm(
        "secondaryPhoneNumberType",
        data.secondaryPhoneNumberType
          ? phoneTypes.find(
              (option) => option.key == data?.secondaryPhoneNumberType
            )
          : ""
      );
      setValueinForm(
        "secondaryPhoneNumber",
        inputFormatPhoneNumber(data?.secondaryPhoneNumber)
      );
    }
    if (data?.secondaryEmail) {
      setShowSecondaryEmail(true);
      setValueinForm("secondaryEmail", data?.secondaryEmail);
    }
    if (vehicles.length > 0) {
      const vehicleData = vehicles[0];
      fetchVehicleMake({
        page: makeValuePage,
        limit: 500,
        year: vehicleData?.year,
      });
      fetchVehicleModel({
        page: modelValuePage,
        limit: 1000,
        vehicleMakeId: vehicleData?.vehicleMake._id,
        year: vehicleData?.year,
      });
      fetchVehicleTrim({
        page: trimValuePage,
        limit: 1000,
        vehicleMakeId: vehicleData?.vehicleMake._id,
        vehicleModelId: vehicleData?.vehicleModel._id,
        year: vehicleData?.year,
      });
      // setValueinForm("vin", vehicleData?.vin);e
      setValueinForm("vehicle.year", vehicleData?.year);
      setValueinForm("vehicle.vehicleMakeId", vehicleData?.vehicleMake?._id);
      setValueinForm("vehicle.vehicleModelId", vehicleData?.vehicleModel?._id);
      setValueinForm(
        "vehicle.vehicleModelTrimId",
        vehicleData?.vehicleModelTrim?._id
      );
      setValueinForm("vehicle.licenseNumber", vehicleData?.licenseNumber);
      setValueinForm("vehicle.provinceId", vehicleData?.province?._id);
      setProvinceValue(vehicleData?.province?._id);
      setSelectedProvinceObject(
        provinceList?.find((d) => d._id == vehicleData?.province?._id)
      );
      setYearValue(vehicleData?.year);
      setMakeValue(vehicleData?.vehicleMake?._id);
      setModelValue(vehicleData?.vehicleModel?._id);
      setTrimValue(vehicleData?.vehicleModelTrim?._id);
      if (trims && trims.length > 0) {
        setTrimEngineValue(
          trims.find((d) => d?._id == vehicleData?.vehicleModelTrimId?._id)
        );
      }
    }
  }, [data, openConvertModal, vehicles]);

  const fetchVehicleYear = async () => {
    await dispatch(vehicleYearMaster({ page: yearPage, limit: 500 }));
  };
  const year = useSelector((state) => state.customer?.vehicleYear);

  const fetchVehicleMake = async (vehicleMakeParams) => {
    setMakeLoader(true);
    await dispatch(vehicleMakeMaster(vehicleMakeParams));
    setMakeLoader(false);
  };
  const makes = useSelector((state) => state.customer?.vehicleMake);

  useEffect(() => {
    setMake(makes);
  }, [makes]);

  const fetchVehicleModel = async (vehicleModelParams) => {
    setModelLoader(true);
    await dispatch(vehicleModelMaster(vehicleModelParams));
    setModelLoader(false);
  };

  const models = useSelector((state) => state.customer?.vehicleModel);
  useEffect(() => {
    setModel(models);
  }, [models]);
  const fetchVehicleTrim = async (vehicleTrimParams) => {
    setTrimLoader(true);

    await dispatch(vehicleTrimMaster(vehicleTrimParams));
    setTrimLoader(false);
  };
  const trims = useSelector((state) => state.customer?.vehicleTrim);
  useEffect(() => {
    setTrim(trims);
  }, [trims]);

  const validatePhoneNumber = (number) => {
    const numericValue = number.replace(/\D/g, "");
    return numericValue.length === 10;
  };

  const checkPhoneNumberExist = async (flag) => {
    if (!flag) {
      setShowLoader(true);
    }

    var phoneNumberWithoutDashes = getValues()?.phoneNumber.replace(/-/g, "");

    const res = await dispatch(
      customerCheckPhoneNumberExist({ phoneNumber: phoneNumberWithoutDashes })
    );

    if (res.status && res?.data._id !== userId?.customer?._id) {
      setError("phoneNumber", {
        type: "required",
        message: "This phone number has been already taken.",
      });
      if (!flag) {
        setShowLoader(false);
      }
      return true;
    } else {
      clearErrors("phoneNumber");
      if (!flag) {
        setShowLoader(false);
      }
      return false;
    }
  };
  const onInvalid = (errors) => {
    const { streetAddress, city, province, postalCode } = getValues();

    const isAnyFieldFilled = [streetAddress, city, province, postalCode].some(
      (field) => field && field.trim() !== ""
    );

    if (isAnyFieldFilled) {
      if (!streetAddress)
        setError("streetAddress", {
          type: "required",
          message: "Street address is required",
        });
      if (!city)
        setError("city", { type: "required", message: "City is required" });
      if (!province)
        setError("province", {
          type: "required",
          message: "Province is required",
        });
      if (!postalCode)
        setError("postalCode", {
          type: "required",
          message: "Postal code is required",
        });

      if (!streetAddress || !city || !province || !postalCode) {
        setEditModalTabValue(1);
        return true;
      }
    } else {
      const firstErrorField = Object.keys(errors)[0];
      if (firstErrorField && firstErrorField.includes("vehicle")) {
        setEditModalTabValue(2);
        return true;
      }
      if (firstErrorField) {
        const errorElement = document.querySelector(
          `[name=${firstErrorField}]`
        );
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: "smooth" });
          setTimeout(() => {
            const offset = 140; // Adjust the offset value as needed
            const elementPosition = errorElement.getBoundingClientRect().top;
            const offsetPosition = elementPosition - offset;
            window.scrollBy({ top: offsetPosition, behavior: "smooth" });
            setFocus(firstErrorField);
          }, 0);
          return true;
        }
      } else {
        return false;
      }
    }
  };
  const handleUpdate = async (data) => {
    if (await checkPhoneNumberExist(true)) {
      setEditModalTabValue(0);
      return;
    }

    if (onInvalid(errors)) {
      return;
    }
    if (
      data.secondaryPhoneNumber &&
      data.secondaryPhoneNumber === data.phoneNumber
    ) {
      toast.error(
        "You’ve entered the same phone number for both primary and secondary."
      );
      return;
    }

    setShowLoader(true);
    onSubmitCustomer(data);
  };

  const onSubmitCustomer = async (values) => {
    let payload = {
      firstName: values.firstName,
      phoneNumber: values.phoneNumber,
    };
    if (values.lastName) {
      payload.lastName = values?.lastName;
    }
    payload.company = values?.company;
    if (addressValues?.city) {
      payload.city = addressValues?.cityId;
    }

    payload.country = "64e738882fec8f2c9b79c139";

    if (values?.postalCode) {
      payload.postalCode = values?.postalCode;
    }
    if (addressValues?.province) {
      payload.province = addressValues?.provinceId;
    }
    if (values?.streetAddress) {
      payload.streetAddress = values?.streetAddress;
    }
    if (values?.contactPrefrence) {
      payload.contactPrefrence = values?.contactPrefrence;
    }
    if (values?.primaryPhoneNumberType) {
      payload.primaryPhoneNumberType = values?.primaryPhoneNumberType?.key;
    }

    payload.secondaryPhoneNumberType = values?.secondaryPhoneNumberType?.key;

    payload.secondaryPhoneNumber = values?.secondaryPhoneNumber;

    if (primaryEmailIndex == 0) {
      payload.email = values?.email;
      payload.secondaryEmail = values?.secondaryEmail;
    } else {
      payload.secondaryEmail = values?.email;
      payload.email = values?.secondaryEmail;
    }

    if (!payload.secondaryPhoneNumber) {
      delete payload.secondaryPhoneNumber;
      delete payload.secondaryPhoneNumberType;
    }
    if (!payload.secondaryEmail) {
      delete payload.secondaryEmail;
    }
    if (
      values.membershipProgram &&
      values.membershipProgram !== "" &&
      values.membershipProgram !== "none"
    ) {
      payload.membershipProgram = values.membershipProgram;
    } else {
      payload.membershipProgram = "";
    }

    let vahiclePayload = values.vehicle;

    vahiclePayload.customerId = userId?.customer?._id;
    vahiclePayload.year = String(vahiclePayload.year);

    if (!vahiclePayload.vehicleModelTrimId) {
      delete vahiclePayload.vehicleModelTrimId;
    }
    const resp = await dispatch(
      updatePhoneNumberExistCustomer(userId?.customer?._id, payload)
    );
    setShowLoader(true);
    let vehcileResp = await dispatch(
      editCustomerVehicle(vehicles[0]?._id, vahiclePayload)
    );
    if (resp) {
      setEditModalTabValue(0);
      handleCloseCustomer();
    }
    setShowLoader(false);
  };

  return (
    <div>
      <Modal
        open={openConvertModal}
        // onClose={() => {
        //   handleCloseCustomer();
        // }}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            // Ignore clicks outside the modal
            return;
          }
          handleCloseCustomer(false);
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            border: "none",
            borderRadius: "8px 8px 0px 0px",
          }}
          className="modalBodyBoxContainer"
        >
          <AppBar
            color="secondary"
            variant="elevation"
            sx={{
              backgroundColor: "#002394",
              color: "white",
              height: "64px",
              borderRadius: "8px 8px 0px 0px !important",
              paddingRight: "0px !important",
            }}
          >
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"} color={"white"}>
                New Customer
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={() => {
                    handleCloseCustomer();
                  }}
                  style={{ cursor: "pointer", height: "32px" }}
                >
                  <CloseIcon style={{ color: "#0000008F", fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            gap={2}
            disableGutters
            style={{
              marginTop: "66px",
              // scrollbarColor: "blue",
              // scrollbarWidth: "thin",
              // overflowY: "auto",
              // maxHeight: "80vh",
              // position: "relative",
            }}
          >
            <form onSubmit={handleSubmit(handleUpdate, onInvalid)}>
              <Grid
                container
                // spacing={3}
                style={{
                  scrollbarColor: "blue",
                  scrollbarWidth: "thin",
                  overflowY: "auto",
                  maxHeight: "70vh",
                }}
                padding={"24px 24px 0 24px"}
              >
                <Tabs
                  value={editModalTabvalue}
                  onChange={handleChangeEditCustomerTabValue}
                  aria-label="basic tabs example"
                  sx={{ marginBottom: "24px" }}
                >
                  <Tab label="BASIC DETAILS" {...a11yProps(0)} />

                  <Tab label="ADDITIONAL DETAILS" {...a11yProps(1)} />
                  <Tab label="VEHICLE DETAILS" {...a11yProps(2)} />
                </Tabs>

                <div
                  style={{ display: editModalTabvalue == 0 ? "block" : "none" }}
                >
                  <Grid container spacing={3}>
                    <Grid item container xs={12} sm={12} md={12} xl={12}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Stack spacing={1}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            }}
                          >
                            Primary Phone Number{" "}
                            <span style={{ color: REQUIRED_RED }}>*</span>
                          </Typography>
                          <div style={{ display: "flex" }}>
                            <Grid container columnGap={2} rowGap={2}>
                              <Grid item xs={12} sm={12} md={8} lg={3} xl={3}>
                                <FormControl
                                  fullWidth
                                  error={!!errors.primaryPhoneNumberType}
                                >
                                  <Controller
                                    name="primaryPhoneNumberType"
                                    control={control}
                                    rules={{
                                      required: "Phone Number Type is required",
                                    }}
                                    render={({ field }) => (
                                      <Select
                                        placeholder="" // defaultValue={}
                                        isLoading={false}
                                        isClearable={false}
                                        isSearchable={false}
                                        value={
                                          selectedPhoneTypes
                                            ? phoneTypes.find(
                                                (option) =>
                                                  option.key ==
                                                  selectedPhoneTypes?.primary
                                              )
                                            : ""
                                        }
                                        options={phoneTypes ? phoneTypes : []}
                                        getOptionLabel={(option) =>
                                          option.value
                                        } // Specifies which field to use as the label
                                        getOptionValue={(option) => option.key}
                                        onChange={(e) => {
                                          setSelectedPhoneTypes({
                                            ...selectedPhoneTypes,
                                            primary: e ? e.key : "",
                                          });
                                        }}
                                        styles={
                                          errors.primaryPhoneNumberType
                                            ? {
                                                ...customStyles,
                                                ...errorCustomStyles,
                                                ...phoneTypeCustomStyles,
                                              }
                                            : {
                                                ...customStyles,
                                                ...phoneTypeCustomStyles,
                                              }
                                        }
                                        error={!!errors.primaryPhoneNumberType}
                                        helperText={
                                          errors.primaryPhoneNumberType
                                            ? errors.primaryPhoneNumberType
                                                .message
                                            : ""
                                        }
                                        {...field}
                                      />
                                    )}
                                  />
                                  {errors.primaryPhoneNumberType && (
                                    <FormHelperText>
                                      {errors.primaryPhoneNumberType.message}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={6.5}
                                xl={6.5}
                              >
                                <Controller
                                  name="phoneNumber"
                                  control={control}
                                  rules={{
                                    required: "Phone Number is required",
                                    pattern: {
                                      value: /^\d{3}-\d{3}-\d{4}$/,
                                      message:
                                        "Phone number should be 10 digits long",
                                    },
                                  }}
                                  render={({ field }) => (
                                    <TextField
                                      id="outlined-basic"
                                      variant="outlined"
                                      fullWidth
                                      error={!!errors.phoneNumber}
                                      helperText={
                                        errors.phoneNumber
                                          ? errors.phoneNumber.message
                                          : ""
                                      }
                                      {...field}
                                      // onChange={(e) => {
                                      //   setValueinForm(
                                      //     "phoneNumber",
                                      //     inputFormatPhoneNumber(e.target.value)
                                      //   );
                                      //   if (e.target.value.length == 12) {
                                      //     clearErrors("phoneNumber");
                                      //   } else {
                                      //     setError("phoneNumber", {
                                      //       message:
                                      //         "Phone number should be 10 digits long",
                                      //     });
                                      //   }
                                      // }}
                                      onChange={(e) => {
                                        // setValue(
                                        //   "phoneNumber",
                                        //   formatPhoneNumber(e.target.value)
                                        // );
                                        if (e.target.value.length <= 12) {
                                          setValueinForm(
                                            "phoneNumber",
                                            inputFormatPhoneNumber(
                                              e.target.value
                                            )
                                          );
                                          let nondashedPhoneNumber =
                                            e.target.value
                                              .toString()
                                              ?.replace(/-/g, "");
                                          if (
                                            nondashedPhoneNumber.length == 10 &&
                                            validatePhoneNumber(
                                              inputFormatPhoneNumber(
                                                e.target.value
                                              )
                                            )
                                          ) {
                                            checkPhoneNumberExist();
                                          }
                                          if (
                                            !validatePhoneNumber(
                                              inputFormatPhoneNumber(
                                                e.target.value
                                              )
                                            )
                                          ) {
                                            setError("phoneNumber", {
                                              type: "manual",
                                              message:
                                                "Invalid phone number. Must be 10 digits.",
                                            });
                                          } else {
                                          }
                                        }
                                      }}
                                      onKeyDown={handlePreventKeyDown}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </Stack>

                        <div
                          onClick={() => setShowSecondaryNumber(true)}
                          style={{
                            color: "#006CDD",
                            cursor: "pointer",
                            fontWeight: 500,
                            height: "36px",
                            padding: "4px 5px",
                            letterSpacing: "0.46px",
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          + ADD PHONE NUMBER
                        </div>
                      </Grid>
                    </Grid>
                    {showSecondaryNumber && (
                      <Grid item container xs={12} sm={12} md={12} xl={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Stack spacing={1}>
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                                letterSpacing: "0.15px",
                              }}
                            >
                              {" "}
                              Secondary Phone Number{" "}
                              {/* <span style={{ color: REQUIRED_RED }}>*</span> */}
                            </Typography>

                            <Grid container item columnGap={2} rowGap={2}>
                              <Grid item xs={12} sm={12} md={8} lg={3} xl={3}>
                                <FormControl
                                  fullWidth
                                  error={!!errors.secondaryPhoneNumberType}
                                >
                                  <Controller
                                    name="secondaryPhoneNumberType"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        placeholder="" // defaultValue={}
                                        isLoading={false}
                                        isClearable={false}
                                        isSearchable={false}
                                        value={
                                          selectedPhoneTypes
                                            ? phoneTypes.find(
                                                (option) =>
                                                  option.key ===
                                                  selectedPhoneTypes?.secondary
                                              )
                                            : ""
                                        }
                                        options={phoneTypes ? phoneTypes : []}
                                        getOptionLabel={(option) =>
                                          option.value
                                        } // Specifies which field to use as the label
                                        getOptionValue={(option) => option}
                                        onChange={(e) => {
                                          setSelectedPhoneTypes({
                                            ...selectedPhoneTypes,
                                            secondary: e ? e.key : "",
                                          });
                                        }}
                                        styles={
                                          errors.secondaryPhoneNumberType
                                            ? {
                                                ...customStyles,
                                                ...errorCustomStyles,
                                                ...phoneTypeCustomStyles,
                                              }
                                            : {
                                                ...customStyles,
                                                ...phoneTypeCustomStyles,
                                              }
                                        }
                                        error={
                                          !!errors.secondaryPhoneNumberType
                                        }
                                        helperText={
                                          errors.secondaryPhoneNumberType
                                            ? errors.secondaryPhoneNumberType
                                                .message
                                            : ""
                                        }
                                        {...field}
                                      />
                                    )}
                                  />
                                  {errors.secondaryPhoneNumberType && (
                                    <FormHelperText>
                                      {errors.secondaryPhoneNumberType.message}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={6.6}
                                xl={6.6}
                              >
                                <Controller
                                  name="secondaryPhoneNumber"
                                  control={control}
                                  rules={{
                                    pattern: {
                                      value: /^\d{3}-\d{3}-\d{4}$/,
                                      message:
                                        "Phone number should be 10 digits long",
                                    },
                                  }}
                                  render={({ field }) => (
                                    <TextField
                                      id="outlined-basic"
                                      variant="outlined"
                                      fullWidth
                                      // required
                                      error={!!errors.secondaryPhoneNumber}
                                      helperText={
                                        errors.secondaryPhoneNumber
                                          ? errors.secondaryPhoneNumber.message
                                          : ""
                                      }
                                      {...field}
                                      onChange={(e) => {
                                        setValueinForm(
                                          "secondaryPhoneNumber",
                                          inputFormatPhoneNumber(e.target.value)
                                        );
                                        if (
                                          e.target.value.length == 12 ||
                                          e.target.value.length == 0
                                        ) {
                                          clearErrors("secondaryPhoneNumber");
                                        } else {
                                          setError("secondaryPhoneNumber", {
                                            message:
                                              "Phone number should be 10 digits long",
                                          });
                                        }
                                      }}
                                      onKeyDown={handlePreventKeyDown}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={2} sm={2} md={1} xl={1}>
                                <div
                                  style={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconButton
                                    color="error"
                                    size="medium"
                                    onClick={() => {
                                      setShowSecondaryNumber(false);
                                      setValueinForm(
                                        "secondaryPhoneNumber",
                                        ""
                                      );
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </Grid>
                            </Grid>
                          </Stack>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12} md={6} xl={6}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          First Name{" "}
                          <span style={{ color: "#D32F2F" }}> *</span>
                        </Typography>
                        <Controller
                          name="firstName"
                          control={control}
                          rules={{
                            required: "First Name is required",
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              id="outlined-basic"
                              variant="outlined"
                              error={!!errors.firstName}
                              helperText={
                                errors.firstName ? errors.firstName.message : ""
                              }
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Last Name
                        </Typography>
                        <Controller
                          name="lastName"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              // required
                              id="outlined-basic"
                              variant="outlined"
                              error={!!errors.lastName}
                              helperText={
                                errors.lastName ? errors.lastName.message : ""
                              }
                              {...field}
                            />
                          )}
                        />
                      </Stack>
                    </Grid>

                    <Grid item container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                            marginBottom: "8px",
                          }}
                        >
                          {" "}
                          Email
                        </Typography>
                        <div style={{ display: "flex" }}>
                          <Grid container item columnSpacing={2} rowGap={0}>
                            <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                              <Controller
                                name="email"
                                control={control}
                                rules={{
                                  pattern: {
                                    value:
                                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/,
                                    message: "Please enter valid email address",
                                  },
                                }}
                                render={({ field }) => (
                                  <TextField
                                    id="outlined-basic"
                                    /*label="Email"*/
                                    variant="outlined"
                                    fullWidth
                                    // required
                                    error={!!errors.email}
                                    helperText={
                                      errors.email ? errors.email.message : ""
                                    }
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} xl={2}>
                              <div
                                style={{
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={0 == primaryEmailIndex}
                                      onChange={(e) => {
                                        showSecondaryEmail &&
                                          setPrimaryEmailIndex(
                                            e.target.checked ? 0 : 1
                                          );
                                      }}
                                    />
                                  }
                                  label="Primary"
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>

                        <div
                          onClick={() => setShowSecondaryEmail(true)}
                          style={{
                            color: "#006CDD",
                            cursor: "pointer",
                            fontWeight: 500,
                            height: "36px",
                            padding: "4px 5px",
                            letterSpacing: "0.46px",
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          + ADD EMAIL
                        </div>

                        {showSecondaryEmail && (
                          <Grid
                            container
                            item
                            columnSpacing={2}
                            rowGap={2}
                            marginTop={2}
                          >
                            <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                              <Controller
                                name="secondaryEmail"
                                control={control}
                                rules={{
                                  pattern: {
                                    value:
                                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/,
                                    message: "Please enter valid email address",
                                  },
                                }}
                                render={({ field }) => (
                                  <TextField
                                    id="outlined-basic"
                                    /*label="Email"*/
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.secondaryEmail}
                                    helperText={
                                      errors.secondaryEmail
                                        ? errors.secondaryEmail.message
                                        : ""
                                    }
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                              <div
                                style={{
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={1 == primaryEmailIndex}
                                      onChange={(e) => {
                                        setPrimaryEmailIndex(
                                          e.target.checked ? 1 : 0
                                        );
                                      }}
                                    />
                                  }
                                  label="Primary"
                                />
                                {/* <CheckBox  >Primary</CheckBox> */}
                                <IconButton
                                  color="error"
                                  size="medium"
                                  onClick={() => {
                                    setShowSecondaryEmail(false);
                                    setPrimaryEmailIndex(0);
                                    setValueinForm("secondaryEmail", "");
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                <div
                  style={{ display: editModalTabvalue == 1 ? "block" : "none" }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Company
                          {/* <span style={{ color: "#D32F2F" }}> *</span> */}
                        </Typography>
                        <Controller
                          name="company"
                          control={control}
                          // rules={{
                          //   required: "Street Address is required",
                          // }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="outlined-basic"
                              variant="outlined"
                              fullWidth
                              // defaultValue={addressValues?.streetAddress}
                              // required
                              InputLabelProps={{
                                shrink: field.value?.length > 0,
                              }}
                              error={!!errors.company}
                              helperText={
                                errors.company ? errors.company.message : ""
                              }
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Discount Program
                        </Typography>
                        <Controller
                          name="membershipProgram"
                          control={control}
                          // rules={{
                          //   required: "Last Name is required",
                          // }}
                          render={({ field }) => (
                            <MuiSelect
                              onChange={(value) => {
                                setValueinForm(
                                  "membershipProgram",
                                  value.target.value
                                );
                                // setValueinForm("firstName", value?.firstName);
                              }}
                              {...field}
                            >
                              <MenuItem value={"none"}>None</MenuItem>
                              {discountList.map((item, index) => (
                                <MenuItem key={index} value={item._id}>
                                  <Stack
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                    sx={{ width: "100%" }}
                                  >
                                    <div> {item.programName}</div>
                                    <Chip
                                      sx={{
                                        background: "#EBEBEB",
                                        color: "#000",
                                      }}
                                      size="small"
                                      label={`${item.discount}%`}
                                    />
                                  </Stack>
                                </MenuItem>
                              ))}
                            </MuiSelect>
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormControl fullWidth error={!!errors.contactPrefrence}>
                        <Stack spacing={1}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            }}
                          >
                            Contact Preference
                          </Typography>
                          <Controller
                            name="contactPrefrence"
                            control={control}
                            // rules={{
                            //   required: "Phone Number Type is required",
                            // }}
                            render={({ field: { onChange, value } }) => (
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              >
                                {contactPrefrences?.map((contact) => (
                                  <FormControlLabel
                                    value={contact.key}
                                    control={<Radio />}
                                    label={contact?.value}
                                  />
                                ))}
                              </RadioGroup>
                            )}
                          />
                        </Stack>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} xl={12}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Street Address
                          {(addressValues.streetAddress ||
                            addressValues.province ||
                            addressValues.city ||
                            addressValues.postal) && (
                            <span style={{ color: "#D32F2F" }}> *</span>
                          )}
                        </Typography>
                        <Controller
                          name="streetAddress"
                          control={control}
                          // rules={{
                          //   required: (addressValues.city !== undefined || addressValues.city !== '' ||
                          //     addressValues.province == undefined || addressValues.province == "" ||
                          //     addressValues.postal !== '') &&
                          //     (addressValues.streetAddress == '') ? "Street Address is required" : false,
                          // }}
                          render={({ field }) => (
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              fullWidth
                              error={!!errors.streetAddress}
                              helperText={
                                errors.streetAddress
                                  ? errors.streetAddress.message
                                  : ""
                              }
                              {...field}
                              onChange={(e) => {
                                setValueinForm("streetAddress", e.target.value);
                                setAddressValue({
                                  ...addressValues,
                                  streetAddress: e.target.value,
                                });
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} xl={4}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          City
                          {(addressValues.streetAddress ||
                            addressValues.province ||
                            addressValues.city ||
                            addressValues.postal) && (
                            <span style={{ color: "#D32F2F" }}> *</span>
                          )}
                        </Typography>
                        <Controller
                          name="city"
                          control={control}
                          render={({ field }) => (
                            <AddressSelect
                              list={cityList}
                              addressValues={addressValues}
                              setAddressValue={setAddressValue}
                              type={"city"}
                              loader={cityListLoader}
                              setValue={setValueinForm}
                              error={!!errors.city}
                              menuPlacement={"top"}
                            />
                          )}
                        />
                        {errors && errors.city && (
                          <p
                            style={{
                              color: "#d32f2f",
                              fontSize: "0.75rem",
                              letterSpacing: "0.03333em",
                              lineHeight: "1.66",
                              marginTop: "3px",
                            }}
                          >
                            {errors.city?.message}
                          </p>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} xl={4}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Province
                          {(addressValues.streetAddress ||
                            addressValues.province ||
                            addressValues.city ||
                            addressValues.postal) && (
                            <span style={{ color: "#D32F2F" }}> *</span>
                          )}
                        </Typography>
                        <Controller
                          name="province"
                          control={control}
                          render={({ field }) => (
                            <AddressSelect
                              list={provinceList}
                              addressValues={addressValues}
                              setAddressValue={setAddressValue}
                              type={"province"}
                              setCityList={setCityList}
                              fetchCityList={fetchCityList}
                              loader={provinceListLoader}
                              setValue={setValueinForm}
                              error={!!errors.province}
                              menuPlacement={"top"}
                            />
                          )}
                        />
                        {errors && errors.province && (
                          <p
                            style={{
                              color: "#d32f2f",
                              fontSize: "0.75rem",
                              letterSpacing: "0.03333em",
                              lineHeight: "1.66",
                              marginTop: "3px",
                            }}
                          >
                            {errors.province?.message}
                          </p>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} xl={4}>
                      <PostalCode
                        name="postalCode"
                        control={control}
                        errors={errors}
                        setValueinForm={setValueinForm}
                        setAddressValue={setAddressValue}
                        addressValues={addressValues}
                        setError={setError}
                        clearErrors={clearErrors}
                      />
                    </Grid>
                  </Grid>
                </div>

                <div
                  style={{ display: editModalTabvalue == 2 ? "block" : "none" }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Province
                          <span style={{ color: "#D32F2F" }}> *</span>
                        </Typography>
                        <Controller
                          name="vehicle.provinceId"
                          control={control}
                          rules={{
                            required: config?.isLicensePlateRequired
                              ? "Province is required"
                              : "",
                          }}
                          render={({ field }) => (
                            <MuiSelect
                              fullWidth
                              value={provinceValue || ""}
                              MenuProps={{
                                style: { maxHeight: "400px" },
                              }}
                              {...field}
                              onChange={(event) => {
                                const value = event.target.value;
                                // Update state values
                                setProvinceValue(value);
                                setSelectedProvinceObject(
                                  provinceList?.find((d) => d?._id === value)
                                );

                                // React Hook Form handling
                                field.onChange(value);
                                clearErrors("vehicle.provinceId");
                              }}
                              error={!!errors.vehicle?.province}
                            >
                              {Array.isArray(provinceList) &&
                                provinceList.map((m) => (
                                  <MenuItem value={m?._id} key={m?._id}>
                                    {m?.province}
                                  </MenuItem>
                                ))}
                              {provinceListLoader && (
                                <MenuItem disabled>
                                  <CircularProgress />
                                </MenuItem>
                              )}
                            </MuiSelect>
                          )}
                        />
                        {errors.vehicle?.province && (
                          <Typography
                            variant="caption"
                            style={{ color: "#D32F2F", marginTop: "4px" }}
                          >
                            {errors.vehicle.provinceId.message}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Licence Plate
                          <span style={{ color: "#D32F2F" }}> *</span>
                        </Typography>
                        <Controller
                          name="vehicle.licenseNumber"
                          control={control}
                          rules={{
                            required: "Licence Plate is required",
                          }}
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              variant="outlined"
                              error={
                                !!(
                                  errors.vehicle && errors.vehicle.licenseNumber
                                )
                              }
                              helperText={
                                errors.vehicle?.licenseNumber?.message || ""
                              }
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              {...field}
                              onChange={(e) => {
                                const value = e.target.value.trim();
                                if (value) {
                                  clearErrors("vehicle.licenseNumber");
                                } else {
                                  setError("vehicle.licenseNumber", {
                                    message: "Licence Plate Number is required",
                                  });
                                }
                                field.onChange(e); // Pass the change event to the field's onChange
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <Typography
                          variant="body1"
                          className="label-500"
                          style={{ fontWeight: 400 }}
                        >
                          Year <span style={{ color: "#D32F2F" }}> *</span>
                        </Typography>
                        <Controller
                          defaultValue=""
                          name="vehicle.year"
                          control={control}
                          rules={{
                            required: "Year is required",
                          }}
                          render={({ field }) => (
                            <MuiSelect
                              {...field}
                              fullWidth
                              value={yearValue || ""}
                              onChange={(event) => {
                                const value = event.target.value;

                                // React Hook Form updates
                                field.onChange(value);

                                // Set state values
                                setYearValue(value);
                                setMakeValue(null);
                                setModelValue(null);
                                setTrimValue(null);
                                setMake([]);
                                setModel([]);
                                setTrim([]);

                                // Fetch updated data based on year
                                fetchVehicleMake({
                                  page: makeValuePage,
                                  limit: 500,
                                  year: value,
                                });

                                // Clear dependent values in the form
                                setValueinForm("vehicleMakeId", "");
                                setValueinForm("vehicleModelId", "");
                                setValueinForm("vehicleModelTrimId", "");
                              }}
                              error={!!errors.vehicle?.year}
                              MenuProps={{
                                style: { maxHeight: "400px" },
                              }}
                            >
                              {Array.isArray(year) &&
                                year.map((y) => (
                                  <MenuItem value={y} key={y}>
                                    {y}
                                  </MenuItem>
                                ))}
                            </MuiSelect>
                          )}
                        />
                        {errors.vehicle?.year && (
                          <FormHelperText error>
                            {errors.vehicle.year.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <Typography
                          variant="body1"
                          className="label-500"
                          style={{ fontWeight: 400 }}
                        >
                          Make <span style={{ color: "#D32F2F" }}> *</span>
                        </Typography>
                        {makeLoader && makeValue != null ? (
                          <Skeleton
                            animation="wave"
                            variant="text"
                            sx={{ fontSize: "3.5rem" }}
                            className="skelton"
                          ></Skeleton>
                        ) : (
                          <Controller
                            name="vehicle.vehicleMakeId"
                            defaultValue=""
                            control={control}
                            rules={{
                              required: "Make is required",
                            }}
                            render={({ field }) => (
                              <MuiSelect
                                {...field}
                                fullWidth
                                value={makeValue || ""}
                                disabled={!yearValue}
                                onChange={(event) => {
                                  const value = event.target.value;

                                  // React Hook Form update
                                  field.onChange(value);

                                  // Set state values
                                  setMakeValue(value);
                                  setModelValue(null);
                                  setModel([]);
                                  setTrim([]);
                                  setTrimValue(null);

                                  // Clear dependent fields in the form
                                  setValueinForm("vehicle.vehicleModelId", "");
                                  setValueinForm(
                                    "vehicle.vehicleModelTrimId",
                                    ""
                                  );

                                  // Fetch models based on selected make
                                  fetchVehicleModel({
                                    page: modelValuePage,
                                    limit: 1000,
                                    vehicleMakeId: value,
                                    year: yearValue,
                                  });
                                }}
                                error={!!errors.vehicle?.vehicleMakeId}
                                MenuProps={{
                                  style: { maxHeight: "400px" },
                                }}
                              >
                                {Array.isArray(make) &&
                                  make.map((m) => (
                                    <MenuItem value={m?._id} key={m?._id}>
                                      {m?.name}
                                    </MenuItem>
                                  ))}
                                {makeLoader && (
                                  <MenuItem disabled>
                                    <CircularProgress />
                                  </MenuItem>
                                )}
                              </MuiSelect>
                            )}
                          />
                        )}
                        {errors.vehicle?.vehicleMakeId && (
                          <FormHelperText error>
                            {errors.vehicle.vehicleMakeId.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <Typography
                          variant="body1"
                          className="label-500"
                          style={{ fontWeight: 400 }}
                        >
                          Model <span style={{ color: "#D32F2F" }}> *</span>
                        </Typography>
                        {modelLoader && modelValue != null ? (
                          <Skeleton
                            animation="wave"
                            variant="text"
                            sx={{ fontSize: "3.5rem" }}
                            className="skelton"
                          />
                        ) : (
                          <Controller
                            name="vehicle.vehicleModelId"
                            control={control}
                            rules={{
                              required: "Model is required",
                            }}
                            render={({ field }) => (
                              <MuiSelect
                                {...field}
                                fullWidth
                                value={modelValue || ""}
                                disabled={!yearValue || !makeValue}
                                onChange={(event) => {
                                  const value = event.target.value;

                                  // React Hook Form update
                                  field.onChange(value);

                                  // Update state
                                  setModelValue(value);
                                  setTrimValue(null);
                                  setTrim([]);

                                  // Clear dependent fields in the form
                                  setValueinForm(
                                    "vehicle.vehicleModelTrimId",
                                    null
                                  );

                                  // Fetch trim data based on model
                                  fetchVehicleTrim({
                                    page: trimValuePage,
                                    limit: 1000,
                                    vehicleMakeId: makeValue,
                                    vehicleModelId: value,
                                    year: yearValue,
                                  });
                                }}
                                error={!!errors.vehicle?.vehicleModelId}
                                MenuProps={{
                                  style: { maxHeight: "400px" },
                                }}
                              >
                                {Array.isArray(model) &&
                                  model.map((m) => (
                                    <MenuItem value={m?._id} key={m?._id}>
                                      {m?.name}
                                    </MenuItem>
                                  ))}
                                {modelLoader && (
                                  <MenuItem disabled>
                                    <CircularProgress />
                                  </MenuItem>
                                )}
                              </MuiSelect>
                            )}
                          />
                        )}
                        {errors.vehicle?.vehicleModelId && (
                          <FormHelperText error>
                            {errors.vehicle.vehicleModelId.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <Typography
                          variant="body1"
                          className="label-500"
                          style={{ fontWeight: 400 }}
                        >
                          Trim
                          {/* <span style={{ color: "#D32F2F" }}> *</span> */}
                        </Typography>
                        {trimLoader && trimValue != null ? (
                          <Skeleton
                            animation="wave"
                            variant="text"
                            sx={{ fontSize: "3.5rem" }}
                            className="skelton"
                          />
                        ) : (
                          <Controller
                            name="vehicle.vehicleModelTrimId"
                            control={control}
                            render={({ field }) => (
                              <MuiSelect
                                {...field}
                                fullWidth
                                disabled={
                                  !yearValue || !makeValue || !modelValue
                                }
                                value={trimValue || ""}
                                onChange={(event) => {
                                  const value = event.target.value;

                                  // React Hook Form update
                                  field.onChange(value);

                                  // Update state
                                  setTrimValue(value);

                                  // Set the selected trim details
                                  setTrimEngineValue(
                                    trim.find((d) => d?._id === value)
                                  );
                                }}
                                error={!!errors.vehicle?.vehicleModelTrimId}
                                MenuProps={{
                                  style: { maxHeight: "400px" },
                                }}
                              >
                                {Array.isArray(trim) &&
                                  trim.map((m) => (
                                    <MenuItem value={m?._id} key={m?._id}>
                                      <div>
                                        <div style={{ fontWeight: 500 }}>
                                          {m?.name}
                                        </div>
                                        <div>{m?.description}</div>
                                      </div>
                                    </MenuItem>
                                  ))}
                                {trimLoader && (
                                  <MenuItem disabled>
                                    <CircularProgress />
                                  </MenuItem>
                                )}
                              </MuiSelect>
                            )}
                          />
                        )}
                        {errors.vehicle?.vehicleModelTrimId && (
                          <FormHelperText error>
                            {errors.vehicle.vehicleModelTrimId.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid
                item
                container
                justifyContent={"flex-end"}
                style={{ marginTop: "24px" }}
                padding={"0px 24px 24px 24px"}
              >
                <Grid item>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleCloseCustomer();
                      }}
                      size="large"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      onClick={() => {
                        if (Object.keys(errors)?.length != 0) {
                          setEditModalTabValue(0);
                        }
                      }}
                    >
                      Save
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Modal>
      <Snackbar
        open={openSuccessModal}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message="I love snacks"
        key={"top" + "center"}
        onClose={() => setOpenSuccessModal(false)}
        autoHideDuration={2000}
      >
        <Alert severity={"error"}>{successMessage}</Alert>
      </Snackbar>
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ConvertCustomerModal;
