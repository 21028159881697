import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Stack,
  TextField,
  Toolbar,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
// import Link from "@mui/material/Link";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { REQUIRED_RED } from "../../App";
import {
  decodeLicence,
  decodeVIN,
  vehicleMakeMaster,
  vehicleModelMaster,
  vehicleTrimMaster,
  vehicleYearMaster,
} from "../../redux/Customer/action";
import { checkValidVIN } from "../../utility/utilityFunctions";
import { configDetails } from "../../redux/Account/action";
import { fetchProvinces } from "../../redux/Vehicles/action";
import useDebounce from "../../utility/useDebounce";
import { toast } from "sonner";

const select_category = [
  { label: "Inglourious Basterds", year: 2009 },
  { label: "Snatch", year: 2000 },
  { label: "3 Idiots", year: 2009 },
  { label: "Monty Python and the Holy Grail", year: 1975 },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  // p: 3,
};

const VehicleModel = ({
  open,
  handleClose,
  vehicles,
  setVehicles,
  editVehicle,
  setEditVehicle,
  handleDeleteVehicle,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
    getValues,
    clearErrors,
    setError,
    setFocus,
    watch,
  } = useForm();
  const dispatch = useDispatch();

  const [step, setStep] = useState(editVehicle?.edit == true ? 2 : 1);

  const [yearValue, setYearValue] = useState("");
  const [makeValue, setMakeValue] = useState("");
  const [modelValue, setModelValue] = useState("");
  const [trimValue, setTrimValue] = useState("");
  const [provinceValue, setProvinceValue] = useState("");
  const [selectedProvinceObject, setSelectedProvinceObject] = useState("");

  const [provinces, setProvince] = useState([]);

  const [yearPage, setYearPage] = useState(1);
  const [trimEngineValue, setTrimEngineValue] = useState("");

  const [makeValuePage, setMakeValuePage] = useState(1);
  const [modelValuePage, setModelValuePage] = useState(1);
  const [trimValuePage, setTrimValuePage] = useState(1);
  const [makeLoader, setMakeLoader] = useState(false);
  const [modelLoader, setModelLoader] = useState(false);
  const [trimLoader, setTrimLoader] = useState(false);
  const [provinceLoader, setProvinceLoader] = useState(false);
  const [loader, setLoader] = useState(false);

  const [make, setMake] = useState([]);
  const [model, setModel] = useState([]);
  const [trim, setTrim] = useState([]);
  const [decode, setDecode] = useState({
    vin: false,
    licence: false,
  });
  let vehicleMakeParams = {
    page: makeValuePage,
    limit: 500,
    year: yearValue,
  };

  const watchedLicenceValue = watch("licenseNumber", "");

  // Use debounce for the watched value
  const debouncedLicenceValue = useDebounce(watchedLicenceValue, 1500);

  const watchedVINValue = watch("vin", "");

  // Use debounce for the watched value
  const debouncedVINValue = useDebounce(watchedVINValue, 1500);

  useEffect(() => {
    fetchConfig();
  }, [open]);
  useEffect(() => {
    if (editVehicle?.edit) {
      setStep(2);
      // console.log("inside", vehicles);
      const data = vehicles[editVehicle?.editIndex];
      fetchVehicleMake({
        page: makeValuePage,
        limit: 500,
        year: data?.year,
      });
      fetchVehicleModel({
        page: modelValuePage,
        limit: 1000,
        vehicleMakeId: data?.vehicleMakeId.id,
        year: data?.year,
      });
      fetchVehicleTrim({
        page: trimValuePage,
        limit: 1000,
        vehicleMakeId: data?.vehicleMakeId.id,
        vehicleModelId: data?.vehicleModelId.id,
        year: data?.year,
      });
      setValue("vin", data?.vin);
      setValue("year", data?.year);
      setValue("vehicleMakeId", JSON.stringify(data?.vehicleMakeId));
      setValue("vehicleModelId", JSON.stringify(data?.vehicleModelId));
      setValue("vehicleModelTrimId", JSON.stringify(data?.vehicleModelTrimId));
      setValue("licenseNumber", data?.licenseNumber);
      setValue("province", data?.provinceId);
      setProvinceValue(data?.provinceId);
      setSelectedProvinceObject(
        provinces?.find((d) => d.provinceCode == data?.provinceId)
      );
      setYearValue(data?.year);
      setMakeValue(JSON.stringify(data?.vehicleMakeId));
      setModelValue(JSON.stringify(data?.vehicleModelId));
      setTrimValue(JSON.stringify(data?.vehicleModelTrimId));
      if (trims) {
        setTrimEngineValue(
          trims.find((d) => d?._id == data?.vehicleModelTrimId?.id)
        );
      }
    }
  }, [editVehicle]);

  useEffect(() => {
    fetchVehicleYear();
    fetchProvincesList();
    // fetchVehicleMake(vehicleMakeParams);
  }, []);

  const fetchConfig = async () => {
    await dispatch(configDetails());
  };
  const config = useSelector((state) => state.account.configDetailData);

  const fetchProvincesList = async () => {
    await dispatch(fetchProvinces());
  };
  const provincesList = useSelector((state) => state.vehicle.provinces)?.sort(
    (a, b) => a.province.localeCompare(b.province)
  );

  useEffect(() => {
    setProvince(provincesList);
  }, [provincesList]);

  useEffect(() => {
    if (!editVehicle?.edit) {
      const defaultProvince = provinces?.find(
        (d) => d.isDefault === true
      )?.provinceCode;
      setValue("province", defaultProvince);
      setProvinceValue(defaultProvince);
      setSelectedProvinceObject(provinces?.find((d) => d.isDefault === true));
    }
  }, [provinces, open]);

  const fetchVehicleYear = async () => {
    await dispatch(vehicleYearMaster({ page: yearPage, limit: 500 }));
  };
  const year = useSelector((state) => state.customer?.vehicleYear);

  const fetchVehicleMake = async (vehicleMakeParams) => {
    setMakeLoader(true);
    await dispatch(vehicleMakeMaster(vehicleMakeParams));
    setMakeLoader(false);
  };
  const makes = useSelector((state) => state.customer?.vehicleMake);

  useEffect(() => {
    setMake(makes);
  }, [makes]);
  const fetchVehicleModel = async (vehicleModelParams) => {
    setModelLoader(true);
    await dispatch(vehicleModelMaster(vehicleModelParams));
    setModelLoader(false);
  };

  const models = useSelector((state) => state.customer?.vehicleModel);
  useEffect(() => {
    setModel(models);
  }, [models]);
  const fetchVehicleTrim = async (vehicleTrimParams) => {
    setTrimLoader(true);

    await dispatch(vehicleTrimMaster(vehicleTrimParams));
    setTrimLoader(false);
  };
  const trims = useSelector((state) => state.customer?.vehicleTrim);
  useEffect(() => {
    setTrim(trims);
  }, [trims]);
  const handleDecodeVIN = async () => {
    if (getValues()?.vin && getValues()?.vin.length == 17) {
      // setVINError("");
      clearErrors("vin");
      setLoader(true);

      const resp = await dispatch(decodeVIN(getValues()?.vin));
      // setModelSelectLoader(true);
      setStep(2);
      setMakeValue(resp?.data?.make?._id);
      setModelValue("");
      setTrimValue("");
      await dispatch(
        vehicleMakeMaster({
          page: 1,
          limit: 1000,
          year: resp?.data?.year,
        })
      );
      let para = {
        page: 1,
        limit: 1000,
        vehicleMakeId: resp?.data?.make?._id,
        year: resp?.data?.year,
      };
      let maekResult = await dispatch(vehicleModelMaster(para));
      if (maekResult) {
        let para = {
          page: 1,
          limit: 1000,
          vehicleMakeId: resp?.data?.make?._id,
          vehicleModelId: resp?.data?.model?._id,
          year: resp?.data?.year,
        };
        await dispatch(vehicleTrimMaster(para));
      }

      if (resp) {
        setValue("year", resp?.data?.year);
        setValue(
          "vehicleMakeId",
          JSON.stringify({
            id: resp?.data?.make?._id,
            name: resp?.data?.make?.name,
          })
        );
        setValue(
          "vehicleModelId",
          JSON.stringify({
            id: resp?.data?.model?._id,
            name: resp?.data?.model?.name,
          })
        );

        setYearValue(resp?.data?.year);
        setMakeValue(
          JSON.stringify({
            id: resp?.data?.make?._id,
            name: resp?.data?.make?.name,
          })
        );
        setModelValue(
          JSON.stringify({
            id: resp?.data?.model?._id,
            name: resp?.data?.model?.name,
          })
        );
        setValue(
          "vehicleModelTrimId",
          JSON.stringify({
            id: resp?.data?.trim?._id,
            name: resp?.data?.trim?.name,
            description: resp?.data?.trim?.description,
          })
        );
        setTrimValue(
          JSON.stringify({
            id: resp?.data?.trim?._id,
            name: resp?.data?.trim?.name,
            description: resp?.data?.trim?.description,
          })
        );
      }
    } else if (getValues()?.vin && getValues()?.vin.length != 17) {
      setError("vin", {
        message: "Please enter valid VIN",
      });
      // setVINError("Please Enter Valid VIN");
    } else {
      setError("vin", {
        message: "Please enter valid VIN",
      });
      // setVINError("Please Enter Valid VIN");
    }
    setLoader(false);
  };

  const handleDecodeLicence = async (value) => {
    if (!provinceValue) {
      setError("province", {
        message: "Please select province",
      });
      return toast.info("Please select province");
    }
    if (provinceValue && debouncedLicenceValue) {
      setLoader(true);
      const resp = await dispatch(
        decodeLicence({
          licenceNumber: debouncedLicenceValue,
          provinceCode: provinceValue,
        })
      );
      // setModelSelectLoader(true);
      if (resp?.status) {
        setValue("vin", resp?.data?.vin);
        handleDecodeVIN();
      } else {
        setLoader(false);
      }
    }
  };
  useEffect(() => {
    if (config?.isLicensePlateDecodeEnabled && debouncedLicenceValue) {
      // Replace with your fetch function
      if (decode?.licence) handleDecodeLicence(debouncedLicenceValue);
    }
  }, [debouncedLicenceValue]);

  useEffect(() => {
    if (debouncedVINValue) {
      // Replace with your fetch function
      if (decode?.vin) handleDecodeVIN();
    }
  }, [debouncedVINValue]);
  const onSubmitVehicle = async (values) => {
    if (values?.vin && values?.vin !== "") {
      if (checkValidVIN(values?.vin)) {
        clearErrors("vin");
      } else {
        return setError("vin", { message: "Please enter valid VIN" });
      }
    }

    if (values?.province) {
      values.provinceId = values?.province;
      values.provinceObject = selectedProvinceObject;
      delete values.province;
    }

    if (values?.licenseNumber && values?.licenseNumber.trim() == "") {
      setError("licenseNumber", {
        message: "Licence Plate Number is required",
      });
      setValue("licenseNumber", "");
      return;
    }
    values.vehicleMakeId = JSON.parse(values.vehicleMakeId);
    values.vehicleModelId = JSON.parse(values.vehicleModelId);
    if (values.vehicleModelTrimId)
      values.vehicleModelTrimId = JSON.parse(values.vehicleModelTrimId);

    if (!values.vehicleModelTrimId) {
      delete values["vehicleModelTrimId"];
    }

    if (editVehicle?.edit) {
      let allVehicles = [...vehicles];
      if (allVehicles[editVehicle?.editIndex]?.vehcileId) {
        values.vehcileId = allVehicles[editVehicle?.editIndex]?.vehcileId;
      }
      allVehicles[editVehicle?.editIndex] = values;
      setVehicles(allVehicles);
      setEditVehicle({ edit: false, editIndex: "" });
    } else {
      setVehicles([...vehicles, values]);
    }
    handleClose();
    reset();
    setMakeValue("");
    setYearValue("");
    setModelValue("");
    setTrimValue("");
    setTrimEngineValue("");
    setStep(1);
    setDecode({
      vin: false,
      licence: false,
    });
  };
  const handleCloseModel = () => {
    reset();
    setMakeValue("");
    setYearValue("");
    setModelValue("");
    setTrimValue("");
    setTrimEngineValue("");
    handleClose();
    setStep(1);
    setDecode({
      vin: false,
      licence: false,
    });
    setProvinceValue("");
    setSelectedProvinceObject({});
  };
  // const handleDeleteVehicle = () => {
  //   const data = vehicles[editVehicle?.editIndex];
  //   const filteredData = vehicles?.filter((d) => d != data);
  //   // console.log("filteredData", filteredData);
  //   setVehicles(filteredData);
  //   handleClose();
  // };
  const onInvalid = (errors) => {
    const firstErrorField = Object.keys(errors)[0];
    if (firstErrorField) {
      const errorElement = document.querySelector(`[name=${firstErrorField}]`);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth" });
        setFocus(firstErrorField);
      }
    }
  };
  return (
    <Box>
      <Modal
        open={open}
        onClose={() => {
          handleCloseModel();
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            // width: "41vw",
            // width: "100%",
            // maxWidth: "50vw",
            // maxHeight: "90vh",
            // overflow: "hidden",
            // overflowY: "scroll",
          }}
          className="modelSize"
        >
          <AppBar color="secondary" variant="elevation" handleCloseModel>
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"} color={"white"}>
                {editVehicle?.edit ? "Edit" : "Add"} Vehicle
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={handleCloseModel}
                  style={{ cursor: "pointer", height: "32px" }}
                >
                  <CloseIcon style={{ color: "#0000008F", fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            gap={2}
            disableGutters
            // className="viewVehicleModal"
            style={{
              marginTop: "64px",
            }}
          >
            <form onSubmit={handleSubmit(onSubmitVehicle, onInvalid)}>
              <Grid
                container
                spacing={2}
                style={{
                  scrollbarColor: "blue",
                  scrollbarWidth: "thin",
                  overflow: "auto",
                  marginBottom: "24px",
                  maxHeight: "60vh",
                  padding: "24px",
                  paddingBottom: 0,
                }}
              >
                {" "}
                <Grid item xs={12} md={6} xl={6}>
                  <FormControl fullWidth>
                    <Typography
                      variant="body1"
                      className="label-500"
                      style={{ fontWeight: 400 }}
                    >
                      Province
                      {config?.isLicensePlateRequired && (
                        <span style={{ color: "#D32F2F" }}> *</span>
                      )}
                    </Typography>
                    {editVehicle?.edit &&
                    provinceLoader &&
                    provinceValue != null ? (
                      <Skeleton
                        animation="wave"
                        variant="text"
                        sx={{ fontSize: "3.5rem" }}
                        className="skelton"
                      ></Skeleton>
                    ) : (
                      <Controller
                        name="province"
                        control={control}
                        rules={{
                          required: config?.isLicensePlateRequired
                            ? "Province is required"
                            : "",
                        }}
                        render={({ field }) => (
                          <Select
                            fullWidth
                            value={provinceValue || ""}
                            // disabled={yearValue !== "" ? false : true}

                            error={!!errors.province}
                            helperText={
                              errors.province ? errors.province.message : ""
                            }
                            MenuProps={{
                              style: { maxHeight: "400px" },
                            }}
                            {...field}
                            onChange={(event) => {
                              console.log("🚀 ~ event:", event.target.value);
                              setProvinceValue(event.target.value);
                              setSelectedProvinceObject(
                                provinces?.find(
                                  (d) => d?.provinceCode == event.target.value
                                )
                              );
                              field.onChange(event);
                              clearErrors("province");
                              // let licenseNumber = getValues("licenseNumber");
                              // licenseNumber = licenseNumber?.replace(/\s/g, "");

                              // setValue(
                              //   "licenseNumber",

                              //   formatLicenceNumber(
                              //     licenseNumber,
                              //     event.target.value
                              //   )
                              // );
                            }}
                          >
                            {Array.isArray(provinces) &&
                              provinces?.map((m) => {
                                return (
                                  <MenuItem
                                    value={m?.provinceCode}
                                    key={m?.provinceCode}
                                  >
                                    {m?.province}
                                  </MenuItem>
                                );
                              })}
                            {provinceLoader && (
                              <MenuItem disabled>
                                <CircularProgress />
                              </MenuItem>
                            )}
                          </Select>
                        )}
                      />
                    )}
                    {errors.province && (
                      <FormHelperText error>
                        {errors.province.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                  <Typography
                    variant="body1"
                    className="label-500"
                    style={{ fontWeight: 400 }}
                  >
                    Licence Plate Number{" "}
                    {config?.isLicensePlateRequired && (
                      <span style={{ color: "#D32F2F" }}> *</span>
                    )}
                  </Typography>

                  <Controller
                    name="licenseNumber"
                    control={control}
                    rules={{
                      required: config?.isLicensePlateRequired
                        ? "Licence Plate Number is required"
                        : "",

                      // pattern: {
                      //   value: licensePlateRegex[provinceValue],
                      //   message: `Invalid Licence Number.Format:${provinceLicensePlatesExample[provinceValue]}`,
                      // },
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        // required
                        id="outlined-basic"
                        variant="outlined"
                        error={!!errors.licenseNumber}
                        helperText={
                          errors.licenseNumber
                            ? errors.licenseNumber.message
                            : ""
                        }
                        // inputProps={{
                        //   maxLength: getLicenceLength(provinceValue), // Limits input length to 17 characters
                        // }}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          setValue(
                            "licenseNumber",
                            e.target.value?.toUpperCase()
                          );
                          setDecode({ ...decode, licence: true });
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {" "}
                              {/* {licenseLoading ? (
                                  <CircularProgress />
                                ) : ( */}
                              {config?.isLicensePlateDecodeEnabled &&
                                getValues("licenseNumber") &&
                                getValues("licenseNumber") !== "" && (
                                  <Button onClick={handleDecodeLicence}>
                                    Decode
                                  </Button>
                                )}
                              {/* // )} */}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  {/* <TextField
                          id="outlined-basic"
                          variant="outlined"
                          fullWidth
                        /> */}
                </Grid>
                <Divider
                  textAlign="center"
                  style={{
                    width: "100%",
                    paddingLeft: "24px",
                    marginTop: "16px",
                  }}
                >
                  {step == 1 ? "or" : "and"}
                </Divider>
                <Grid item xs={12} md={12} xl={12}>
                  <Typography variant="body1" className="label-500">
                    VIN{" "}
                    {step == 1 && <span style={{ color: "#D32F2F" }}>*</span>}
                  </Typography>
                  <Controller
                    name="vin"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        // required
                        inputProps={{
                          maxLength: 17, // Limits input length to 17 characters
                        }}
                        id="outlined-basic"
                        variant="outlined"
                        error={!!errors.vin}
                        helperText={errors.vin ? errors.vin.message : ""}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          setDecode({ ...decode, vin: true });

                          setValue("vin", e.target.value?.toUpperCase());
                          if (e.target.value !== "") {
                            const regex = checkValidVIN(e.target.value);

                            if (regex) {
                              clearErrors("vin");
                              handleDecodeVIN();
                            } else {
                              setError("vin", {
                                message: "Please enter valid VIN",
                              });
                            }
                          } else {
                            clearErrors("vin");
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                <Divider
                  textAlign="center"
                  style={{
                    width: "100%",
                    paddingLeft: "24px",
                    marginTop: "16px",
                  }}
                >
                  or
                </Divider>
                {step == 1 && (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    xl={12}
                    justifyContent={"center"}
                    display={"flex"}
                  >
                    <Button variant="outlined" onClick={() => setStep(2)}>
                      Enter details manually
                    </Button>
                  </Grid>
                )}
                {step == 2 && (
                  <>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <Typography
                          variant="body1"
                          className="label-500"
                          style={{ fontWeight: 400 }}
                        >
                          Year <span style={{ color: "#D32F2F" }}> *</span>
                        </Typography>
                        <Controller
                          defaultValue=""
                          name="year"
                          control={control}
                          rules={{
                            required: "Year is required",
                          }}
                          render={({ field }) => (
                            <Select
                              fullWidth
                              value={yearValue}
                              onChange={(event) => {
                                field.onChange(event);

                                setYearValue(event.target.value);
                                setMakeValue(null);
                                setModelValue(null);
                                setTrimValue(null);
                                setMake([]);
                                setModel([]);
                                setTrim([]);
                                fetchVehicleMake({
                                  page: makeValuePage,
                                  limit: 500,
                                  year: event.target.value,
                                });
                              }}
                              error={!!errors.year}
                              helperText={
                                errors.year ? errors.year.message : ""
                              }
                              MenuProps={{
                                style: { maxHeight: "400px" },
                              }}
                              // {...field}
                            >
                              {Array.isArray(year) &&
                                year?.map((y) => {
                                  return (
                                    <MenuItem value={y} key={y}>
                                      {y}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <Typography
                          variant="body1"
                          className="label-500"
                          style={{ fontWeight: 400 }}
                        >
                          Make <span style={{ color: "#D32F2F" }}> *</span>
                        </Typography>
                        {editVehicle?.edit &&
                        makeLoader &&
                        makeValue != null ? (
                          <Skeleton
                            animation="wave"
                            variant="text"
                            sx={{ fontSize: "3.5rem" }}
                            className="skelton"
                          ></Skeleton>
                        ) : (
                          <Controller
                            name="vehicleMakeId"
                            defaultValue={""}
                            control={control}
                            rules={{
                              required: "Make is required",
                            }}
                            render={({ field }) => (
                              <Select
                                fullWidth
                                value={makeValue ? makeValue : ""}
                                disabled={yearValue !== "" ? false : true}
                                onChange={(event) => {
                                  field.onChange(event);

                                  setMakeValue(event.target.value);
                                  setModelValue(null);
                                  setModel([]);
                                  setTrim([]);
                                  setTrimValue(null);

                                  fetchVehicleModel({
                                    page: modelValuePage,
                                    limit: 1000,
                                    vehicleMakeId: JSON.parse(
                                      event.target.value
                                    )?.id,
                                    year: yearValue,
                                  });
                                }}
                                error={!!errors.vehicleMakeId}
                                helperText={
                                  errors.vehicleMakeId
                                    ? errors.vehicleMakeId.message
                                    : ""
                                }
                                MenuProps={{
                                  style: { maxHeight: "400px" },
                                }}
                              >
                                {Array.isArray(make) &&
                                  make?.map((m) => {
                                    return (
                                      <MenuItem
                                        value={JSON.stringify({
                                          id: m?._id,
                                          name: m?.name,
                                        })}
                                        key={m?._id}
                                      >
                                        {m?.name}
                                      </MenuItem>
                                    );
                                  })}
                                {makeLoader && (
                                  <MenuItem disabled>
                                    <CircularProgress />
                                  </MenuItem>
                                )}
                              </Select>
                            )}
                          />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <Typography
                          variant="body1"
                          className="label-500"
                          style={{ fontWeight: 400 }}
                        >
                          Model <span style={{ color: "#D32F2F" }}> *</span>
                        </Typography>
                        {editVehicle?.edit &&
                        modelLoader &&
                        modelValue != null ? (
                          <Skeleton
                            animation="wave"
                            variant="text"
                            sx={{ fontSize: "3.5rem" }}
                            className="skelton"
                          ></Skeleton>
                        ) : (
                          <Controller
                            name="vehicleModelId"
                            control={control}
                            rules={{
                              required: "Model is required",
                            }}
                            render={({ field, fieldState }) => (
                              <Select
                                fullWidth
                                value={modelValue}
                                disabled={
                                  yearValue !== "" && makeValue !== ""
                                    ? false
                                    : true
                                }
                                onChange={(event) => {
                                  field.onChange(event);
                                  setModelValue(event.target.value);
                                  setTrimValue(null);
                                  setValue("vehicleModelTrimId", null);
                                  setTrim([]);

                                  fetchVehicleTrim({
                                    page: trimValuePage,
                                    limit: 1000,
                                    vehicleMakeId: JSON.parse(makeValue).id,
                                    vehicleModelId: JSON.parse(
                                      event.target.value
                                    ).id,
                                    year: yearValue,
                                  });
                                }}
                                error={!!errors.vehicleModelId}
                                helperText={
                                  errors.vehicleModelId
                                    ? errors.vehicleModelId.message
                                    : ""
                                }
                                MenuProps={{
                                  style: { maxHeight: "400px" },
                                }}
                                // {...field}
                              >
                                {Array.isArray(model) &&
                                  model?.map((m) => {
                                    return (
                                      <MenuItem
                                        value={JSON.stringify({
                                          id: m?._id,
                                          name: m?.name,
                                        })}
                                        key={m?._id}
                                      >
                                        {m?.name}
                                      </MenuItem>
                                    );
                                  })}

                                {modelLoader && (
                                  <MenuItem disabled>
                                    <CircularProgress />
                                  </MenuItem>
                                )}
                              </Select>
                            )}
                          />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <Typography
                          variant="body1"
                          className="label-500"
                          style={{ fontWeight: 400 }}
                        >
                          Trim
                          {/* <span style={{ color: "#D32F2F" }}> *</span> */}
                        </Typography>
                        {editVehicle?.edit &&
                        trimLoader &&
                        trimValue != null ? (
                          <Skeleton
                            animation="wave"
                            variant="text"
                            sx={{ fontSize: "3.5rem" }}
                            className="skelton"
                          ></Skeleton>
                        ) : (
                          <Controller
                            name="vehicleModelTrimId"
                            control={control}
                            // rules={{
                            //   required: "Make is required",
                            // }}
                            render={({ field, fieldState }) => (
                              <Select
                                fullWidth
                                disabled={
                                  yearValue && makeValue && modelValue
                                    ? false
                                    : true
                                }
                                value={trimValue}
                                onChange={(event) => {
                                  field.onChange(event);

                                  setTrimValue(event.target.value);
                                  setTrimEngineValue(
                                    trim.find(
                                      (d) =>
                                        d?._id ==
                                        JSON.parse(event.target.value)?.id
                                    )
                                  );
                                }}
                                error={!!errors.vehicleModelTrimId}
                                helperText={
                                  errors.vehicleModelTrimId
                                    ? errors.vehicleModelTrimId.message
                                    : ""
                                }
                                MenuProps={{
                                  style: { maxHeight: "400px" },
                                }}
                                // {...field}
                              >
                                {Array.isArray(trim) &&
                                  trim?.map((m) => {
                                    return (
                                      <MenuItem
                                        value={JSON.stringify({
                                          id: m?._id,
                                          name: m?.name,
                                          description: m?.description,
                                        })}
                                        key={m?._id}
                                      >
                                        <div>
                                          <div style={{ fontWeight: 500 }}>
                                            {m?.name}
                                          </div>
                                          <div>{m?.description}</div>
                                        </div>
                                      </MenuItem>
                                    );
                                  })}
                                {trimLoader && (
                                  <MenuItem disabled>
                                    <CircularProgress />
                                  </MenuItem>
                                )}
                              </Select>
                            )}
                          />
                        )}
                      </FormControl>
                    </Grid>
                  </>
                )}
                {trimValue &&
                  (trimEngineValue?.bodyType ||
                    trimEngineValue?.engineDriveType ||
                    trimEngineValue?.engineFuelType ||
                    trimEngineValue?.engineTransmission ||
                    trimEngineValue?.engineType) && (
                    <>
                      <Divider
                        textAlign="center"
                        style={{
                          width: "100%",
                          paddingLeft: "24px",
                          marginTop: "16px",
                        }}
                      ></Divider>
                      <Grid
                        container
                        xs={12}
                        md={12}
                        xl={12}
                        spacing={3}
                        style={{
                          // width: "100%",
                          marginLeft: "24px",
                          marginTop: "24px",
                          padding: 0,
                          background: "#F5F5F7",
                        }}
                        borderRadius={2}
                      >
                        {trimEngineValue?.engineType && (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ padding: "16px 0px 0px 16px" }}
                          >
                            <Typography fontWeight={500} fontSize={"16px"}>
                              Engine Type
                            </Typography>
                            <Typography
                              fontWeight={400}
                              fontSize={"16px"}
                              color={"rgba(0, 0, 0, 0.6)"}
                            >
                              {trimEngineValue?.engineType}
                            </Typography>
                          </Grid>
                        )}
                        {trimEngineValue?.engineFuelType && (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ padding: "16px 0px 0px 16px" }}
                          >
                            <Typography fontWeight={500} fontSize={"16px"}>
                              Engine Fuel Type
                            </Typography>
                            <Typography
                              fontWeight={400}
                              fontSize={"16px"}
                              color={"rgba(0, 0, 0, 0.6)"}
                            >
                              {trimEngineValue?.engineFuelType}
                            </Typography>
                          </Grid>
                        )}
                        {trimEngineValue?.engineDriveType && (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ padding: "16px 0px 0px 16px" }}
                          >
                            <Typography fontWeight={500} fontSize={"16px"}>
                              Engine Drive Type
                            </Typography>
                            <Typography
                              fontWeight={400}
                              fontSize={"16px"}
                              color={"rgba(0, 0, 0, 0.6)"}
                            >
                              {trimEngineValue?.engineDriveType}
                            </Typography>
                          </Grid>
                        )}
                        {trimEngineValue?.engineTransmission && (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ padding: "16px 0px 0px 16px" }}
                          >
                            <Typography fontWeight={500} fontSize={"16px"}>
                              Engine Transmission
                            </Typography>
                            <Typography
                              fontWeight={400}
                              fontSize={"16px"}
                              color={"rgba(0, 0, 0, 0.6)"}
                            >
                              {trimEngineValue?.engineTransmission}
                            </Typography>
                          </Grid>
                        )}
                        {trimEngineValue?.bodyType && (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ padding: "16px 0px 16px 16px" }}
                          >
                            <Typography fontWeight={500} fontSize={"16px"}>
                              Body Type
                            </Typography>
                            <Typography
                              fontWeight={400}
                              fontSize={"16px"}
                              color={"rgba(0, 0, 0, 0.6)"}
                            >
                              {trimEngineValue?.bodyType}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
              </Grid>

              <Grid
                item
                container
                justifyContent={"space-between"}
                padding={"24px"}
                paddingTop={"0px"}
              >
                <Grid item>
                  <Stack spacing={2} direction="row">
                    {editVehicle?.edit && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          handleCloseModel();
                          handleDeleteVehicle(editVehicle?.editIndex);
                          // setDeleteVehicleId(editVehicle.id);
                          // setOpenVehicleDelete(true);
                        }}
                        // disabled={vehicles?.length == 1}
                        size="large"
                      >
                        DELETE
                      </Button>
                    )}
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleCloseModel}
                      size="large"
                    >
                      Cancel
                    </Button>
                    {step == 1 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        // onClick={handleCloseModel}
                        size="large"
                        onClick={(e) => {
                          e.preventDefault();
                          setStep(2);
                        }}
                      >
                        Next
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        // onClick={handleCloseModel}
                        size="large"
                        type="submit"
                      >
                        Save
                      </Button>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Modal>
      <Dialog open={loader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default VehicleModel;
