import { useTheme } from "@emotion/react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  styled,
  Switch,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../App";
import {
  configDetails,
  updateBufferConfigApi,
} from "../../../redux/Account/action";
import { copyToClipboard } from "../../../utility/helpers/commonFunction";
import { default as SuccessMessageModal } from "../../Common/SuccessMessage";

const OnlineBooking = () => {
  const theme = useTheme();
  const WEB_ENDPOINT = process.env.REACT_APP_ONLINE_WEB_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpenSideBar } = useContext(Context);
  const [showLoader, setShowLoader] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isBufferActive, setIsBufferActive] = useState(false);
  const [registeredUrl, setRegisteredUrl] = useState("");
  const [storeBufferValues, setStoreBufferValues] = useState({
    bufferDays: 1,
    isBufferDaysEnabled: false,
  });
  const [bufferDays, setBufferDays] = useState(1);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const config = useSelector((state) => state.account.configDetailData);

  const fetchConfigApi = async () => {
    setShowLoader(true);
    const resp = await dispatch(configDetails());
    if (resp._id) {
      setRegisteredUrl(resp.registeredUrl ?? "");
    }
    if (resp.bufferDays) {
      setBufferDays(resp.bufferDays);
      setIsBufferActive(resp.isBufferDaysEnabled);
      setStoreBufferValues({
        bufferDays: resp.bufferDays,
        isBufferDaysEnabled: resp.isBufferDaysEnabled,
      });
    } else {
      setBufferDays(1);
      setIsBufferActive(false);
      setStoreBufferValues({
        bufferDays: 1,
        isBufferDaysEnabled: false,
      });
    }
    setShowLoader(false);
  };

  useEffect(() => {
    fetchConfigApi();
  }, []);

  // Handler for enabling/disabling the buffer
  const handleToggleBuffer = () => {
    setIsBufferActive((prev) => !prev);
  };

  // Handler for changing the buffer days
  const handleBufferChange = (action) => {
    if (action === "increment") {
      setBufferDays((prev) => prev + 1);
    } else if (action === "decrement" && bufferDays > 1) {
      setBufferDays((prev) => prev - 1);
    }
  };

  const saveChanges = async () => {
    setShowLoader(true);
    let payload = {
      isBufferDaysEnabled: isBufferActive,
      bufferDays,
    };
    const resp = await dispatch(updateBufferConfigApi(payload));
    if (resp) {
      fetchConfigApi();
      setSuccessMessage("Changes Saved");
      setOpenSuccessModal(true);
    }
  };

  const PauseAppointmentToogle = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
      defaultChecked={isBufferActive}
      onChange={(e) => setIsBufferActive(e.target.checked)}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#34C759",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor:
        theme.palette.mode === "light"
          ? "rgba(120, 120, 128, 0.16)"
          : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  return (
    <Box>
      {/* Online Booking Grid  */}
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ boxShadow: 3, bgcolor: "white" }}
        justifyContent={"start"}
        borderTop={"none"}
        padding={"24px"}
        borderRadius={"10px"}
        marginTop={"32px"}
        rowGap={2}
      >
        <Grid item lg={3.8} md={12} sm={12} xs={12} xl={5}>
          <Typography variant="h5" sx={{ fontWeight: 400, fontSize: "24px" }}>
            Online Booking
          </Typography>
        </Grid>
        <Grid item container xs={12} lg={8.2} xl={7}>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            xl={12}
            justifyContent={{ xs: "start", sm: "end" }}
            columnGap={3}
            rowGap={1}
          >
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => {
                  window.open(`${WEB_ENDPOINT}${registeredUrl}`, "_blank");
                }}
              >
                View ONLINE BOOKING LINK
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => {
                  copyToClipboard(`${WEB_ENDPOINT}${registeredUrl}`);
                }}
              >
                COPY LINK TO SHARE
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Buffer Grid */}
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ boxShadow: 3, bgcolor: "white" }}
        justifyContent={"space-between"}
        borderTop={"none"}
        padding={"24px"}
        borderRadius={"10px"}
        marginTop={"32px"}
        rowGap={3}
      >
        <Grid item xs={12} md={isOpenSideBar ? 12 : 6.5} lg={4.5}>
          <Typography variant="h5" sx={{ fontWeight: 400, fontSize: "24px" }}>
            Buffer
          </Typography>
          <Typography
            mt={1.5}
            sx={{ fontWeight: 400, fontSize: "16px", lineHeight: "24px" }}
          >
            Add a buffer to avoid customers booking last minute, and add at
            least 1 or more days between the booking date and the appointment
            date.
          </Typography>
          <Typography
            mt={3}
            sx={{ fontWeight: 400, fontSize: "16px", lineHeight: "24px" }}
          >
            Example: Setting a buffer for 1 day will prevent customers from
            booking on a Sunday for an appointment on Monday.
          </Typography>
        </Grid>

        <Grid item xs={12} md={isOpenSideBar ? 12 : 5.255} lg={7.255}>
          <Grid container item alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Stack flexDirection={"row"} alignItems={"center"} gap={3}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: isBufferActive ? "#000" : "#80808082",
                  }}
                >
                  Select Days
                </Typography>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  gap={1}
                  sx={{
                    border: "1px solid #0000003B",
                    borderRadius: "6px",
                    width: "162px",
                    height: "56px",
                  }}
                >
                  <IconButton
                    onClick={() => handleBufferChange("decrement")}
                    disabled={!isBufferActive}
                  >
                    <RemoveIcon
                      style={{ color: isBufferActive ? "#006CDD" : "" }}
                    />
                  </IconButton>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      letterSpacing: "0.15px",
                      color: isBufferActive ? "#000" : "#80808082",
                    }}
                  >
                    {bufferDays} Day{bufferDays > 1 ? "s" : ""}
                  </Typography>
                  <IconButton
                    onClick={() => handleBufferChange("increment")}
                    disabled={!isBufferActive}
                  >
                    <AddIcon
                      style={{ color: isBufferActive ? "#006CDD" : "" }}
                    />
                  </IconButton>
                </Box>
              </Stack>
            </Grid>

            <Grid
              item
              xs={12}
              columnGap={1.5}
              rowGap={1.5}
              sx={{ marginTop: "4px" }}
            >
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                gap={1.5}
                sx={{ marginLeft: "76.5px" }}
              >
                <Button
                  disabled={!isBufferActive}
                  onClick={() => setBufferDays(3)}
                  sx={{
                    color: isBufferActive ? "#000" : "#000000DE",
                    background: isBufferActive ? "#EBEBEB" : "#EBEBEB",
                    padding: "4px 8px!important",
                    borderRadius: "8px",
                    cursor: "pointer",
                    textTransform: "lowercase",
                  }}
                >
                  3 days
                </Button>

                <Button
                  disabled={!isBufferActive}
                  onClick={() => setBufferDays(7)}
                  sx={{
                    color: isBufferActive ? "#000" : "#000000DE",
                    background: isBufferActive ? "#EBEBEB" : "#EBEBEB",
                    padding: "4px 8px!important",
                    borderRadius: "8px",
                    cursor: "pointer",
                    textTransform: "lowercase",
                  }}
                >
                  7 days
                </Button>

                <Button
                  disabled={!isBufferActive}
                  onClick={() => setBufferDays(10)}
                  sx={{
                    color: isBufferActive ? "#000" : "#000000DE",
                    background: isBufferActive ? "#EBEBEB" : "#EBEBEB",
                    padding: "4px 8px!important",
                    borderRadius: "8px",
                    cursor: "pointer",
                    textTransform: "lowercase",
                  }}
                >
                  10 days
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        {/* Save and Cancel Buttons */}
        <Grid
          item
          container
          justifyContent="space-between"
          xs={12}
          md={12}
          rowGap={2}
        >
          <Grid item xs={12} sm={3} md={3}>
            <Stack flexDirection={"row"} alignItems={"center"}>
              <PauseAppointmentToogle />
              <Typography ml={1}>
                {isBufferActive ? "Active" : "Inactive"}
              </Typography>
            </Stack>
          </Grid>

          <Grid
            item
            container
            spacing={2}
            xs={12}
            sm={!isOpenSideBar ? 9 : 12}
            md={9}
            justifyContent={"flex-end"}
          >
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setBufferDays(storeBufferValues.bufferDays);
                  setIsBufferActive(storeBufferValues.isBufferDaysEnabled);
                }}
              >
                Cancel Changes
              </Button>
            </Grid>

            <Grid item>
              <Button variant="contained" color="primary" onClick={saveChanges}>
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Loader and Success Message */}
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
    </Box>
  );
};

export default OnlineBooking;
