import { useTheme } from "@emotion/react";
import {
  Badge,
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  requestAppointmentDetails,
  requestAppointmentList,
  requestEstimateDetails,
  requestEstimateList,
} from "../../redux/Dashboard/action";
import AppointmentRequestModal from "../modal/AppointmentrequestModal";
import EstimateRequestModal from "../modal/EstimateRequestModal";
import "./Request.css";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Requests = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [value, setValue] = React.useState(
    pathname.split("/")[2] == "appointments" ? 0 : 1
  );
  const [data, setData] = useState([]);
  const [estimate, setEstimate] = useState("");
  const [appointment, setAppointment] = useState("");
  const [id, setId] = useState("");
  const [appointmentId, setAppointmentId] = useState("");
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const handleClose = () => {
    requestestimate();
    setOpen(false);
  };
  const handleCancel = () => {
    // requestestimate();
    setOpen(false);
  };
  const handleOpen = () => setOpen(true);
  const handleClose1 = () => {
    requestappointment();
    setOpen1(false);
  };
  const handleCancelAppointment = () => {
    setOpen1(false);
  };
  const handleOpen1 = () => setOpen1(true);
  const [showLoader, setShowLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const index = [0, 1];
  const limit = 20;
  const requestestimate = async (currentPage) => {
    setLoading(true);
    setShowLoader(true);
    const resp = await dispatch(
      requestEstimateList(limit, currentPage ? currentPage : page)
    );
    if (resp) {
      setTotalPages(resp.totalPages);
      setShowLoader(false);
      setData([...resp.data]);
      setLoading(false);
    }
  };

  const requestappointment = async (currentPage) => {
    setLoading(true);
    setShowLoader(true);
    const resp = await dispatch(
      requestAppointmentList(limit, currentPage ? currentPage : page)
    );
    if (resp) {
      setTotalPages(resp.totalPages);
      setShowLoader(false);
      setData([...resp.data]);
      setLoading(false);
    }
  };

  const nameArray = window.location.pathname.split("/");
  const name = nameArray[nameArray.length - 1];

  useEffect(() => {
    if (name === "estimates") {
      requestestimate();
    } else {
      requestappointment();
    }
    return () => {
      setData([]);
    };
  }, []);

  const handleChange = async (event, newValue) => {
    setLoading(true);
    setValue(newValue);
    if (newValue === 0) {
      setData([]); // Clear existing data
      await requestappointment();
      navigate("/request/appointments");
    }
    if (newValue === 1) {
      setData([]); // Clear existing data
      await requestestimate();
      navigate("/request/estimates");
    }
    setLoading(false);
  };
  useEffect(() => {
    if (id) {
      estimateDetails();
    }
    if (appointmentId) {
      AppointmentDetails();
    }
  }, [id, appointmentId]);

  const estimateDetails = async () => {
    if (id) {
      setShowLoader(true);
      const data = await dispatch(requestEstimateDetails(id));
      if (data) {
        setShowLoader(false);
        setEstimate(data);
      }
    }
  };
  const AppointmentDetails = async () => {
    if (appointmentId) {
      setShowLoader(true);
      const data = await dispatch(requestAppointmentDetails(appointmentId));
      if (data) {
        setShowLoader(false);
        setAppointment(data);
      }
    }
  };

  const handleScroll = () => {
    const isAtBottom =
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight;

    if (isAtBottom && !loading && page < totalPages) {
      // params.page = page + 1;
      setPage(page + 1);
      if (name == "estimates") {
        requestestimate(page + 1);
      } else {
        requestappointment(page + 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, page]);

  return (
    <Box
      padding={"32px 24px"}
      style={{
        background: "rgba(250, 251, 255, 1)",
      }}
    >
      <Grid container rowGap={2}>
        <Grid container item justifyContent={"space-between"}>
          <Grid item lg={9}>
            <Typography variant="h4" sx={{ fontWeight: 400 }}>
              Requests{" "}
            </Typography>
          </Grid>
        </Grid>
        <>
          <Grid
            container
            item
            // gap={2}
            margin={"12px 0"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item container alignItems={"center"}>
              <Box sx={{ width: "100%" }}>
                <Box>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label={
                        <span>
                          APPOINTMENT REQUESTS{" "}
                          {name == "appointments" && (
                            <Badge
                              badgeContent={
                                name == "appointments" && data?.length
                              }
                              color="error"
                              style={{
                                paddingLeft:
                                  name == "appointments" &&
                                  data?.length &&
                                  "15px",
                              }}
                            />
                          )}
                        </span>
                      }
                      {...a11yProps(0)}
                      style={{ fontWeight: 500 }}
                    />
                    <Tab
                      label={
                        <span>
                          ESTIMATE REQUESTS{" "}
                          {name == "estimates" && (
                            <Badge
                              badgeContent={name == "estimates" && data?.length}
                              color="error"
                              style={{
                                paddingLeft:
                                  name == "estimates" && data?.length && "15px",
                              }}
                            />
                          )}
                        </span>
                      }
                      {...a11yProps(1)}
                      style={{ fontWeight: 500 }}
                    />
                  </Tabs>
                </Box>
                {index?.map((item) => (
                  <CustomTabPanel value={value} index={item} padding={"24px"}>
                    {!loading && data && data?.length > 0
                      ? data?.map((d) => {
                          return (
                            <Card
                              style={{
                                borderRadius: "4px",
                                borderLeft: "4px solid rgba(117, 219, 255, 1)",
                                borderTop: "none",
                                marginBottom: "8px",
                              }}
                              key={d?._id}
                            >
                              <Paper>
                                <CardHeader
                                  title={
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div>
                                        <Typography variant="h6">
                                          {name == "appointments"
                                            ? d?.customer?.firstName +
                                              " " +
                                              d?.customer?.lastName
                                            : name == "estimates"
                                            ? d?.customer?.fullName
                                            : ""}
                                        </Typography>
                                        <Typography
                                          variant="subtitle1"
                                          color={"rgba(0, 0, 0, 0.6)"}
                                        >
                                          {/* {d?.dropOffDateTime && moment(d?.dropOffDateTime).format("MMMM D, YYYY")} */}
                                          {name == "appointments"
                                            ? d?.dropOffDateTime &&
                                              moment(d?.dropOffDateTime).format(
                                                "MMMM D, YYYY"
                                              )
                                            : name == "estimates"
                                            ? d?.estimateRequestDate &&
                                              moment(
                                                d?.estimateRequestDate
                                              ).format("MMMM D, YYYY")
                                            : ""}
                                        </Typography>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        {name == "estimates" ? (
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                              setId(d?._id);
                                              handleOpen();
                                            }}
                                          >
                                            {" "}
                                            VIEW{" "}
                                          </Button>
                                        ) : (
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                              setAppointmentId(d?._id);
                                              handleOpen1();
                                            }}
                                          >
                                            {" "}
                                            VIEW{" "}
                                          </Button>
                                        )}
                                      </div>
                                    </div>
                                  }
                                ></CardHeader>
                              </Paper>
                            </Card>
                          );
                        })
                      : "No Data Found"}
                  </CustomTabPanel>
                ))}
              </Box>
            </Grid>
          </Grid>
        </>
      </Grid>
      <EstimateRequestModal
        open={open}
        handleClose={handleClose}
        estimateData={estimate}
        id={id}
        handleCancel={handleCancel}
      />
      <AppointmentRequestModal
        open={open1}
        handleClose={handleClose1}
        appointmentData={appointment}
        id={appointmentId}
        handleCancelAppointment={handleCancelAppointment}
        apiCall={requestappointment}
        fetchAppointmentDetails={AppointmentDetails}
      />
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Requests;
