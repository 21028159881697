import AddIcon from "@mui/icons-material/Add";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  alpha,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  styled,
  Switch,
  Tooltip,
  tooltipClasses,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { green } from "@mui/material/colors";
import dayjs from "dayjs";
import moment from "moment";
import "moment/locale/en-gb";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams, useLocation } from "react-router-dom";
import { appointmentDetails } from "../../redux/appointment/action";
import {
  calendarListing,
  getAppointmentCalendarPrefrences,
  getAvailablity,
  updateAvailablity,
} from "../../redux/Calendar/action";
import AppointmentModel from "../Appointment/AppointmentModel";
import ViewUpcomingModal from "../Appointment/UpcomingAppointment/ViewUpcomingModal";
import AddOdoModal from "../modal/AddOdoModal";
import "./Calendar.css";
import CustomCalendar from "./CustomCalendar";
import Sidebar from "./Sidebar";
import CalendarSettingModel from "./CalendarSettingModel";
import { addToHistory } from "../../components/history";
const localizer = momentLocalizer(moment);

//test
const borderColor = (status) => {
  if (status == "waiting_for_drop_off") return "8px solid #75DBFF";
  else if (status == "vehicle_dropped_off") return "8px solid #FFC30F";
  else if (status == "in_progress") return "8px solid #FF9E31";
  else if (status == "ready_for_pick_up") return "8px solid #5CE469";
  else if (status == "customer_paid") return "8px solid #00CD44";
  else return "8px solid #F44336";
};
const OtherSideBorderColor = (status) => {
  if (status == "waiting_for_drop_off") return "2px solid #75DBFF";
  else if (status == "vehicle_dropped_off") return "2px solid #FFC30F";
  else if (status == "in_progress") return "2px solid #FF9E31";
  else if (status == "ready_for_pick_up") return "2px solid #5CE469";
  else if (status == "customer_paid") return "2px solid #00CD44";
  else return "2px solid #F44336";
};
const backgroundColor = (status) => {
  if (status == "waiting_for_drop_off") return "#C1EFFF";
  else if (status == "vehicle_dropped_off") return "#FFF0C2";
  else if (status == "in_progress") return "#FFE6CB";
  else if (status == "ready_for_pick_up") return "#F0F0F0";
  else if (status == "customer_paid") return "#CEFFDE";
  else return "#FDE2E0";
};
const CustomMonthHeader = ({ label }) => (
  <div className="custom-month-header">
    <Typography variant="body1">{label}</Typography>
  </div>
);

const CustomWeekHeader = ({ label }) => {
  return (
    <div
      className="custom-week-header"
      // onClick={(e) => {
      //   console.log("eee", e.preventDefault());
      // }}
    >
      <p>{label.split(" ")[1]}</p>
      {/* <br/> */}
      <span style={{ fontSize: "22px" }}>{label.split(" ")[0]}</span>
      <div style={{ fontSize: "12px" }}>
        <Switch defaultChecked size="small" />
        Turn off availablity
      </div>
      {/* <br/> */}
    </div>
  );
};
const CustomDayHeader = ({
  props,
  availablity,
  currentDate,
  fetchAvailablityStatus,
  setShowLoader,
}) => {
  const dispatch = useDispatch();
  const weekday = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const date = new Date(props?.date).getDate();
  const day = weekday[new Date(props?.date).getDay()];
  const comingDate = moment(currentDate).format("YYYY-MM-DD");
  const [switchValue, setSwitchValue] = useState(
    availablity.filter(
      (d) => d.date == moment(currentDate).format("YYYY-MM-DD")
    )?.length == 0
      ? true
      : false
  );
  // const checked =
  //   availablity.filter((d) => d.date == comingDate)?.length == 0 ? false : true;
  const updateStatusAvailablity = async (payload) => {
    setShowLoader(true);
    const resp = await dispatch(updateAvailablity(payload));

    if (resp) {
      fetchAvailablityStatus();
    }
  };

  return (
    <div className="custom-day-header">
      <div>{day}</div>
      <div>
        <h3>{date}</h3>
      </div>
      <div style={{ fontSize: "12px" }}>
        <RedSwitch
          checked={
            availablity.filter(
              (d) => d.date == moment(currentDate).format("YYYY-MM-DD")
            )?.length == 0
              ? true
              : false
          }
          onChange={(e) => {
            updateStatusAvailablity({
              shopId: JSON.parse(localStorage.getItem("apex-saas_details"))
                ?._id,
              date: comingDate,
              isTurnOff: !e.target.checked,
            });
          }}
        />
        Appointment Requests :{switchValue ? "ON" : "OFF"}
        {/* {availablity.filter((d) => d.date == comingDate)?.length == 0
          ? "ON"
          : "OFF"} */}
      </div>
    </div>
  );
};

const RedSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: green[800],
    "&:hover": {
      backgroundColor: alpha(green[800], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: green[800],
  },
}));

function MyCalendar() {
  const { calendarView } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [view, setView] = useState(calendarView ?? "day");
  const [resources, setResources] = useState([]);
  const [calendarPreferences, setCalendarPreferences] = useState([
    "upcoming_appointments",
    "waiting_for_drop_off",
    "vehicle_dropped_off",
    "in_progress",
    "ready_for_pick_up",
  ]);
  const location = useLocation();
  const [date, setDate] = useState(new Date());
  const [sideBarWidth, setSideBarWidth] = useState(3);
  const [assignee, setAssignee] = useState([]);
  const [show, setShow] = React.useState(false);
  const [selectId, setSelectId] = React.useState("");
  const [updateStatus, setUpdateStatus] = React.useState("");
  const [appointmentDropOffODO, setAppointmentDropOffODo] = useState(null);
  const [appointmentCompletionOffODO, setAppointmentCompletionOffODo] =
    useState(null);

  const [customCalendarDates, setCustomCalendarDates] = useState({
    start: "",
    end: "",
  });
  const [calendarParams, setCalendarParams] = useState({
    startDate: moment(date).format("YYYY-MM-DD"),
    endDate: moment(date).format("YYYY-MM-DD"),
    assignee: [],
  });
  const [openAppointmentDetailModal, setOpenAppointmentDetailModal] =
    useState(false);
  const [appointmentDetailData, setAppointmentDetailData] = useState({});
  const [selectedAppointmentId, setSelectedAppointmentId] = useState("");
  const [events, setEvents] = useState([]);
  const [calendarDataApicallFirstTime, setCalendarDataApiCallFirstTime] =
    useState(false);
  const [upcomingAppointmentModel, setUpcomingAppointmentModel] =
    useState(false);
  const [dataLoader, setDataLoader] = useState(true);
  const [statusLoader, setStatusLoader] = useState(true);
  const [openCalendarSettings, setOpencalendarSettings] = useState(false);
  useEffect(() => {
    const currentDate = new Date(date);
    const currentDayOfWeek = currentDate.getDay();

    if (view == "3day") {
      const startDate = new Date(currentDate);
      startDate.setDate(currentDate.getDate());

      // Calculate the end date of the current week
      const endDate = new Date(currentDate);
      endDate.setDate(currentDate.getDate() + 2);

      fetchCalendarData({
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        assignee: [],
      });
    }
    if (view == "5day") {
      const startDate = new Date(currentDate);
      startDate.setDate(currentDate.getDate());

      // Calculate the end date of the current week
      const endDate = new Date(currentDate);
      endDate.setDate(currentDate.getDate() + 4);

      fetchCalendarData({
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        assignee: [],
      });
    }
  }, [view, date, resources, calendarPreferences]);

  const fetchCalendarDataonAppointmentStatusChange = () => {
    handleCloseAppointmentDetailModal();
    fetchCalendarData(calendarParams);
  };

  const handleViewChange = (newView) => {
    // console.log("newView", newView);
    setView(newView);
  };
  // console.log("view", view);

  const handleTodayClick = () => {
    // Calculate the start date for today and set it as the view's date
    // setView("day");
    setDate(new Date());
  };
  const handleNavigate = (action) => {
    let numberOfDays = "";
    let type = "";
    if (view == "day") {
      type = "day";
      numberOfDays = 1;
    }
    if (view == "3day") {
      type = "day";
      numberOfDays = 3;
    }
    if (view == "5day") {
      type = "day";
      numberOfDays = 5;
    }

    if (view == "month") {
      type = "month";
      numberOfDays = 1;
    }
    const newDate =
      action === "PREV"
        ? moment(date).subtract(numberOfDays, type)
        : moment(date).add(numberOfDays, type);

    setDate(newDate.toDate());
  };

  const handleCloseUpcomingAppointmentModal = () => {
    setUpcomingAppointmentModel(false);
    setSelectedAppointmentId("");
  };
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "transparent",
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[0],
      fontSize: 11,
    },
  }));
  const CustomEvent = ({ event }) => {
    return (
      <LightTooltip
        title={
          <Card
            className="customeCalendarCardEvent"
            style={{
              textAlign: "left",
              backgroundColor: backgroundColor(event?.status),

              borderLeft: borderColor(event?.status),

              position: "relative",
            }}
          >
            <Box sx={{ marginTop: "-12px" }}>
              {/* <p> */}
              <h3 style={{ marginBottom: "auto" }}>
                {event.title}
                {": "}
                {event?.customer?.firstName}
                {"  "}
                {event?.customer?.lastName}
              </h3>
              Drop Off:{moment(event.start).format("h:mm A")}
              <br />
              {event?.service}
            </Box>
          </Card>
        }
      >
        <Stack
          sx={{ margin: "2px 0 2px 0px" }}
          onClick={() => setSelectedAppointmentId(event?.appointmentId)}
        >
          <Typography style={{ fontWeight: "600", fontSize: "16px" }}>
            {event.title}
            {": "}
            {event?.customer?.firstName}
            {"  "}
            {event?.customer?.lastName}
          </Typography>
          <Typography style={{ fontWeight: "400", fontSize: "14px" }}>
            Drop Off:{moment(event.start).format("h:mm A")}
          </Typography>
          <Typography style={{ fontWeight: "400", fontSize: "14px" }}>
            {event?.service}
          </Typography>
        </Stack>
      </LightTooltip>
    );
  };
  useEffect(() => {
    if (resources.length > 0 && view != "3day" && view != "5day") {
      calendarParams.startDate = moment(new Date(date)).format("YYYY-MM-DD");
      calendarParams.endDate = moment(new Date(date)).format("YYYY-MM-DD");
      fetchCalendarData(calendarParams);
    }
    if (view != "month") fetchAvailablityStatus();
    else {
      setStatusLoader(false);
    }
  }, [resources, date, view, calendarPreferences]);

  useEffect(() => {
    fetchAppointmentsStatusList();
  }, []);
  const fetchCalendarData = async (calendarParams) => {
    if (calendarDataApicallFirstTime) {
      let payload = {
        ...calendarParams,
        calendarViewPreferences: [],
      };
      if (resources?.length == 0) {
        return;
      }
      if (view == "month") {
        // var date = new Date()
        var y = date.getFullYear();
        var m = date.getMonth();
        var startDate = new Date(y, m, 1);
        var endDate = new Date(y, m + 1, 0);
        payload.startDate = moment(startDate).format("YYYY-MM-DD");
        payload.endDate = moment(endDate).format("YYYY-MM-DD");
        payload.assignee = [];
      }
      resources?.map((assign) => {
        payload.assignee.push(assign?._id);
      });
      calendarPreferences?.map((preferance) => {
        payload.calendarViewPreferences.push(preferance);
      });
      setCalendarParams(payload);
      setDataLoader(true);
      const resp = await dispatch(calendarListing(payload));
      // console.log("resp", resp);
      let data = [];
      Array.isArray(resp) &&
        resp?.map((r) => {
          data.push({
            title: r?.data?.appointmentNumber,
            start: moment(r?.start).toDate(),
            end: moment(r?.end).toDate(),
            resourceId: r?.data?.assignee?._id,
            service:
              r?.data?.services?.length > 1
                ? `Total Services ${r?.data?.services?.length}`
                : r?.data?.services[0]?.name,
            customer: r?.data?.customer,
            appointmentId: r?.data?._id,
            status: r?.data?.status,
          });
        });
      // console.log("datadata", data);
      setEvents(data);

      setDataLoader(false);

      // if (view == "day" || view == "month") setShowLoader(false);

      // console.log("resp", resp);
    }
  };

  // const calendarData = useSelector((state) => state.calendar.calendarList);
  const fetchAvailablityStatus = async () => {
    setStatusLoader(true);
    await dispatch(getAvailablity());
    setStatusLoader(false);

    // if (view == "day" || view == "month") setShowLoader(false);
  };
  useEffect(() => {
    const loader = dataLoader || statusLoader;
    setShowLoader(loader);
  }, [dataLoader, statusLoader]);
  const availablity = useSelector((state) => state.calendar.availablity);
  // console.log("availablity", availablity);
  // console.log("events", events);

  const fetchAppointmentsStatusList = async () => {
    setShowLoader(true);

    await dispatch(getAppointmentCalendarPrefrences());

    setShowLoader(false);
    setCalendarDataApiCallFirstTime(true);
  };
  const appointmentsStatusList = useSelector(
    (state) => state.calendar.appointmentsStatus
  );

  useEffect(() => {
    if (Object.keys(appointmentsStatusList).length > 0) {
      const trueKeys = [];
      const dataObj = {
        ...appointmentsStatusList,
      }; // Access the data object

      for (let key in dataObj) {
        if (dataObj.hasOwnProperty(key) && dataObj[key] === true) {
          trueKeys.push(key);
        }
      }
      setCalendarPreferences(trueKeys);
    }
  }, [appointmentsStatusList]);

  //Fetch appointment details on click on calendar
  useEffect(() => {
    details();
  }, [selectedAppointmentId]);

  const details = async () => {
    if (selectedAppointmentId) {
      setShowLoader(true);
      const detailView = await dispatch(
        appointmentDetails(selectedAppointmentId)
      );

      let timeOfAppointment = dayjs(detailView?.dropOffDateTime).format(
        "YYYY-MM-DD"
      );

      setShowLoader(false);
      if (detailView) {
        setAppointmentDetailData(detailView);

        const currentDate = dayjs().format("YYYY-MM-DD"); // Format current date

        if (dayjs(timeOfAppointment).isAfter(currentDate)) {
          // Appointment is today or in the future

          setUpcomingAppointmentModel(true);
        } else {
          // Appointment is in the past
          if (
            detailView?.status === "customer_paid" ||
            detailView?.status === "mark_as_no_show"
          ) {
            navigate(`/appointment/past-appointment/${detailView?._id}`);
          } else {
            setOpenAppointmentDetailModal(true);
          }
        }
      }
    }
  };
  useEffect(() => {
    navigate(`/calendar/${view}`);
  }, [view]);

  const handleCloseAppointmentDetailModal = () => {
    setOpenAppointmentDetailModal(false);
    setSelectedAppointmentId("");
  };

  const CustomMonthEvent = ({ event }) => (
    <div>
      <Typography fontWeight={600}>{event.title}</Typography>
    </div>
  );
  const handleStatusClose = () => {
    setShow(false);
  };
  const handleStatus = () => setShow(true);

  return (
    <>
      <Box sx={{ padding: "32px 24px", width: "100%" }}>
        <Box mb={4}>
          <div className="my-custom-toolbar">
            <Grid
              container
              columns={16}
              // gap={1}
              sx={{ bgcolor: "background.main" }}
            >
              <Grid xl={4} lg={3} md={4}>
                {/* <Stack> */}
                <Typography
                  display={"inline"}
                  // variant="h4"
                  className="toolbar-label"
                  fontSize={"34px"}
                >
                  {moment(date).format("MMMM YYYY")}
                </Typography>
                {/* </Stack> */}
              </Grid>
              <Grid display={"flex"} alignItems="center" xl={4} lg={5} md={4}>
                <IconButton
                  onClick={(e) => {
                    handleNavigate("PREV");
                  }}
                >
                  <ChevronLeftIcon />
                </IconButton>
                <IconButton onClick={() => handleNavigate("NEXT")}>
                  <ChevronRightIcon />
                </IconButton>
                {/* <button onClick={handleTodayClick}>Today</button> */}
                <Button
                  onClick={handleTodayClick}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Today
                </Button>
                {/* <button onClick={() => handleViewChange("month")}>Month</button>
              <button onClick={() => handleViewChange("week")}>Week</button>
              <button onClick={() => handleViewChange("day")}>Day</button> */}
              </Grid>
              <Grid xl={8} lg={8} md={8} sm={10}>
                <Stack spacing={2} direction="row" sx={{ float: "right" }}>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        setOpencalendarSettings(true);
                      }}
                    >
                      Settings
                    </Button>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        addToHistory(location.pathname);
                        navigate("/appointment/new-appointment");
                      }}
                    >
                      New Appointment
                    </Button>
                  </div>
                </Stack>
              </Grid>
            </Grid>
          </div>
        </Box>
        <div className="calendar-app">
          <Grid container columns={20}>
            <Grid
              xl={sideBarWidth}
              lg={sideBarWidth + 1}
              md={sideBarWidth + 2}
              sm={sideBarWidth}
              xs={sideBarWidth}
            >
              <Sidebar
                view={view}
                setView={setView}
                setSideBarWidth={setSideBarWidth}
                sideBarWidth={sideBarWidth}
                setResources={setResources}
                resources={resources}
                setAssignee={setAssignee}
                calendarPreferences={calendarPreferences}
                setCalendarPreferences={setCalendarPreferences}
              />
            </Grid>
            <Grid
              xl={20 - sideBarWidth}
              lg={20 - sideBarWidth - 1}
              md={20 - sideBarWidth - 2}
              sm={20 - sideBarWidth}
              xs={20 - sideBarWidth}
            >
              {view == "day" || view == "month" ? (
                <div className="calendar-container">
                  <Calendar
                    localizer={localizer}
                    events={events} // Your events data
                    showAllEvents={true}
                    selectable={true}
                    onSelectEvent={(event) => {
                      setSelectedAppointmentId(event?.appointmentId);
                    }}
                    onSelectSlot={(e) => {
                      setView("day");
                      setDate(e?.start);
                    }}
                    startAccessor="start"
                    endAccessor="end"
                    // dayLayoutAlgorithm="no-overlap"
                    components={{
                      month: {
                        header: CustomMonthHeader,
                        event: CustomMonthEvent,
                        dateHeader: (e) => {
                          return (
                            <span
                              style={{
                                fontSize: "13.33px",
                              }}
                            >
                              {e.label}
                            </span>
                          );
                        },
                      },
                      work_week: {
                        header: CustomWeekHeader,

                        // header: CustomWeek
                      },
                      day: {
                        toolbar: (props) => (
                          <CustomDayHeader
                            props={props}
                            availablity={availablity}
                            currentDate={date}
                            fetchAvailablityStatus={fetchAvailablityStatus}
                            setShowLoader={setShowLoader}
                          />
                        ),
                        event: CustomEvent,
                        resourceHeader: (props) => (
                          <Chip
                            label={props?.resource?.title}
                            className="customCalendarChip"
                          ></Chip>
                        ),
                      },
                      // week: {
                      //   header: CustomWeek
                      // }
                    }}
                    step={30}
                    resources={
                      view == "work_week"
                        ? resources.length == 1
                          ? [...resources]
                          : false
                        : resources.length > 0
                        ? [...resources]
                        : false
                    }
                    view={view}
                    defaultView={view}
                    onView={handleViewChange}
                    views={["day", "work_week", "month", "week"]}
                    // views={{ "3day": MyWeek }}
                    date={date}
                    timeslots={2}
                    onNavigate={handleNavigate}
                    // dayPropGetter={dayPropGetter}
                    min={
                      new Date(
                        new Date(date).getFullYear(),
                        new Date(date).getMonth(),
                        new Date(date).getDate(),
                        0,
                        0,
                        0
                      )
                    }
                    formats={{ timeGutterFormat: "h:mm A" }}
                    eventPropGetter={(myEventsList) => {
                      return {
                        style: {
                          backgroundColor: backgroundColor(
                            myEventsList?.status
                          ),
                          borderLeft: borderColor(myEventsList?.status),
                          borderRight: OtherSideBorderColor(
                            myEventsList?.status
                          ),
                          borderTop: OtherSideBorderColor(myEventsList?.status),
                          borderBottom: OtherSideBorderColor(
                            myEventsList?.status
                          ),
                        },
                      };
                    }}
                    dayPropGetter={() => {
                      return { style: { background: "none" } };
                    }}
                    scrollToTime={new Date()}

                    // Set the default start time to 9:00 AM
                  />
                </div>
              ) : (
                <CustomCalendar
                  date={date}
                  resources={resources}
                  view={view}
                  events={events}
                  setCustomCalendarDates={setCustomCalendarDates}
                  availablity={availablity}
                  fetchAvailablityStatus={fetchAvailablityStatus}
                  setSelectedAppointmentId={setSelectedAppointmentId}
                  setShowLoader={setShowLoader}
                  showLoader={showLoader}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </Box>
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <AppointmentModel
        open={openAppointmentDetailModal}
        handleClose={handleCloseAppointmentDetailModal}
        data={appointmentDetailData}
        status={appointmentDetailData?.status}
        show={show}
        handleStatusClose={handleStatusClose}
        handleStatus={handleStatus}
        recallDetailsAPI={() => {
          details();
        }}
        setUpdateStatus={setUpdateStatus}
        setSelectId={setSelectId}
        setAppointmentCompletionOffODo={setAppointmentCompletionOffODo}
        setAppointmentDropOffODo={setAppointmentDropOffODo}
        fetchCalendarDataonAppointmentStatusChange={
          fetchCalendarDataonAppointmentStatusChange
        }
      />
      {(appointmentDetailData?.status == "waiting_for_drop_off" ||
        appointmentDetailData?.status == "in_progress" ||
        appointmentDetailData?.status == "vehicle_dropped_off") && (
        <AddOdoModal
          open={show}
          handleClose={() => setShow(false)}
          updateStatus={updateStatus}
          id={selectId}
          handleClose1={() => setShow(false)}
          currentStatus={appointmentDetailData?.status}
          handleClose2={() => setShow(false)}
          appointmentDropOffODO={appointmentDropOffODO}
          appointmentCompletionOffODO={appointmentCompletionOffODO}
          setAppointmentCompletionOffODo={setAppointmentCompletionOffODo}
          setAppointmentDropOffODo={setAppointmentDropOffODo}
          appointmentView={() => {}}
          fetchCalendarDataonAppointmentStatusChange={
            fetchCalendarDataonAppointmentStatusChange
          }
        />
      )}
      <ViewUpcomingModal
        handleClose={handleCloseUpcomingAppointmentModal}
        open={upcomingAppointmentModel}
        handleCancelModel={handleCloseUpcomingAppointmentModal}
        upcomingData={appointmentDetailData}
        appointmentId={selectedAppointmentId}
      />
      <CalendarSettingModel
        open={openCalendarSettings}
        // handleCloseModel={handleCloseCalendarSettingModel}
      />
    </>
  );
}

export default MyCalendar;
