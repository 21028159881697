import { Box } from "@mui/material";
import React from "react";

const UserTag = ({ type }) => {
  return (
    <>
      {type && (
        <Box
          sx={{
            padding: "7px 10px",
            background:
              type === 1
                ? "#DECAFB"
                : type === 2
                ? "#AAEAFE"
                : type === 3
                ? "#EDEDED"
                : "",
            color:
              type === 1
                ? "#371B68"
                : type === 2
                ? "#070707"
                : type === 3
                ? "#002394"
                : "",
            fontSize: "13px",
            fontWeight: "500",
            lineHeight: "18px",
            letterSpacing: "0.16px",
            width: "max-content",
          }}
        >
          {type === 1 && "Guest User"}
          {type === 2 && "Online Booking"}
          {type === 3 && "App Booking"}
        </Box>
      )}
    </>
  );
};

export default UserTag;
